// import { ReactComponent as FrameIcon } from "../../asset/Frame 1001.svg";
// import { useState } from "react";
// import ItemsData from "../libs/items";
// import useFormDataSender from "../libs/sender";
// import useFormDataReceiver from "../libs/receiver";
// import useShippingCost from "../libs/useShippingCost";
// import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Spinner from "../../utils/spinner";

// function Logisticsitems() {
//   const navigate = useNavigate();
//   const [summaryformData, handlesummaryChange] = ItemsData("summaryData");
//   const [receiverFormData] = useFormDataReceiver("recieverFormData");
//   const [formData] = useFormDataSender("shipFormData");
//   const [items_value, setItems_value] = useState(summaryformData.items_value || "");

//   const { mutation } = useShippingCost();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const numericItemsValue = parseFloat(items_value.replace(/[^0-9.-]+/g, ""));

//     try {
//       const data = await mutation.mutateAsync({
//         sender_state: formData.state,
//         receiver_state: receiverFormData.state,
//         weight: summaryformData.weight,
//         quantity: summaryformData.quantity,
//         items_value: numericItemsValue, 
//       });

//       if (data) {
//         localStorage.setItem("shippingSummaryData", JSON.stringify(data));
//         navigate("/Logisticssummary");
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error("Sorry we are not shipping to these state combination yet, Please try again later.");

//       setTimeout(() => {
//         navigate('/Logiscticsender');
//       }, 2000);
//     }
//   };

//   const handleItems_valueChange = (event) => {
//     let inputValue = event.target.value.replace(/[^\d]/g, ""); 
//     inputValue = Number(inputValue) / 100; 

    
//     const formattedValue = inputValue.toLocaleString("en-US", {
//       style: "currency",
//       currency: "NGN",
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });

//     setItems_value(formattedValue);


//     handlesummaryChange({
//       target: { name: "items_value", value: inputValue.toString() },
//     });
//   };

//   return (
//     <div className="logistics-sender">
//       <div className="header">
//         <p>Create Package</p>
//       </div>
//       <div className="ship-form">
//         <FrameIcon />
//         <form onSubmit={handleSubmit}>
//           <p>What are you Shipping?</p>
//           <div className="inner">
//             <label>
//               Category
//               <select
//                 name="category"
//                 value={summaryformData.category}
//                 onChange={handlesummaryChange}
//                 required
//               >
//                 <option value="">Select</option>
//                 <option value="Electronics">Electronics</option>
//                 <option value="Food Stuff">Food Stuff</option>
//                 <option value="Appliance">Appliance</option>
//               </select>
//             </label>

//             <label>
//               Select Item
//               <select
//                 name="item"
//                 value={summaryformData.item}
//                 onChange={handlesummaryChange}
//                 required
//               >
//                 <option value="">Select</option>
//                 <option value="Freezer">Freezer</option>
//                 <option value="Rice">Rice</option>
//                 <option value="Iron">Iron</option>
//                 <option value="Fan">Fan</option>
//                 <option value="Chair">Chair</option>
//                 <option value="Table">Table</option>
//               </select>
//             </label>

//             <label>
//               Weight range (kg)
//               <input
//                 type="tel"
//                 name="weight"
//                 value={summaryformData.weight}
//                 onChange={handlesummaryChange}
//                 required
//               />
//             </label>

//             <label>
//               Quantity
//               <input
//                 type="text"
//                 name="quantity"
//                 value={summaryformData.quantity}
//                 onChange={handlesummaryChange}
//                 required
//               />
//             </label>

//             <label>
//               Value (Naira)
//               <input
//                 type="tel"
//                 name="items_value"
//                 placeholder="Enter value"
//                 value={items_value || ""}
//                 onChange={handleItems_valueChange}
//                 required
//               />
//             </label>

//             <label>
//               Estimated delivery date
//               <input
//                 type="date"
//                 name="estimated_delivery_date"
//                 value={summaryformData.estimated_delivery_date || ""}
//                 onChange={handlesummaryChange}
//                 required
//               />
//             </label>

//             <label>
//               Delivery Method
//               <select
//                 name="delivery_method"
//                 value={summaryformData.delivery_method || ""}
//                 required
//                 onChange={handlesummaryChange}
//               >
//                 <option value="">Select Delivery Method</option>
//                 <option value="Home">Home</option>
//                 <option value="Office">Office</option>
//               </select>
//             </label>

//             <label>
//               Payment Method
//               <select
//                 name="payment_method"
//                 value={summaryformData.payment_method || ""}
//                 required
//                 onChange={handlesummaryChange}
//               >
//                 <option value="">Select Payment Method</option>
//                 <option value="Cash">Cash</option>
//                 <option value="POS">POS</option>
//               </select>
//             </label>
//           </div>
//           <div className="btn">
//             <button onClick={() => navigate("/Logisticsreceiever")}>
//               Back
//             </button>
//             <button type="submit">
//               {mutation.isLoading ? <Spinner /> : "Proceed"}
//             </button>
//           </div>
//         </form>
//         <ToastContainer style={{ width: "80%" }} position="top-right"  />
//       </div>
//     </div>
//   );
// }

// export default Logisticsitems;




// import { ReactComponent as FrameIcon } from "../../asset/Frame 1001.svg";
// import { useState } from "react";
// import ItemsData from "../libs/items";
// import useFormDataSender from "../libs/sender";
// import useFormDataReceiver from "../libs/receiver";
// import useManagerLocalShippingCost from "../libs/useManagerLocalShipCost";
// import useManagerStatesAndCities from "../libs/fetchManagerStateCities";
// import { useNavigate } from "react-router-dom";
// import Spinner from "../../utils/spinner";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import useUserCategories from "../libs/fetchCategoryHooks";

// const Logisticsitems = () => {
//   const navigate = useNavigate();
//   const [summaryformData, handlesummaryChange] = ItemsData("summaryData");
//   const [receiverFormData] = useFormDataReceiver("recieverFormData");
//   const [formData] = useFormDataSender("shipFormData");
//   const [items_value, setItems_value] = useState(summaryformData.items_value || "");
//   const { mutation } = useManagerLocalShippingCost();
//   const { data: userCategories, isLoading, isError } = useUserCategories();
//   const { data: statesAndCities } = useManagerStatesAndCities();
//   const [isEmergency, setIsEmergency] = useState("");
//   const [isPickupRequired, setIsPickupRequired] = useState("");

//   const getCitiesForState = (stateId) => {
//     const selectedState = statesAndCities?.states.find((state) => state._id === stateId);
//     return selectedState?.cities || [];
//   };

//   const getStateNameById = (stateId) => {
//     const state = statesAndCities?.states.find((state) => state._id === stateId);
//     return state?.name || "";
//   };

//   const getCityNameById = (stateId, cityId) => {
//     const city = getCitiesForState(stateId).find((city) => city._id === cityId);
//     return city?.name || "";
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const numericItemsValue = parseFloat(items_value.replace(/[^0-9.-]+/g, ""));
//     const isEmergencyValue = isEmergency === "true";
//     const isPickupValue = isPickupRequired === "true";
//     const senderStateName = getStateNameById(formData.senderState);
//     const senderCityName = formData.senderCity
//       ? getCityNameById(formData.senderState, formData.senderCity)
//       : "Unknown City";
//     const receiverStateName = getStateNameById(receiverFormData.receiverState);
//     const receiverCityName = receiverFormData.receiverCity
//       ? getCityNameById(receiverFormData.receiverState, receiverFormData.receiverCity)
//       : "Unknown City";

//     try {
//       const data = await mutation.mutateAsync({
//         senderState: senderStateName,
//         senderCity: senderCityName,
//         receiverState: receiverStateName,
//         receiverCity: receiverCityName,
//         weight: summaryformData.weight,
//         quantity: summaryformData.quantity,
//         itemsValue: numericItemsValue,
//         isEmergency: isEmergencyValue,
//         isPickupRequired: isPickupValue,
//       });

//       if (data) {
//         localStorage.setItem("shippingSummaryData", JSON.stringify(data));
//         navigate("/Logisticssummary");
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error("Sorry, we are not shipping to these state combinations yet. Please try again later.");
//       setTimeout(() => {
//         navigate("/Logisticsender");
//       }, 2000);
//     }
//   };

//   const handleItems_valueChange = (event) => {
//     let inputValue = event.target.value.replace(/[^\d]/g, "");
//     inputValue = Number(inputValue) / 100;

//     const formattedValue = inputValue.toLocaleString("en-US", {
//       style: "currency",
//       currency: "NGN",
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });

//     setItems_value(formattedValue);

//     handlesummaryChange({
//       target: { name: "items_value", value: inputValue.toString() },
//     });
//   };

//   return (
//     <div className="logistics-sender">
//       <div className="header">
//         <p>Create Package</p>
//       </div>
//       <div className="ship-form">
//         <FrameIcon />
//         <form onSubmit={handleSubmit}>
//           <p>What are you Shipping?</p>
//           <div className="inner">
//             <label>
//               Category
//               <select
//                 name="category"
//                 value={summaryformData.category}
//                 onChange={handlesummaryChange}
//                 required
//               >
//                 <option value="">Select</option>
//                 {isLoading && <option>Loading categories...</option>}
//                 {isError && <option>Error loading categories</option>}
//                 {userCategories?.categories?.map((category) => (
//                   <option key={category._id} value={category.name}>
//                     {category.name}
//                   </option>
//                 ))}
//               </select>
//             </label>
//             <label>
//               Select Item
//               <select
//                 name="item"
//                 value={summaryformData.item}
//                 onChange={handlesummaryChange}
//                 required
//               >
//                 <option value="">Select</option>
//                 {summaryformData.category &&
//                   userCategories?.categories
//                     ?.find((cat) => cat.name === summaryformData.category)
//                     ?.description?.map((item, index) => (
//                       <option key={index} value={item}>
//                         {item}
//                       </option>
//                     ))}
//               </select>
//             </label>
//             <label>
//               Weight range (kg)
//               <input
//                 type="tel"
//                 name="weight"
//                 value={summaryformData.weight}
//                 onChange={handlesummaryChange}
//                 required
//               />
//             </label>
//             <label>
//               Quantity
//               <input
//                 type="text"
//                 name="quantity"
//                 value={summaryformData.quantity}
//                 onChange={handlesummaryChange}
//                 required
//               />
//             </label>
//             <label>
//               Value (Naira)
//               <input
//                 type="tel"
//                 name="items_value"
//                 placeholder="Enter value"
//                 value={items_value || ""}
//                 onChange={handleItems_valueChange}
//                 required
//               />
//             </label>
//             <label>
//               Estimated delivery date
//               <input
//                 type="date"
//                 name="estimated_delivery_date"
//                 value={summaryformData.estimated_delivery_date || ""}
//                 onChange={handlesummaryChange}
//                 required
//               />
//             </label>
//             <label>
//               Delivery Method
//               <select
//                 name="delivery_method"
//                 value={summaryformData.delivery_method || ""}
//                 required
//                 onChange={handlesummaryChange}
//               >
//                 <option value="">Select Delivery Method</option>
//                 <option value="Home">Home</option>
//                 <option value="Office">Office</option>
//               </select>
//             </label>
//             <label>
//               Payment Method
//               <select
//                 name="payment_method"
//                 value={summaryformData.payment_method || ""}
//                 required
//                 onChange={handlesummaryChange}
//               >
//                 <option value="">Select Payment Method</option>
//                 <option value="Cash">Cash</option>
//                 <option value="POS">POS</option>
//               </select>
//             </label>
//             <label>
//               Pick Up
//               <select
//                 name="isPickup"
//                 value={isPickupRequired}
//                 onChange={(e) => setIsPickupRequired(e.target.value)}
//                 required
//               >
//                 <option value="">Select</option>
//                 <option value="true">Pick Up</option>
//                 <option value="false">Regular</option>
//               </select>
//             </label>
//             <label>
//               Express Delivery
//               <select
//                 name="isEmergency"
//                 value={isEmergency}
//                 onChange={(e) => setIsEmergency(e.target.value)}
//                 required
//               >
//                 <option value="">Select</option>
//                 <option value="true">Express</option>
//                 <option value="false">Regular</option>
//               </select>
//             </label>
//           </div>
//           <div className="btn">
//             <button onClick={() => navigate("/Logisticsreceiever")}>Back</button>
//             <button type="submit">{mutation.isLoading ? <Spinner /> : "Proceed"}</button>
//           </div>
//         </form>
//         <ToastContainer style={{ width: "80%" }} position="top-right" />
//       </div>
//     </div>
//   );
// };

// export default Logisticsitems;









import { ReactComponent as FrameIcon } from "../../asset/Frame 1001.svg";
import { useState } from "react";
import ItemsData from "../libs/items";
import useFormDataSender from "../libs/sender";
import useFormDataReceiver from "../libs/receiver";
import useManagerLocalShippingCost from "../libs/useManagerLocalShipCost";
import { useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUserCategories from "../libs/fetchCategoryHooks";
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";

const Logisticsitems = () => {
  const navigate = useNavigate();
  const [summaryformData, handlesummaryChange] = ItemsData("summaryData");
  const [receiverFormData] = useFormDataReceiver("recieverFormData");
  const [formData] = useFormDataSender("shipFormData");
  const { mutation } = useManagerLocalShippingCost();
  const { data: userCategories, isLoading, isError } = useUserCategories();
  const [isEmergency, setIsEmergency] = useState("");
  const [isPickupRequired, setIsPickupRequired] = useState("");
  const [items, setItems] = useState([{name: "", weight: "", items_value: "", quantity: "" }]);

  console.log({ formData, receiverFormData });

  const handleItemsChange = (index, event) => {
    const { name, value } = event.target;
    console.log(`Updating item[${index}] - ${name}:`, value);
    const newItems = [...items];
    newItems[index][name] = value;
    if (name === "category") newItems[index].name = ""; 
    setItems(newItems);
  };

  const addItem = () => setItems([...items, { name: "", weight: "", items_value: "", quantity: "" }]);
  const removeItem = (index) => setItems(items.filter((_, i) => i !== index));


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Accessing state and city directly from formData and receiverFormData
    const senderStateName = formData.senderState; 
    const senderCityName = formData.senderCity || "Unknown City"; 
    const receiverStateName = receiverFormData.receiverState;
    const receiverCityName = receiverFormData.receiverCity || "Unknown City"; 
  
    const payload = {
      senderState: senderStateName,
      senderCity: senderCityName,
      receiverState: receiverStateName,
      receiverCity: receiverCityName,
      isEmergency: isEmergency === "true",
      isPickupRequired: isPickupRequired === "true",
      items: items.map((item) => ({
        name: item.name,
        weight: parseFloat(item.weight) || 0,
        items_value: parseFloat(item.items_value.replace(/[^0-9.-]+/g, "")) || 0,
        quantity: parseInt(item.quantity, 10) || 1,
      })),
      estimated_delivery_date: summaryformData.estimated_delivery_date,
      delivery_method: summaryformData.delivery_method,
      payment_method: summaryformData.payment_method,
    };
  
  
    try {
     
      localStorage.setItem("itemsData", JSON.stringify(items));
  
      const data = await mutation.mutateAsync(payload);
      if (data) {
        localStorage.setItem("shippingSummaryData", JSON.stringify(data));
        navigate("/Logisticssummary");
      }
    } catch (error) {
      console.error("❌ Submission Error:", error);
      toast.error("Shipping unavailable for this location.");
    }
  };
  
  return (
    <div className="logistics-sender">
      <div className="header"><p>Create Package</p></div>
      <div className="ship-form">
        <FrameIcon />
        <form onSubmit={handleSubmit}>
          <p>What are you Shipping?</p>
          {items.map((item, index) => (
            <div className="inner" key={index}>
              <label>Category
                <select name="category" value={item.category} onChange={(e) => handleItemsChange(index, e)} required>
                  <option value="">Select</option>
                  {isLoading && <option>Loading categories...</option>}
                  {isError && <option>Error loading categories</option>}
                  {userCategories?.categories?.map((category) => (
                    <option key={category._id} value={category.name}>{category.name}</option>
                  ))}
                </select>
              </label>

              <label>Select Item
                <select name="name" value={item.name} onChange={(e) => handleItemsChange(index, e)} required>
                  <option value="">Select</option>
                  {item.category &&
                    userCategories?.categories
                      ?.find((cat) => cat.name === item.category)
                      ?.description?.map((itemName, idx) => (
                        <option key={idx} value={itemName}>{itemName}</option>
                      ))}
                </select>
              </label>

              <label>Weight (kg)
                <input type="text" name="weight" value={item.weight} onChange={(e) => handleItemsChange(index, e)} required />
              </label>
              <label>Quantity
                <input type="text" name="quantity" value={item.quantity} onChange={(e) => handleItemsChange(index, e)} required />
              </label>
              <label>Value (Naira)
                <input type="text" name="items_value" value={item.items_value} onChange={(e) => handleItemsChange(index, e)} required />
              </label>
              {items.length > 1 && (
                <div className="remove-item">
                  <button type="button" onClick={() => removeItem(index)}><CiCircleMinus /></button>
                </div>
              )}
              <label>Estimated Delivery Date
            <input type="date" name="estimated_delivery_date" value={summaryformData.estimated_delivery_date || ""} onChange={handlesummaryChange} required />
          </label>
          <label>Delivery Method
            <select name="delivery_method" value={summaryformData.delivery_method || ""} onChange={handlesummaryChange} required>
              <option value="">Select</option>
              <option value="Home">Home</option>
              <option value="Office">Office</option>
            </select>
          </label>
          <label>Payment Method
            <select name="payment_method" value={summaryformData.payment_method || ""} onChange={handlesummaryChange} required>
              <option value="">Select</option>
              <option value="Cash">Cash</option>
              <option value="POS">POS</option>
            </select>
          </label>
          <label>Pick Up
            <select name="isPickup" value={isPickupRequired} onChange={(e) => setIsPickupRequired(e.target.value)} required>
              <option value="">Select</option>
              <option value="true">Pick Up</option>
              <option value="false">Regular</option>
            </select>
          </label>
          <label>Express Delivery
            <select name="isEmergency" value={isEmergency} onChange={(e) => setIsEmergency(e.target.value)} required>
              <option value="">Select</option>
              <option value="true">Express</option>
              <option value="false">Regular</option>
            </select>
          </label>
            </div>
          ))}
         <div className="add-more-item">
         <button type="button" onClick={addItem}><CiCirclePlus /></button>
         </div>
          

         
          <div className="btn">
            <button onClick={() => navigate("/Logisticsreceiever")}>Back</button>
            <button type="submit">{mutation.isLoading ? <Spinner /> : "Proceed"}</button>
          </div>
        </form>
        <ToastContainer style={{ width: "80%" }} position="top-right" />
      </div>
    </div>
  );
};

export default Logisticsitems;

