import { NavLink as Link, Outlet, useLocation } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1.svg";
import { BiCategoryAlt } from "react-icons/bi";
import { ReactComponent as BpersonIcon } from "../../asset/bi_person.svg";
import { ReactComponent as MenuIcon } from "../../asset/menu-down.svg";
import UserDataFetcher from "../libs/Getadminbyid";
import { PiUsersFourThin } from "react-icons/pi";
import { TfiPackage } from "react-icons/tfi";
import { BsChatRightDots } from "react-icons/bs";
import { IoChatbubbleEllipsesOutline, IoSettingsOutline } from "react-icons/io5";
import { RxTokens } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineCarRental } from "react-icons/md";
import { PiWheelchairDuotone } from "react-icons/pi";
import { IoIosPeople } from "react-icons/io";
import { TbCurrencyNaira } from "react-icons/tb";
import { GiTruck } from "react-icons/gi";
import { FaArrowsRotate } from "react-icons/fa6";
import { CiBag1 } from "react-icons/ci";
import { TbAffiliate } from "react-icons/tb";
import { GrBusinessService } from "react-icons/gr";
import { MdBusinessCenter } from "react-icons/md";
import "./navbar.css";

const SuperNavbar = () => {
  const location = useLocation();
  const [dropdowns, setDropdowns] = useState({
    admins: false,
    costManagement: false,
    packagesManagement: false,
    others: false,
  });

  useEffect(() => {
    const path = location.pathname;
    setDropdowns({
      admins: path.includes("Superadminlogistic") || path.includes("Superamdindriver") || path.includes("Superadminrider") || path.includes("superadminfreight") || path.includes("superadmincharterer") || path.includes("Superadminmrarketmanager") || path.includes("ontripdriver")  || path.includes("Superadminsupport") || path.includes("superadminbusinessusers") ,
      packagesManagement: path.includes("Superadmindispatches") || path.includes("superadminundeliveredpackages") || path.includes("superadminallbranchpackages"),
      costManagement:  path.includes("superadminshippingcost") || path.includes("localshippingcost") || path.includes("superadminuserswallet"),
      others: path.includes("superadmininvestor") || path.includes("superadmintoken") || path.includes("Superadminblog") || path.includes("Superadminchat")
    });
  }, [location]);

  const toggleDropdown = (key) => {
    setDropdowns((prev) => {
      const newState = Object.keys(prev).reduce((acc, curr) => {
        acc[curr] = curr === key ? !prev[curr] : false;
        return acc;
      }, {});
      return newState;
    });
  };
  

  const { adminData } = UserDataFetcher();

  const NavLinkLCss = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#284697" : "",
      color: isActive ? "#fff" : "",
    };
  };

  useEffect(() => {
    const button = document.querySelector('.admins-button');
  
    if (button) { 
      const toggleActive = () => {
        button.classList.toggle('active');
      };
  
      button.addEventListener('click', toggleActive);
  
      return () => {
        button.removeEventListener('click', toggleActive);
      };
    }
  }, []);


  return (
    <Fragment>
      <div className="super_amin_header">
        <p>
          Welcome back, <span>{adminData?.user?.first_name}</span>{" "}
          <span>{adminData?.user?.last_name}</span>
        </p>
        <div className="side">
          <BpersonIcon className="round" />
          <MenuIcon />
        </div>
      </div>
      <div className="super_admin_container">
        <div className="frame-card">
          <FrameIcon />
        </div>
        <div className="links">
          <Link to="/" style={NavLinkLCss}>
            <BiCategoryAlt className="icon" /> Overview
          </Link>

          <div className="admins-button" onClick={() => toggleDropdown("admins")}>
            <span>
              <div className="icon-card">
                <PiUsersFourThin style={{ fontSize: "26px", color:'#284697' }} />
                <h2> Admins</h2>
              </div>
              {dropdowns.admins ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </span>
            {dropdowns.admins && (
              <div className="admins-drop">
                <Link to="/Superadminlogistic" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Logistics Officer
                </Link>
                <Link to="/Superamdindriver" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Driver
                </Link>
                <Link to="/Superadminrider" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Rider
                </Link>
                <Link to="/superadminfreight" style={NavLinkLCss}>
                  <GiTruck style={{ fontSize: "26px" }} /> Freight
                </Link>
                <Link to="/superadmincharterer" style={NavLinkLCss}>
                  <MdOutlineCarRental style={{ fontSize: "26px" }} /> Charterer
                </Link>
                <Link to="/Superadminmrarketmanager" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Marketplace Admin
                </Link>
                <Link to="/Superadminsupport" style={NavLinkLCss}>
                  <PiUsersFourThin className="icon" /> Support
                </Link>
                <Link to="/superadminbusinessusers" style={NavLinkLCss}>
                <GrBusinessService  className="icon" /> Business Users
                </Link>
                <Link to="/ontripdriver" style={NavLinkLCss}>
            <PiWheelchairDuotone className="icon" /> On Trip Driver
          </Link>
              </div>
            )}
          </div>
          <div className="admins-button" onClick={() => toggleDropdown("packagesManagement")}>
            <span>
              <div className="icon-card">
              <TfiPackage style={{ fontSize: "26px" , color:'#284697'}} />
                <h2> Pkgs Management</h2>
              </div>
              {dropdowns.packagesManagement ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </span>
            {dropdowns.packagesManagement && (
              <div className="admins-drop">
                 <Link to="/Superadmindispatches" style={NavLinkLCss}>
                  <TfiPackage className="icon" /> Dispatch Service
                </Link>
                <Link to="/superadminallbranchpackages" style={NavLinkLCss}>
                  <TfiPackage className="icon" /> Packages By Branch
                </Link>
                <Link to="/superadminundeliveredpackages" style={NavLinkLCss}>
                <FaArrowsRotate className="icon" /> Undelivered Packages
                </Link>
              </div>
            )}
          </div>

          <div className="admins-button" onClick={() => toggleDropdown("costManagement")}>
            <span>
              <div className="icon-card">
              <CiBag1 style={{ fontSize: "26px", color:'#284697' }} />
                <h2>Costs Management</h2>
              </div>
              {dropdowns.costManagement ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </span>
            {dropdowns.costManagement && (
              <div className="admins-drop">
                <Link to="/superadminshippingcost" style={NavLinkLCss}>
            <TbCurrencyNaira className="icon" />
           Nation Shipping Cost
          </Link>
          <Link to="/localshippingcost" style={NavLinkLCss}>
            <TbCurrencyNaira className="icon" />
           Local Shipping Cost
          </Link>
          <Link to="/superadminuserswallet" style={NavLinkLCss}>
            <MdBusinessCenter className="icon" />
           Business User Wallet
          </Link>
              </div>
            )}
          </div>

          <div className="admins-button" onClick={() => toggleDropdown("others")}>
            <span>
              <div className="icon-card">
              <TbAffiliate style={{ fontSize: "26px", color:'#284697' }}  />
                <h2>Others</h2>
              </div>
              {dropdowns.others ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </span>
            {dropdowns.others && (
              <div className="admins-drop">
               <Link to="/superadmininvestor" style={NavLinkLCss}>
            <IoIosPeople className="icon" /> Investors
          </Link>
          <Link to="/superadmintoken" style={NavLinkLCss}>
            <RxTokens className="icon" /> Tokens
          </Link>
          <Link to="/Superadminblog" style={NavLinkLCss}>
            <IoChatbubbleEllipsesOutline className="icon" /> Blog
          </Link>
          <Link to="/Superadminchat" style={NavLinkLCss}>
            <BsChatRightDots className="icon" /> Admin Chat
          </Link>
              </div>
            )}
          </div>

          <Link to="/Superadminsetting" style={NavLinkLCss}>
            <IoSettingsOutline className="icon" /> Settings
          </Link>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default SuperNavbar;


