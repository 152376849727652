import { useQuery } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";


const fetchBusinessCategories = async (userId, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/categories/categories/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to fetch business categories");
  }

  return response.json();
};

const useBusinessCategories = () => {
  const { token, user } = useContext(AuthContext);

  return useQuery(
    ["businessCategories", user.userId],
    () => fetchBusinessCategories(user.userId, token),
    {
      enabled: !!user.userId && !!token,
    }
  );
};

export default useBusinessCategories;
