import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import useBusinessPackageCreate from "../../Business_account/lib/createBulkPackages";

export default function Logisticbulkpackagecreation() {
  const [packages, setPackages] = useState([]);

  const navigate = useNavigate();
  const { createPackage, mutation } = useBusinessPackageCreate();
  const [overlayMessage, setOverlayMessage] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("bulkShippingData"));
    if (storedData?.packages) {
      setPackages(storedData.packages);
    }
  }, []);

  const handleEdit = (index, field, value) => {
    const updatedPackages = [...packages];
    updatedPackages[index][field] = value;
    setPackages(updatedPackages);
    localStorage.setItem(
      "bulkShippingData",
      JSON.stringify({ packages: updatedPackages })
    );
  };

  const handleCreate = async () => {
    setError(null);
    setIsLoading(true); // Start loading

    const storedData = JSON.parse(localStorage.getItem("bulkShippingData"));

    if (
      !storedData ||
      !storedData.creator ||
      !Array.isArray(storedData.packages)
    ) {
      setError("Invalid or missing data in local storage.");
      setIsLoading(false); // Stop loading

      return;
    }

    try {
      const cleanedPackages = storedData.packages.map((pkg) => ({
        ...pkg,
        shipping_cost: parseFloat(pkg.shipping_cost) || 0,
        original_shipping_cost: parseFloat(pkg.original_shipping_cost) || 0,
        total_discount: parseFloat(pkg.total_discount) || 0,
        discount_percentage: parseFloat(pkg.discount_percentage) || 0,
      }));

      const cleanedData = { ...storedData, packages: cleanedPackages };

      const response = await createPackage(cleanedData);

      if (response?.success) {
        setOverlayMessage({
          show: true,
          message: "Shipment(s) created successfully.",
        });

        setTimeout(() => {
          setOverlayMessage({ show: false, message: "" });
          navigate("/Logisticspackages");
        }, 3000);
      } else {
        throw new Error(response?.message || "Package creation failed.");
      }
    } catch (err) {
      const serverError =
        err.response?.data?.message ||
        mutation.error?.message ||
        err.message ||
        "An error occurred";

      setError(serverError);
    } finally {
      setIsLoading(false); // Stop loading after request completes
    }
  };

  return (
    <>
      {overlayMessage.show && (
        <div className="overlay-re">
          <div className="overlay-message-re">{overlayMessage.message}</div>
        </div>
      )}

      <div className="logistic-bulk-packages-container ">
        <div className="header">
          <h2> Bulk Packages</h2>
          <button onClick={handleCreate} disabled={isLoading}>
            {isLoading ? "Creating..." : "Create"}
          </button>
        </div>
        <table className="bulk-packages-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Items/Qt</th>
              <th>Value</th>
              <th>Est. Del Date</th>
              <th>Amount to Pay</th>
              <th>Actual Cost</th>
              <th> Discount</th>
              <th>Payment</th>
              <th>Delivery </th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <strong>{pkg.sender?.name}</strong>
                  <br />
                  {pkg.sender?.email}
                  <br />
                  {pkg.sender?.phone_number}
                </td>
                <td>
                  <strong>{pkg.receiver?.name}</strong>
                  <br />
                  {pkg.receiver?.email}
                  <br />
                  {pkg.receiver?.phone_number}
                </td>
                <td>
                  {pkg.items?.length ? (
                    <ul>
                      {pkg.items.map((item, i) => (
                        <li key={i}>
                          {item.name}-{item.quantity}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No items"
                  )}
                </td>
                <td>
                  {pkg.items?.length ? (
                    <ul>
                      {pkg.items.map((item, i) => (
                        <li key={i}>{item.items_value}</li>
                      ))}
                    </ul>
                  ) : (
                    "No items"
                  )}
                </td>
                {/* <td>
                {pkg.items?.length ? (
                  <ul>
                    {pkg.items.map((item, i) => (
                      <li key={i}>
                        {item?.items_value?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        }) || "N/A"}
                      </li>
                    ))}
                  </ul>
                ) : (
                  "No items"
                )}
              </td> */}

                <td>
                  <input
                    type="date"
                    value={pkg.estimated_delivery_date || ""}
                    onChange={(e) =>
                      handleEdit(
                        index,
                        "estimated_delivery_date",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>{pkg.shipping_cost}</td>
                <td>{pkg.original_shipping_cost}</td>
                <td>{pkg.total_discount}</td>
                {/* <td>
                {pkg?.shipping_cost?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                }) || "N/A"}
              </td>
              <td>
                {pkg?.original_shipping_cost?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                }) || "N/A"}
              </td>
              <td>
                {pkg?.total_discount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                }) || "N/A"}
              </td> */}
                <td>
                  <select
                    value={pkg.payment_method}
                    onChange={(e) =>
                      handleEdit(index, "payment_method", e.target.value)
                    }
                  >
                    <option value="POS">POS</option>
                    <option value="TRANSFER">TRANSFER</option>
                    <option value="CASH">CASH</option>
                  </select>
                </td>
                <td>
                  <select
                    value={pkg.delivery_method}
                    onChange={(e) =>
                      handleEdit(index, "delivery_method", e.target.value)
                    }
                  >
                    <option value="Home">Home</option>
                    <option value="Pickup">Office</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
