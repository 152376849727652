import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { ReactComponent as SearchIcon } from "../../asset/iconoir_search.svg";
import { ReactComponent as PackageIcon } from "../../Assets/Frame 1171276918.svg";
import { ReactComponent as PreshipmentIcon } from "../../Assets/Frame 1171276918 (2).svg";
import { ReactComponent as RiderIcon } from "../../Assets/Frame 1171276918 (1).svg";
import { ReactComponent as TrackingIcon } from "../../Assets/Ellipse 3681.svg";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TbCurrencyNaira } from "react-icons/tb";
import { CiCircleRemove } from "react-icons/ci";
import useTotalPackagesById from "../libs/fetchTotalPackagesById";
import useTotalActivePackagesById from "../libs/fetchTotalActivePackagesById";
import useRecentPackagesById from "../libs/fetchRecentPackagesById";
import usePreshipmentFetchShipmentByUser from "../libs/fetchPreShipments";
import useTrackPackage from "../libs/useTrackPackage";
import useRiderMetricByBranch from "../libs/useRidersByBranch";
import useLogisticsRevenueData from "../libs/fetchLogisticRevenue";
import Spinner from "../../utils/spinner";
import "./Logisticsoverview.css";
function Logisticoverview() {
  const { data: totalPackages, isLoading, isError } = useTotalPackagesById();
  const {
    data: totalActivePackages,
    isLoading: isLoadings,
    isError: isErrors,
  } = useTotalActivePackagesById();
  const {
    data: ridersData,
    isLoading: riderLoading,
    isError: riderError,
  } = useRiderMetricByBranch();
  const {
    data: revenueData,
    isLoading: revenueLoading,
    isError: revenueError,
  } = useLogisticsRevenueData();
  const { data: shipment } = usePreshipmentFetchShipmentByUser();
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingOverlay, setTrackingOverlay] = useState(false);
  const {
    data: trackingInfo,
    isLoading: trackingLoading,
    isError: trackingError,
  } = useTrackPackage(trackingNumber);

  const {
    data: recentPackages,
    isLoading: recentPackagesLoading,
    isError: recentPackagesError,
  } = useRecentPackagesById();

  const chartOptions = {
    chart: {
      type: "bar",
      height: 450,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Daily", "Weekly", "Monthly", "Yearly"],
    },
  };

  const chartSeries = [
    {
      name: "Revenue",
      data: [
        revenueData?.metrics?.today?.totalEarnings || 0,
        revenueData?.metrics?.week?.totalEarnings || 0,
        revenueData?.metrics?.month?.totalEarnings || 0,
        revenueData?.metrics?.year?.totalEarnings || 0,
      ],
    },
  ];

  return (
    <Fragment>
      {trackingOverlay && (
        <div className={`tracking-card ${trackingLoading ? "loading" : ""}`}>
          <div className="card">
            {trackingLoading ? (
              <Spinner />
            ) : trackingError ? (
              <div className="track-error">
                <p>Shipment not found.</p>
                <button onClick={() => setTrackingOverlay(false)}>Close</button>
              </div>
            ) : (
              <>
                <span>
                  <h1>Track shipment</h1>
                  <button
                    onClick={() => {
                      setTrackingNumber("");
                      setTrackingOverlay(false);
                    }}
                  >
                    Close
                  </button>
                </span>

                <div className="del">
                  <p>Delivery Address</p>
                  <div className="in">
                    <p>From</p>
                    <span>
                      {trackingInfo?.sender.state},{" "}
                      {trackingInfo?.sender.address_1}
                    </span>
                  </div>
                  <div className="in">
                    <p>To</p>
                    <span>
                      {trackingInfo?.receiver.state},{" "}
                      {trackingInfo?.receiver.address_1}
                    </span>
                  </div>
                  <div className="status-history">
                    <h3>Status History</h3>
                    <ul className="info">
                      {trackingInfo?.statusHistory?.map((status) => (
                        <li key={status._id}>
                <p>
                        {status.status === "Delivered" ? (
                          <IoIosCheckmarkCircleOutline className="delivered-icon" />
                        ) : status.status === "Delivery Failed" ? (
                          <CiCircleRemove className="delivery-failed-icon" />
                        ) : (
                          <TrackingIcon className="tracking-icon" />
                        )}
                        {status.status}
                      </p>   
                          <div className="wrapper">
                            {status.city && status.state && (
                              <p>
                                Location: {status.city},    {status.state}
                              </p>
                            )}

                            {status.createdAt && (
                              <p>
                                {new Date(status.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </p>
                            )}
                          {status.receivedBy && (
                            <h3>Received By: {status.receivedBy}</h3>
                          )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="logistic-admin-overview">
        <div className="header">
          <p>Overview</p>
          <span>
            <input
              type="text"
              placeholder="Track package by tracking num"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              required
            />
            <div
              className="rd"
              onClick={() => trackingNumber && setTrackingOverlay(true)}
            >
              <SearchIcon />
            </div>
          </span>
        </div>
        <div className="box-card">
          <div className="box">
            <PackageIcon />
            <p>Total Package</p>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <p>Error fetching total packages</p>
            ) : (
              <p>{totalPackages?.totalPackages || 0}</p>
            )}
          </div>
          <div className="box">
            <PackageIcon />
            <p>Total Active Package</p>
            {isLoadings ? (
              <Spinner />
            ) : isErrors ? (
              <p>Error fetching total active packages</p>
            ) : (
              <p>{totalActivePackages?.totalActivePackages || 0}</p>
            )}
          </div>
          <div className="box">
            <PreshipmentIcon />
            <p>Pre shipment</p>
            {isLoadings ? (
              <Spinner />
            ) : isErrors ? (
              <p>Error fetching pick up orders</p>
            ) : (
              <p>{shipment?.totalShipments || 0}</p>
            )}
          </div>
          <div className="box">
            <RiderIcon />
            <p>Riders</p>
            {riderLoading ? (
              <Spinner />
            ) : riderError ? (
              <p>Error fetching riders</p>
            ) : (
              <p>{ridersData?.totalRiders || 0}</p>
            )}
          </div>
          <div className="box">
            <TbCurrencyNaira className="revenue-icon" />
            <p>Daily Revenue</p>
            <span>
              {revenueData?.metrics?.today?.totalEarnings
                ? (revenueData?.metrics?.today?.totalEarnings).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )
                : 0}
            </span>
          </div>
          <div className="box">
            <TbCurrencyNaira className="revenue-icon" />
            <p>Weekly Revenue</p>
            <span>
              {revenueData?.metrics?.week?.totalEarnings
                ? (revenueData?.metrics?.week?.totalEarnings).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )
                : 0}
            </span>
          </div>
          <div className="box">
            <TbCurrencyNaira className="revenue-icon" />
            <p>Monthly Revenue</p>
            <span>
              {revenueData?.metrics?.month?.totalEarnings
                ? (revenueData?.metrics?.month?.totalEarnings).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )
                : 0}
            </span>
          </div>
          <div className="box">
            <TbCurrencyNaira className="revenue-icon" />
            <p>Yearly Revenue</p>
            <span>
              {revenueData?.metrics?.year?.totalEarnings
                ? (revenueData?.metrics?.year?.totalEarnings).toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )
                : 0}
            </span>
          </div>
        </div>

        <div className="chart-container">
          {revenueLoading ? (
            <Spinner />
          ) : revenueError ? (
            <p style={{ fontFamily: "Montserrat" }}>No revenue data</p>
          ) : (
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={350}
              width={450}
            />
          )}
        </div>

        <span>
          <h2>Recent Packages</h2>
        </span>
        <div className="recent-package-table">
          {recentPackagesLoading ? (
            <Spinner />
          ) : recentPackagesError ? (
            <p style={{ fontFamily: "Montserrat" }}>No Recent packages</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Tracking Number</th>
                  <th>Sender </th>
                  <th>Receiver</th>
                  <th>Item/Weight</th>
                  <th>Quantity</th>
                  <th>Shipping Cost</th>
                  <th>Date Created</th>
                  <th>Estimated Delivery Date</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {recentPackages?.recentPackages?.map((pkg, index) => (
                  <tr key={index}>
                    <td>{pkg.tracking_number}</td>
                    <td>{pkg.sender.name}</td>
                    <td>{pkg.receiver.name}</td>
                    <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>
                {item?.name} / {item?.weight}kg
              </div>
            )) || "N/A"}
          </td>
          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>{item?.quantity}</div>
            )) || 0}
          </td>
                    <td>
                      {pkg.shipping_cost
                        ? pkg.shipping_cost.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : ""}
                    </td>
                    <td>
                      {pkg.dateCreated
                        ? new Date(pkg.dateCreated).toLocaleDateString(
                            "en-NG",
                            {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )
                        : ""}
                    </td>
                    <td>
                      {pkg.estimated_delivery_date
                        ? new Date(
                            pkg.estimated_delivery_date
                          ).toLocaleDateString("en-NG", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        : ""}
                    </td>
                    <td>
                      <Link to={`/details/${pkg._id}`}>View Details</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Logisticoverview;
