import React, { useState, useEffect } from "react";
import Businessusersecurity from "../Business_user_security/businessusersecurity";
import UserDataFetcher from "../../super_admin/libs/Getadminbyid";
import useUpdateUserById from "../lib/updateBusinessDetails";
import { CiEdit } from "react-icons/ci";
import "./Business_user_Management.css";

export default function Businessusermanagement() {
  const { adminData } = UserDataFetcher();
  const [profile, setProfile] = useState(true);
  const [security, setSecurity] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [selected, setSelected] = useState("profile");

  const handleProfile = () => {
    setProfile(true);
    setSecurity(false);
  };

  const handleSecurity = () => {
    setProfile(false);
    setSecurity(true);
  };

  const handleProfileClick = () => {
    setSelected("profile");
    handleProfile();
  };

  const handleSecurityClick = () => {
    setSelected("security");
    handleSecurity();
  };

  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    business_email: "",
    phone_number: "",
    role: "",
    business_address: "",
    state: "",
  });

  const { updateUserById } = useUpdateUserById();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (adminData?.user) {
      setUserDetails({
        first_name: adminData.user.first_name || "",
        last_name: adminData.user.last_name || "",
        email: adminData.user.email || "",
        company_name: adminData.user.company_name || "",
        business_email: adminData.user.business_email || "",
        phone_number: adminData.user.phone_number || "",
        role: adminData.user.role || "",
        business_address: adminData.user.business_address || "",
        state: adminData.user.state || "",
      });
    }
  }, [adminData]);

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleUpdateDetails = async () => {
    setLoading(true);
    try {
      await updateUserById(adminData.user._id, {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        company_name: userDetails.company_name,
        phone_number: userDetails.phone_number,
        business_address: userDetails.business_address,
      });
      // alert(" details updated successfully!");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const toggleEditDetails = async () => {
    if (isEditingDetails) {
      await handleUpdateDetails({
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        company_name: userDetails.company_name,
        phone_number: userDetails.phone_number,
        business_address: userDetails.business_address,
      });
    }
    setIsEditingDetails(!isEditingDetails);
  };

  return (
    <div className="user-management">
      <div className="switch">
        <button
          onClick={handleProfileClick}
          className={selected === "profile" ? "active-btn" : ""}
        >
          User Profile
        </button>
        <button
          onClick={handleSecurityClick}
          className={selected === "security" ? "active-btn" : ""}
        >
          Security
        </button>
      </div>

      {profile && (
        <div className="info-card">
          <div className="user-details">
            <div className="btn">
              <span>
                <p>Profile Management</p>
              </span>
              <button onClick={toggleEditDetails} disabled={loading}>
                {loading
                  ? "Saving..."
                  : isEditingDetails
                  ? "Save Changes"
                  : "Edit"}
              </button>
            </div>

            {/* Personal Details Section */}
            <div className="wrapper">
              <p>Personal Details</p>
              <div className="user">
                <span>
                  <p>First Name</p>
                  <div className="input-container">
                    <input
                      type="text"
                      name="first_name"
                      value={userDetails.first_name}
                      onChange={handleChange}
                      disabled={!isEditingDetails}
                    />
                    {isEditingDetails && <CiEdit className="input-icon" />}
                  </div>
                </span>

                <span>
                  <p>Last Name</p>
                  <div className="input-container">
                    <input
                      type="text"
                      name="last_name"
                      value={userDetails.last_name}
                      onChange={handleChange}
                      disabled={!isEditingDetails}
                    />
                    {isEditingDetails && <CiEdit className="input-icon" />}
                  </div>
                </span>

                <span>
                  <p>Email</p>
                  <input
                    type="email"
                    name="email"
                    value={userDetails.email}
                    disabled
                  />
                </span>
              </div>
            </div>

            {/* Company Details Section */}
            <div className="wrapper">
              <p>Company Details</p>
              <div className="user">
                <span>
                  <p>Company Name</p>
                  <div className="input-container">
                    <input
                      type="text"
                      name="company_name"
                      value={userDetails.company_name}
                      onChange={handleChange}
                      disabled={!isEditingDetails}
                    />
                    {isEditingDetails && <CiEdit className="input-icon" />}
                  </div>
                </span>
                <span>
                  <p>Business Email</p>
                  <input
                    type="email"
                    name="business_email"
                    value={userDetails.business_email}
                    disabled
                  />
                </span>
                <span>
                  <p>Business Phone Number</p>
                  <div className="input-container">
                    <input
                      type="text"
                      name="phone_number"
                      value={userDetails.phone_number}
                      onChange={handleChange}
                      disabled={!isEditingDetails}
                    />
                    {isEditingDetails && <CiEdit className="input-icon" />}
                  </div>
                </span>
                <span>
                  <p>Business Type</p>
                  <input
                    type="text"
                    name="role"
                    value={userDetails.role}
                    disabled
                  />
                </span>
                <span>
                  <p>Business Address</p>
                  <div className="input-container">
                    <input
                      type="text"
                      name="business_address"
                      value={userDetails.business_address}
                      onChange={handleChange}
                      disabled={!isEditingDetails}
                    />
                    {isEditingDetails && <CiEdit className="input-icon" />}
                  </div>
                </span>
                <span>
                  <p>Location State</p>
                  <input
                    type="text"
                    name="state"
                    value={userDetails.state}
                    disabled
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {security && (
        <div className="info-card">
          <Businessusersecurity />
        </div>
      )}
    </div>
  );
}
