import { useState, useEffect } from 'react';

const useBusinessReceiverFormData = (key) => {
  const [receiverformData, setreceiverFormData] = useState(() => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : {};
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(receiverformData));
  }, [receiverformData, key]);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setreceiverFormData({
      ...receiverformData,
      [name]: value,
    });
  };

  return [receiverformData, handleChanges];
};

export default useBusinessReceiverFormData;
