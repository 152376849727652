import { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";


const useBusinessReceiptDetails = () => {
  const { token, user } = useContext(AuthContext);
  const [isFetchingReceipt, setIsFetchingReceipt] = useState(false);

  const fetchReceiptDetails = async (packageId) => {
    if (!packageId || !user?.userId) {
      console.error("Invalid packageId or userId:", {
        packageId,
        userId: user?.userId,
      });
      throw new Error("Invalid packageId or userId.");
    }

    setIsFetchingReceipt(true);
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/receipt/get/${packageId}/${user?.userId}`;
      console.log("API Request URL:", url);

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob", // Handle PDF Blob
      });

      console.log("PDF fetched successfully");

      // Create a Blob URL for the PDF and return it
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      return pdfUrl;
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      throw new Error(
        error.response?.data?.message || "Failed to fetch receipt details."
      );
    } finally {
      setIsFetchingReceipt(false);
    }
  };

  return { fetchReceiptDetails, isFetchingReceipt };
};

export default useBusinessReceiptDetails;
