import { Fragment, useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1003.svg";
import { ReactComponent as EditIcon } from "../../asset/system-uicons_write.svg";
import { useNavigate } from "react-router-dom";
import useFormDataSender from "../libs/sender";
import useFormDataReceiver from "../libs/receiver";
import usePackageCreate from "../libs/usePackageCreate";
import useSummaryData from "../libs/items";
import useFetchReceiptDetails from "../libs/fetchReceiptDetails";

function Logisticssummary() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { fetchReceiptDetails } = useFetchReceiptDetails();
  const [totalShippingCost, setTotalShippingCost] = useState(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");

  const [formData] = useFormDataSender("shipFormData");
  const [receiverFormData] = useFormDataReceiver("recieverFormData");
  const [summaryformData] = useSummaryData("summaryData");

  const { createPackage, mutation } = usePackageCreate();

  useEffect(() => {
    const storedData = localStorage.getItem("shippingSummaryData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setTotalShippingCost(parsedData.amountToPay);
    }
    console.log(storedData.amountToPay);
  }, []);

  const itemsData = JSON.parse(localStorage.getItem("itemsData")) || [];
  console.log(itemsData);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const senderStateName = formData.senderState;
    const senderCityName = formData.senderCity || "Unknown City";
    const receiverStateName = receiverFormData.receiverState;
    const receiverCityName = receiverFormData.receiverCity || "Unknown City";

    try {
      const storedData = localStorage.getItem("shippingSummaryData");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const {
          amountToPay,
          originalShippingCost,
          totalDiscount,
          discountPercentage,
          isEmergency,
          isPickupRequired,
        } = parsedData;

        // Retrieve items from localStorage (the new method)
        const items = itemsData.map((item) => ({
          ...item,

          items_value:
            parseFloat(item.items_value.replace(/[^0-9.-]+/g, "")) || 0,
          weight: parseFloat(item.weight) || 0,
          quantity: parseInt(item.quantity, 10) || 1,
        }));

        const packageData = {
          shipping_cost: parseFloat(amountToPay),
          original_shipping_cost: parseFloat(originalShippingCost),
          total_discount: parseFloat(totalDiscount) || 0,
          discount_percentage: parseFloat(discountPercentage) || 0,
          isEmergency: isEmergency === "true",
          isPickupRequired: isPickupRequired === "true",
          creator: user.userId,
          sender: {
            name: formData.name,
            contact_name: formData.contact_name,
            phone_number: formData.phone_number,
            email: formData.email,
            address_1: formData.address_1,
            address_2: formData.address_2,
            zip_code: formData.zip_code,
            state: senderStateName,
            city: senderCityName,
          },
          receiver: {
            name: receiverFormData.name,
            contact_name: receiverFormData.contact_name,
            phone_number: receiverFormData.phone_number,
            email: receiverFormData.email,
            address_1: receiverFormData.address_1,
            address_2: receiverFormData.address_2,
            zip_code: receiverFormData.zip_code,
            state: receiverStateName,
            city: receiverCityName,
          },
          estimated_delivery_date: new Date(
            summaryformData.estimated_delivery_date
          ),
          payment_method: summaryformData.payment_method,
          delivery_method: summaryformData.delivery_method,
          items: items,
        };

        if (summaryformData.pickupOption) {
          packageData.pickupOption = summaryformData.pickupOption;
        }

        setOverlayMessage("Creating package...");
        setIsOverlayVisible(true);

        const createdPackage = await createPackage(packageData);
        const packageId = createdPackage?.package?._id;

        if (packageId) {
          setOverlayMessage(
            "Package created successfully. Downloading receipt..."
          );
          const pdfUrl = await fetchReceiptDetails(packageId);

          if (pdfUrl) {
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = `package_receipt_${packageId}.pdf`;
            link.click();
          }

          setOverlayMessage("Receipt downloaded successfully!");
          localStorage.clear();
          setTimeout(() => {
            setIsOverlayVisible(false);
            navigate("/Logisticspackages");
          }, 3000);
        } else {
          throw new Error("Failed to retrieve package ID from response.");
        }
      } else {
        throw new Error("No shipping summary data found in local storage.");
      }
    } catch (error) {
      console.error("Error creating package:", error);
      setOverlayMessage("Error creating package. Please try again.");
      setTimeout(() => setIsOverlayVisible(false), 3000);
    }
  };

  return (
    <Fragment>
      {isOverlayVisible && (
        <div className="overlay-re">
          <div className="overlay-message-re">{overlayMessage}</div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="logistics-sender">
        <div className="header">
          <p>Create Package</p>
        </div>
        <div className="summary">
          <FrameIcon />
          <div className="sender-info">
            <div className="in">
              <p>Ship From/Return to</p>
              <button
                type="button"
                onClick={() => navigate("/Logiscticsender")}
              >
                Edit <EditIcon />
              </button>
            </div>
            <div className="info">
              <p>{formData.name}</p>
              <p>{formData.contact_name}</p>
              <p>{formData.address_1}</p>
              <p>
                {formData.senderStateName} {formData.zip_code}
              </p>
              <p>{formData.senderCityName}</p>
              <p>{formData.phone_number}</p>
            </div>
          </div>

          <div className="sender-info">
            <div className="in">
              <p>Ship to</p>
              <button
                type="button"
                onClick={() => navigate("/Logisticsreceiever")}
              >
                Edit <EditIcon />
              </button>
            </div>
            <div className="info">
              <p>{receiverFormData.name}</p>
              <p>{receiverFormData.contact_name}</p>
              <p>{receiverFormData.address_1}</p>
              <p>
                {receiverFormData.receiverStateName} {receiverFormData.zip_code}
              </p>
              <p>{receiverFormData.receiverCityName}</p>
              <p>{receiverFormData.phone_number}</p>
            </div>
          </div>

          <div className="sum">
            <div className="in">
              <p>Item Summary</p>
              <button type="button" onClick={() => navigate("/Logisticsitems")}>
                Edit <EditIcon />
              </button>
            </div>
            <div className="sum-items">
              <div className="in">
                <p>Delivery Items</p>
              </div>
              <div className="items">
                {itemsData && itemsData.length > 0 ? (
                  itemsData.map((item, index) => (
                    <Fragment key={index}>
                      <div className="wrapper">
                        <span>
                          <h3>Category</h3>
                          <p>{item.category || "N/A"}</p>
                        </span>
                        <span>
                          <h3>Weight</h3>
                          <p>{item.weight || "N/A"}</p>
                        </span>
                        <span>
                          <h3>Quantity</h3>
                          <p>{item.quantity || "N/A"}</p>
                        </span>
                        <span>
                          <h3>Value (NGN)</h3>
                          <p>
                            {item.items_value
                              ? item.items_value.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "NGN",
                                })
                              : "N/A"}
                          </p>
                        </span>
                      </div>
                    </Fragment>
                  ))
                ) : (
                  <p>No items available.</p>
                )}
              </div>
            </div>
          </div>

          <div className="ship-cost">
            <div className="in">
              <p>Estimate Cost</p>
            </div>
            <div className="cost">
              <p>
                {totalShippingCost !== null
                  ? totalShippingCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : "N/A"}
              </p>
            </div>
          </div>
          {mutation.errorMsg && (
            <p style={{ color: "red" }}>{mutation.errorMsg}</p>
          )}
          <button
            type="submit"
            disabled={mutation.isLoading}
            className="submit"
          >
            {mutation.isLoading ? "Creating..." : "Submit"}
          </button>
        </div>
      </form>
    </Fragment>
  );
}

export default Logisticssummary;
