import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const fetchMyConcerns = async ({ pageParam = 1, searchTerm = "" }, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/raise/concerns/my?page=${pageParam}&limit=10&search=${encodeURIComponent(searchTerm)}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch concerns.");
  }

  return response.json();
};

const useMyConcerns = (searchTerm) => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ["myConcerns", searchTerm], // Key includes searchTerm to refetch on change
    ({ pageParam }) => fetchMyConcerns({ pageParam, searchTerm }, token),
    {
      enabled: !!token, // Only run query if token exists
      getNextPageParam: (lastPage, pages) => {
        return lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : undefined;
      },
    }
  );
};

export default useMyConcerns;
