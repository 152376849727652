import { useQuery } from "react-query";

const fetchBusinessTypes = async () => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/business/getbusinesses`);
  if (!response.ok) {
    throw new Error("Failed to fetch business types");
  }
  return response.json();
};

const useBusinessTypes = () => {
  return useQuery({
    queryKey: ["businessTypes"],
    queryFn: fetchBusinessTypes,
  });
};

export default useBusinessTypes;
