import React, { useState, useRef, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import useFetchcreatorPackages from "../libs/fetchCreatorPackages";
import { FaXmark } from "react-icons/fa6";
import Spinner from "../../utils/spinner";
import "./Branch_packages.css";

export default function Superadminallbranchpackages() {
  const [searchTerm, setSearchTerm] = useState("");
  const [packageOverlay, setPackageOverlay] = useState(false);
  const [selectedPackages, setSelectedPackages] = useState(false)
  const [selectedCreatorDetails, setSelectedCreatorDetails] = useState(null);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useFetchcreatorPackages();

  const creatorArray = data?.pages?.flatMap((page) => page.groupedPackages) || [];


  const filteredCreator = creatorArray.filter((creator) =>
    creator?.creatorDetails?.first_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const observerRef = useRef();
  const lastCreatorElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleViewCreatorPackages = (creatorId) => {
    const selectedCreator = creatorArray.find((creator) => creator.creatorId === creatorId);
    setSelectedPackages(selectedCreator?.packages || []);
    setSelectedCreatorDetails(selectedCreator?.creatorDetails || null);
    setPackageOverlay(true);
  };
  return (
    <>
      {packageOverlay && (
        <div className="creator-package-overlay">
         <div className="package-info">
            <span>
            <h3>
  Packages Created by: {selectedCreatorDetails?.first_name}{" "}
  {selectedCreatorDetails?.last_name}
</h3>


            <button onClick={() => setPackageOverlay(false)}><FaXmark /></button>
            </span>
         <table>
            <thead>
              <tr>
                <th>Tracking Number</th>
                <th>Sender Name</th>
                <th>Receiver Name</th>
                <th>Item/Weight</th>
                <th>Quantity</th>
                <th>Date Created</th>
                <th>Est. Deliv Date</th>
                <th>Shipping Cost</th>
                <th>Emergency Deliv</th>
                <th>Pick Up</th>
              </tr>
            </thead>
            <tbody>
              {selectedPackages.length > 0 ? (
                selectedPackages.map((pkg) => (
                  <tr key={pkg.tracking_number}>
                    <td>{pkg.tracking_number}</td>
                    <td>{pkg.sender?.name}</td>
                    <td>{pkg.receiver?.name}</td>
                    <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>
                {item?.name} / {item?.weight}kg
              </div>
            )) || "N/A"}
          </td>
          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>{item?.quantity}</div>
            )) || 0}
          </td>
                    <td>{new Date(pkg.dateCreated).toLocaleDateString()}</td>
                    <td>{new Date(pkg.estimated_delivery_date).toLocaleDateString()}</td>
                    
                   
                    <td>
                  {pkg?.shipping_cost?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })}
                </td>
                    <td>{pkg.isEmergency ? "Yes" : "No"}</td>
                    <td>{pkg.isPickupRequired ? "Yes" : "No"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11">No packages available</td>
                </tr>
              )}
            </tbody>
          </table>
         
         </div>
        </div>
      )}

      <div className="super_admin_creator_container">
        <div className="header">
          <p> Packages Creator </p>
          <span>
            <SearchIcon />
            <input
              type="search"
              placeholder="Search Creator by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </span>
        </div>

        <div className="creator-packages-table">
          {isLoading && <Spinner />}
          {isError && <p style={{ fontFamily: "Montserrat" }}>No Creator packages available now</p>}
          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Branch</th>
                  <th>Total Package</th>
                  <th>Package Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredCreator.map((creator, index) => {
                  const isLastElement = filteredCreator.length === index + 1;
                  return (
                    <tr key={creator.creatorId} ref={isLastElement ? lastCreatorElementRef : null}>
                      <td>{creator.creatorId.toUpperCase().slice(0, 8)}</td>
                      <td>{creator.creatorDetails?.first_name || "N/A"}</td>
                      <td>{creator.creatorDetails?.last_name || "N/A"}</td>
                      <td>{creator.creatorDetails?.email || "N/A"}</td>
                      <td>{creator.creatorDetails?.branch_full_address || "N/A"}</td>
                      <td>{creator.totalPackages}</td>
                      <td>
                        <button onClick={() => handleViewCreatorPackages(creator.creatorId)}>
                          View Package
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {isFetchingNextPage && <Spinner />}
      </div>
    </>
  );
}



