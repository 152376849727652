import React, { useState } from "react";
import { Link } from "react-router-dom";
import PackageDataMetrics from "../libs/GetPackagemetric";
import UserDataMetrics from "../libs/GetusrMetric";
import fetchAllPackages from "../libs/fetchAllPackages";
import useSuperTrackPackage from "../libs/trackPackage";
import useSuperadminRevenueData from "../libs/fetchRevenueData";
import { ReactComponent as SearchIcon } from "../../Assets/iconoir_search.svg";
import { ReactComponent as TrackingIcon } from "../../Assets/Ellipse 3681.svg";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { CiCircleRemove } from "react-icons/ci";
import Spinner from "../../utils/spinner";
import "./super_admin_overview.css";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

const RevenueBox = ({ users }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter users based on the search term
  const filteredUsers = users.filter(
    (user) =>
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      user.branchAddress.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate grand total earnings
  const grandTotalEarnings = users.reduce(
    (total, user) => total + (user.totalEarnings || 0),
    0
  );

  return (
    <div className="box">
      <h3>
        Grand Total Earnings:{" "}
        {grandTotalEarnings.toLocaleString("en-US", {
          style: "currency",
          currency: "NGN",
        })}
      </h3>
      <input
        type="text"
        placeholder="Search Logistic Officer by name or branch..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ margin: "10px 0", padding: "8px", width: "100%" }}
      />
      {filteredUsers.length === 0 ? (
        <p style={{ fontFamily: "Montserrat" }}>
          No revenue resources available now
        </p>
      ) : (
        filteredUsers.map((user) => (
          <div key={user.userId} className="user-metrics">
            <span>
              <h4>
                {user?.firstName} {user?.lastName}
              </h4>
              <p>{user?.branchAddress}</p>
            </span>
            <span>
              <p>Total Packages:</p>
              <h3>{user?.totalPackages}</h3>
            </span>
            <span>
              <p>Total Earnings:</p>
              <h3>
                {user?.totalEarnings?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                })}
              </h3>
            </span>
            {["today", "week", "month", "year"].map((period) => (
              <span key={period}>
                <p>
                  {period.charAt(0).toUpperCase() + period.slice(1)} Revenue:
                </p>
                <h3>
                  {user[period]?.totalEarnings?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  }) || 0}
                </h3>
                <p>Package Count ({period}):</p>
                <h3>{user[period]?.packageCount || 0}</h3>
              </span>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

const SuperadminOverview = () => {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingOverlay, setTrackingOverlay] = useState(false);
  const {
    packageMetrics,
    isLoading: packagesLoading,
    isError: packagesError,
  } = PackageDataMetrics();
  const { userMetrics } = UserDataMetrics();
  const { packages } = fetchAllPackages();
  const {
    data: trackingInfo,
    isLoading: trackingLoading,
    isError: trackingError,
  } = useSuperTrackPackage(trackingNumber);
  const {
    data: revenueData,
    isLoading: revenueLoading,
    isError: revenueError,
  } = useSuperadminRevenueData();

  

  return (
    <>
      {trackingOverlay && (
        <div className={`tracking-card ${trackingLoading ? "loading" : ""}`}>
          <div className="card">
            {trackingLoading ? (
              <Spinner />
            ) : trackingError ? (
              <div className="track-error">
                <p>Shipment not found.</p>
                <button onClick={() => setTrackingOverlay(false)}>Close</button>
              </div>
            ) : (
              <>
                <span>
                  <h1>Track shipment</h1>
                  <button
                    onClick={() => {
                      setTrackingNumber("");
                      setTrackingOverlay(false);
                    }}
                  >
                    Close
                  </button>
                </span>

                <div className="del">
                  <p>Delivery Address</p>
                  <div className="in">
                    <p>From</p>
                    <span>
                      {trackingInfo?.sender.state},{" "}
                      {trackingInfo?.sender.address_1}
                    </span>
                  </div>
                  <div className="in">
                    <p>To</p>
                    <span>
                      {trackingInfo?.receiver.state},{" "}
                      {trackingInfo?.receiver.address_1}
                    </span>
                  </div>
                  <div className="status-history">
                    <h3>Status History</h3>
                    <ul className="info">
                      {trackingInfo?.statusHistory?.map((status) => (
                        <li key={status._id}>
          <p>
          {status.status === "Delivered" ? (
            <IoIosCheckmarkCircleOutline className="delivered-icon" />
          ) : status.status === "Delivery Failed" ? (
            <CiCircleRemove className="delivery-failed-icon" />
          ) : (
            <TrackingIcon className="tracking-icon" />
          )}
          {status.status}
        </p>
                       
                          <div className="wrapper">
                            {status.city && status.state && (
                              <p>
                                Location: {status.city}, {status.state}
                              </p>
                            )}
                            {status.createdAt && (
                              <p>
                                {new Date(status.createdAt).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </p>
                            )}
                          {status.receivedBy && (
                            <h3>Received By: {status.receivedBy}</h3>
                          )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="super_admin_overview_container">
        <div className="header">
          <p>Overview</p>
          <span>
            <input
              type="text"
              placeholder="Track package by tracking num"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              required
            />
            <div
              className="rd"
              onClick={() => trackingNumber && setTrackingOverlay(true)}
            >
              <SearchIcon />
            </div>
          </span>
        </div>

        <div className="card-container">
          <div className="box">
            <h2>Total orders</h2>
            <span>More than yesterday</span>
            <p>{packageMetrics?.metrics?.totalPackages}</p>
          </div>
          <div className="box">
            <h2>Total Signed Up Users</h2>
            <span>New Users : {userMetrics?.totalUsers}</span>
            <div className="in">
              <p>{userMetrics?.totalUsers}</p>
              <Link to="/allusers">View Users</Link>
            </div>
          </div>
          <div className="box">
            <h2>Total Logistics Officer</h2>
            <span>Active: {userMetrics?.logisticsManagers}</span>
            <p>{userMetrics?.logisticsManagers}</p>
          </div>
          <div className="box">
            <h2>Total Drivers</h2>
            <span>Active: {userMetrics?.drivers}</span>
            <p>{userMetrics?.drivers}</p>
          </div>
          <div className="box">
            <h2>Total Rriders</h2>
            <span>Active: {userMetrics?.riders}</span>
            <p>{userMetrics?.riders}</p>
          </div>
          <div className="box">
            <h2>Total Market place Adm</h2>
            <span>Active: {userMetrics?.marketplaceManagers}</span>
            <p>{userMetrics?.marketplaceManagers}</p>
          </div>
          <div className="box">
            <h2>Total Merchants</h2>
            <span>Active: {userMetrics?.merchants}</span>
            <p>{userMetrics?.merchants}</p>
          </div>
          <div className="box">
            <h2>Total Custtomers</h2>
            <span>Active: {userMetrics?.customers}</span>
            <p>{userMetrics?.customers}</p>
          </div>
        </div>

        <div className="super-admin-revenue">
          <h2>Revenue Metrics</h2>
          <div className="wrapper">
            {revenueLoading ? (
              <Spinner />
            ) : revenueError ? (
              <p style={{ fontFamily: "Montserrat" }}>
                No revenue data available
              </p>
            ) : (
              <RevenueBox users={revenueData?.metrics || []} />
            )}
          </div>
        </div>


        <div className="packages">
          <div className="pkgs">
            <h2>Recent Packages</h2>
            <Link to="/superadminviewallpackage">View all</Link>
          </div>
          <div className="packages-data">
            {packagesError && (
              <p style={{ fontFamily: "Montserrat" }}>No packages available</p>
            )}
            {Array.isArray(packages?.packages)
              ? packages.packages.map((pkg) => (
                  <div className="pg-table" key={pkg.id}>
                    <p>{pkg?.tracking_number}</p>
                    <p>{formatDate(pkg?.dateCreated)}</p>
                    <p>
                      {pkg?.shipping_cost?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "NGN",
                      })}
                    </p>
                    <p>{pkg?.status[pkg.status.length - 1]?.status}</p>
                    <p>{pkg?.receiver?.name}</p>
                    <Link to={`/packages/${pkg.id}`}>Details</Link>
                  </div>
                ))
              : packagesLoading && <Spinner />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperadminOverview;
