import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import "./Business_international.css";

export default function InternationalPackageCreation() {
  const navigate = useNavigate();

  return (
    <div className="international-package-creation">
      <div className="header">
        <span>
          <FaArrowLeft onClick={() => navigate("/businesspackageshipment")} />
          <p>International Shipment</p>
        </span>
      </div>
      <div className="item-creation">
        <h3>COMING SOON...</h3>
      </div>
    </div>
  );
}
