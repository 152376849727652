// import { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../Context/AuthContext";

// const useLoginForm = () => {
//   const navigate = useNavigate();
//   const { Login } = useContext(AuthContext);
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     otp: "",
//   });
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [showTotp, setShowTotp] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };


//   const handleLogin = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_SERVER_URL}/users/auth`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//         }
//       );
//       const resData = await response.json();
//       if (!response.ok) {
//         if (resData.message === "One-time verification code is required for login.") {
//           setShowTotp(true);
//           navigate('/onetimeverificationcode')
//           return;
//         } else if(resData.message === "Your account is not activated. Please activate your account to log in.") {
//           navigate("/EmailVerificationForm")
//           return
//         } else {
//           throw new Error(resData.message);
//         }
//       }
//       setLoading(false);
//       const user = {
//         userId: resData.userId,
//         token: resData.token,
//         role: resData.role,
//       };
//       Login(user);
//       if (user?.role === "superadmin") {
//         navigate("/");
//       } else if(user.role === "logisticsmanager") {
//         navigate("/");
//       } else if(user.role === 'support'){
//         navigate("/");
//       } else if(user.role === 'customer'){
//         navigate("/Market")
//       } else if(user.role === 'rider'){
//         navigate('/')
//       } else if(user.role === 'driver'){
//         navigate('/')
//       } else if(user.role === "marketplacemanager"){
//         navigate('/')
//       } else if(user.role === 'merchant') {
//         navigate('/')
//       } else if(user.role === 'charterer'){
//         navigate('/')
//       } else if(user.role === 'freightmanager') {
//         navigate('/')
//       }
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return {
//     formData,
//     loading,
//     error,
//     handleChange,
//     handleLogin,
//     setShowTotp,
//     showTotp,
//   };
// };

// export default useLoginForm;




import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

const useLoginForm = () => {
  const navigate = useNavigate();
  const { Login, businessRoles } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showTotp, setShowTotp] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/users/auth`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const resData = await response.json();
      if (!response.ok) {
        if (resData.message === "One-time verification code is required for login.") {
          setShowTotp(true);
          navigate('/onetimeverificationcode');
          return;
        } else if (resData.message === "Your account is not activated. Please activate your account to log in.") {
          navigate("/EmailVerificationForm");
          return;
        } else {
          throw new Error(resData.message);
        }
      }

      setLoading(false);
      const user = {
        userId: resData.userId,
        token: resData.token,
        role: resData.role,
      };
      Login(user);

      // ✅ **Check if the user's role matches an industry**
      const isBusinessIndustry = Array.isArray(businessRoles?.businesses) 
        ? businessRoles.businesses.some(
            (business) => business.industry === user?.role
          ) 
        : false;

      if (isBusinessIndustry) {
        navigate("/"); 
      } else {
        switch (user.role) {
          case "superadmin":
          case "logisticsmanager":
          case "support":
          case "rider":
          case "driver":
          case "marketplacemanager":
          case "merchant":
          case "charterer":
          case "freightmanager":
            navigate("/");
            break;
          case "customer":
            navigate("/Market");
            break;
          default:
            navigate("/"); // Default route
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    formData,
    loading,
    error,
    handleChange,
    handleLogin,
    setShowTotp,
    showTotp,
  };
};

export default useLoginForm;
