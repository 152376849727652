import React, { useContext, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../utils/spinner";
import useShippingCost from "../../libs/useShippingCost";
import useAllStates from "../../libs/getAllStates";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import { ReactComponent as AnimationIcon } from "../../Assets/animation_llz85rwh_small 1.svg";
import { ReactComponent as GetquoteIcon } from "../../Assets/QUOTE-text-svgrepo-com 1.svg";
import { ReactComponent as CautionIcon } from "../../Assets/QUOTE-text-svgrepo-com 1.svg";
import "./Superadmingetquote.css";

const Superadmingetquote = () => {
  const { isGetquoteOpen, setIsGetquoteOpen } = useContext(GlobalContext);

  const [resData, setResData] = useState(null);
  const [displayValidationError, setDisplayValidationError] = useState(false);
  const { mutation } = useShippingCost();
  const { data: allStates } = useAllStates();
  const [form, setForm] = useState({
    sender_state: "",
    receiver_state: "",
    items: [{ weight: "", quantity: "", items_value: "" }],
    isPickupRequired: false,
    isExpress: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setDisplayValidationError(false);
  };

  const handleBooleanChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value === "true",
    }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = form.items.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setForm((prevForm) => ({
      ...prevForm,
      items: updatedItems,
    }));
  };

  const handleAddItem = () => {
    setForm((prevForm) => ({
      ...prevForm,
      items: [...prevForm.items, { weight: "", quantity: "", items_value: "" }],
    }));
  };

  const handleRemoveItem = (index) => {
    setForm((prevForm) => ({
      ...prevForm,
      items: prevForm.items.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    setIsGetquoteOpen(true);
    const { sender_state, receiver_state, items, isPickupRequired, isExpress } =
      form;

    if (
      !sender_state ||
      !receiver_state ||
      items.some((item) => !item.weight || !item.quantity || !item.items_value)
    ) {
      setDisplayValidationError(true);
      setIsGetquoteOpen(false);

      setTimeout(() => {
        setDisplayValidationError(false);
      }, 3000);

      return;
    }

    try {
      const data = await mutation.mutateAsync({
        sender_state,
        receiver_state,
        isPickupRequired,
        isExpress,
        items: items.map((item) => ({
          ...item,
          weight: parseFloat(item.weight),
          items_value: parseFloat(item.items_value.replace(/[^0-9.-]+/g, "")),
          quantity: parseInt(item.quantity, 10),
        })),
      });

      setResData(data);
      setDisplayValidationError(false);
    } catch (error) {
      setDisplayValidationError(true);
      setIsGetquoteOpen(false);

      setTimeout(() => {
        setDisplayValidationError(false);
      }, 5000);

      toast.error(
        error?.response?.data?.message ||
          "Sorry, we are not shipping to these state combinations yet. Please try again later."
      );

      console.error(error);
    }
  };

  const handleDoneButtonClick = () => {
    setIsGetquoteOpen(false);
    setResData(null);
  };

  const formatToNGN = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    }).format(amount);
  };
  

  return (
    <Fragment>
       {isGetquoteOpen && (
        <div className="business-quote-pop-card">
          <div className="business-quote-pop-wrapper">
            {mutation.isLoading && <Spinner />}
            {mutation.isError && (
              <div className="error">
                <CautionIcon />
                {mutation.isError && <p>Error fetching shipping cost</p>}
                <button onClick={() => setIsGetquoteOpen(false)}>Okay</button>
              </div>
            )}
            {!mutation.isLoading && !mutation.isError && resData && (
              <>
                <h3>Shipment Cost Estimate</h3>
                <p>
                  This is just an estimated cost for your shipment. Actual price
                  may vary after creating a shipment.
                </p>
                <span>
                  <h3>Total Quantity: {resData?.totalQuantity || 0}</h3>
                  <h3>
                    Total Weight:
                    {resData?.totalWeight ? `${resData.totalWeight} kg` : "N/A"}
                  </h3>
                </span>
                <div className="quote-package-icon">
                  <AnimationIcon />
                </div>

                <div className="business-get-qoute-cost">
                  <div className="wrapper">
                    <p>Base Shipping Cost:</p>
                   
                    <p>{formatToNGN(resData?.originalShippingCost)}</p>
                  </div>

                  <div className="wrapper">
                    <p>Discount Applied:</p>
                    <p> {formatToNGN(resData?.totalDiscount)}</p>
                  </div>
                  <div className="wrapper">
                    <p>Pick up Cost:</p>
                    <p> {formatToNGN(resData?.pickupCharge)}</p>
                  </div>
                  <div className="wrapper">
                    <p>Express Cost:</p>
                    <p> {formatToNGN(resData?.expressCharge)}</p>
                  </div>


                  <div className="wrapper">
                    <p>Total Items Value:</p>
                    <p>{formatToNGN(resData?.totalItemsValue)}</p>
                  </div>

                  <div className="wrapper">
                    <p>Final Shipping Cost:</p>
                    <h3>{formatToNGN(resData?.totalShippingCost)}</h3>
                  </div>
                </div>
                <button onClick={handleDoneButtonClick}>Done</button>
              </>
            )}
          </div>
        </div>
      )}
      <div className="super-admin-get-quote">
        <div className="get-form-container">
          <div className="getquote-con">
            <span>
              <div className="card">
                <Link to="/superadminshippingcost">
                  <FaArrowLeft />
                </Link>
                <h3>Get Quote</h3>
              </div>
              <p>Get a free Shipping Quote online</p>
            </span>
            <GetquoteIcon />
          </div>
          <form>
            <div className="input-combination">
              <span>
                <label>Origin State</label>
                <select
                  name="sender_state"
                  value={form.sender_state}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Origin State</option>
                  {allStates &&
                    allStates.map((state) => (
                      <option key={state._id} value={state.state}>
                        {state.state}
                      </option>
                    ))}
                </select>
              </span>

              <span>
                <label>Destination State</label>
                <select
                  name="receiver_state"
                  value={form.receiver_state}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Destination State</option>
                  {allStates &&
                    allStates.map((state) => (
                      <option key={state._id} value={state.state}>
                        {state.state}
                      </option>
                    ))}
                </select>
              </span>
            </div>

            {form.items.map((item, index) => (
              <div key={index} className="form-group-item-group">
                <div className="input-combination">
                  <span>
                    <label htmlFor={`item_weight_${index}`}>Weight (kg)</label>
                    <input
                      type="number"
                      id={`item_weight_${index}`}
                      name="weight"
                      value={item.weight}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </span>
                </div>

                <div className="input-combination">
                  <span>
                    <label htmlFor={`item_quantity_${index}`}>Quantity</label>
                    <input
                      type="number"
                      id={`item_quantity_${index}`}
                      name="quantity"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </span>

                  <span>
                    <label htmlFor={`item_value_${index}`}>Item Value</label>
                    <input
                      type="text"
                      id={`item_value_${index}`}
                      name="items_value"
                      value={item.items_value}
                      onChange={(e) => handleItemChange(index, e)}
                    />
                  </span>
                </div>

                <div className="super-remove-card">
                  {form.items.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveItem(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}

            <div className="input-combination">
              <span>
                <label>Pickup Required?</label>
                <select
                  name="isPickupRequired"
                  value={form.isPickupRequired ? "true" : "false"}
                  onChange={handleBooleanChange}
                >
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </span>

              <span>
                <label>Express Delivery?</label>
                <select
                  name="isExpress"
                  value={form.isExpress ? "true" : "false"}
                  onChange={handleBooleanChange}
                >
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </span>
            </div>

            <div className="super-add-item-card">
              <button type="button" onClick={handleAddItem}>
                Add More Item
              </button>
            </div>

            {displayValidationError && (
              <p
                style={{
                  color: "red",
                  fontFamily: "Montserrat",
                  textAlign: "center",
                  margin: "10px auto",
                }}
              >
                Please ensure that all fields are filled.
              </p>
            )}

            <button type="button" onClick={handleSubmit}>
              Get Quotes
            </button>
          </form>
          <ToastContainer className="toast-container" />
        </div>
      </div>
    </Fragment>
  );
};

export default Superadmingetquote;
