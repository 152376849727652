import React, { useState, Fragment } from "react";
import useRaiseConcern from "../lib/useRaiseConcern";
import useValidateTracking from "../lib/useValidateTracking";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "./raise-concerns.css";

const RaiseConcerns = () => {
  const { mutate: raiseConcern, isLoading, error } = useRaiseConcern();
  const [concernData, setConcernData] = useState({
    trackingID: "",
    subject: "",
    message: "",
  });

  const navigate = useNavigate();

  const { isValid, message, status, isChecking } = useValidateTracking(
    concernData.trackingID
  );
  const [customSubject, setCustomSubject] = useState(false);

  const handleChange = (e) => {
    setConcernData({ ...concernData, [e.target.name]: e.target.value });
  };

  const handleSubjectChange = (e) => {
    if (e.target.value === "Other") {
      setCustomSubject(true);
      setConcernData({ ...concernData, subject: "" });
    } else {
      setCustomSubject(false);
      setConcernData({ ...concernData, subject: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid) return;
    raiseConcern(concernData, {
      onSuccess: () => {
        toast.success("Concern raised successfully!");
        setTimeout(() => {
          navigate("/my-concerns");
        }, 3000);
        setConcernData({ trackingID: "", subject: "", message: "" });
        setCustomSubject(false);
      },
    });
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className="business-create-concerns">
        <span onClick={() => navigate("/my-concerns")}>
          <FaArrowLeft />
          <h2>Raise a Concern</h2>
        </span>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Tracking ID:</label>
            <input
              type="text"
              name="trackingID"
              value={concernData.trackingID}
              onChange={handleChange}
              maxLength="11"
              required
              disabled={isChecking}
            />
            {isChecking ? (
              <p className="loading">⏳ Validating...</p>
            ) : (
              message && (
                <p
                  className={`tracking-messages ${
                    isValid ? "valid" : "errors"
                  }`}
                >
                  {isValid ? "✅ " : "❌ "} {message}
                </p>
              )
            )}
            {isValid && (
              <div className="tracking-status">
                📦 <p>Current Status:</p>
                <h3> {status}</h3>
              </div>
            )}
          </div>

          <div>
            <label>Subject:</label>
            <select
              name="subject"
              onChange={handleSubjectChange}
              required={!customSubject}
              disabled={!isValid || isChecking}
            >
              <option value="">Select a subject</option>
              <option value="Delayed Delivery">Delayed Delivery</option>
              <option value="Damaged Package">Damaged Package</option>
              <option value="Lost Package">Lost Package</option>
              <option value="Wrong Delivery">Wrong Delivery</option>
              <option value="Other">Other (Specify Below)</option>
            </select>
          </div>

          {/* Custom Subject Input */}
          {customSubject && (
            <div>
              <label>Custom Subject:</label>
              <input
                type="text"
                name="subject"
                value={concernData.subject}
                onChange={handleChange}
                required
                disabled={!isValid || isChecking}
              />
            </div>
          )}

          {/* Message Input */}
          <div>
            <label>Message:</label>
            <textarea
              name="message"
              value={concernData.message}
              onChange={handleChange}
              required
              disabled={!isValid || isChecking}
            ></textarea>
          </div>

          <button type="submit" disabled={isLoading || !isValid}>
            {isLoading ? "Submitting..." : "Raise Concern"}
          </button>
        </form>

        {error && <p className="error">{error.message}</p>}
      </div>
    </Fragment>
  );
};

export default RaiseConcerns;
