import usePackageDetails from "../libs/fetchPackageDetails";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import "./Superadminpackagedeatails.css";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

function Superadminpackagedeatails() {
  const { data, isLoading, isError } = usePackageDetails();
  const navigate = useNavigate();
  const packageDetails = data?.package;

  return (
    <div className="pkg-details-container">
      <div className="header">
        <IoMdArrowBack onClick={() => navigate("/")} className="icon" />
        <h1>Package Details</h1>
      </div>
      {isLoading && <Spinner />}
      {isError && <p style={{fontFamily: 'Montserrat', marginTop: '10px'}}>No package details available now!</p>}
      <div className="card">
        {!isLoading && !isError && (
          <>
            <div className="header">
              <p>ID : {packageDetails._id?.toUpperCase().slice(0, 8)}</p>
              <p>{formatDate(packageDetails?.dateCreated)}</p>
            </div>

            <div className="sender">
              <p>SENDER'S INFO</p>
            </div>
            <div className="sender-info">
              <div className="card">
                <p>Senders Full name</p>
                <span>{packageDetails?.sender?.name}</span>
              </div>
              <div className="card">
                <p>Email</p>
                <span>{packageDetails?.sender?.email}</span>
              </div>
              <div className="card">
                <p>Phone Number</p>
                <span>{packageDetails?.sender?.phone_number}</span>
              </div>
              <div className="card">
                <p>Home Adress</p>
                <span>{packageDetails?.sender?.address_1}</span>
              </div>
              <div className="card">
                <p>Other Adress</p>
                <span>{packageDetails?.sender?.address_2}</span>
              </div>
              <div className="card">
                <p>State</p>
                <span>{packageDetails?.sender?.state}</span>
              </div>
              <div className="card">
                <p>Zip Code</p>
                <span>{packageDetails?.sender?.zip_code}</span>
              </div>
            </div>
            <div className="sender">
              <p>RECEIVER’S INFO</p>
            </div>
            <div className="sender-info">
              <div className="card">
                <p>Senders Full name</p>
                <span>{packageDetails?.receiver?.name}</span>
              </div>
              <div className="card">
                <p>Email</p>
                <span>{packageDetails?.receiver?.email}</span>
              </div>
              <div className="card">
                <p>Phone Number</p>
                <span>{packageDetails?.receiver?.phone_number}</span>
              </div>
              <div className="card">
                <p>Home Adress</p>
                <span>{packageDetails?.receiver?.address_1}</span>
              </div>
              <div className="card">
                <p>Other Adress</p>
                <span>{packageDetails?.receiver?.address_2}</span>
              </div>
              <div className="card">
                <p>State</p>
                <span>{packageDetails?.receiver?.state}</span>
              </div>
              <div className="card">
                <p>Zip Code</p>
                <span>{packageDetails?.receiver?.zip_code}</span>
              </div>
            </div>
            <div className="sender">
              <p>ITEM(S) INFO</p>
            </div>
            <div className="sender-info">
            <div className="card">
                <p>Item(s) Name</p>
              <span>{packageDetails?.items?.map((item) => item.name).join(", ")}</span>
</div>
<div className="card">
  
<p>Weight</p>
  <span>{packageDetails?.items?.map((item) => item.weight).join(", ")}</span>
</div>
<div className="card">
  <p>Quantity</p>
  <span>{packageDetails?.items?.map((item) => item.quantity).join(", ")}</span>
</div>
              <div className="card">
                <p>Payment Method</p>
                <span>{packageDetails?.payment_method}</span>
              </div>
              <div className="card">
                <p>Est. Delivery date</p>
                <span>
                  {formatDate(packageDetails?.estimated_delivery_date)}
                </span>
              </div>
              <div className="card">
                  <p>Valued at</p>
                  <span>
  {packageDetails?.items
    ?.map((item) =>
      Number(item.items_value).toLocaleString("en-NG", {
        style: "currency",
        currency: "NGN",
      })
    )
    .join(", ")}
</span>

                </div>
              <div className="card">
                <p>Shipping Fee</p>
                <span>
                  {packageDetails?.shipping_cost
                    ? (packageDetails.shipping_cost).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "NGN",
                        }
                      )
                    : ""}
                </span>
              </div>
              <div className="card">
                <p>Delivery Method</p>
                <span>{packageDetails?.delivery_method}</span>
              </div>
              <div className="card">
                <p>Driver</p>
                <span>{packageDetails?.isAssignedToDriver ? "Yes" : "No"}</span>
              </div>
              <div className="card">
                <p>Rider</p>
                <span>{packageDetails?.isAssignedToRider ? "Yes" : "No"}</span>
              </div>
              <div className="card">
                <p>Waybill Number</p>
                <span>{packageDetails?.waybill?.waybillNumber}</span>
              </div>
              <div className="card">
                <p>Barcode</p>
                <img
                  src={`data:image/png;base64, ${packageDetails?.waybill?.barcode}`}
                  alt="Barcode"
                />
              </div>
            </div>
            <div className="sender">
                <p>OTHERS INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Original Shipping Cost</p>
                  <span>
                    {packageDetails?.original_shipping_cost
                      ? packageDetails?.original_shipping_cost.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "NGN",
                          }
                        )
                      : null}
                  </span>
                </div>
                <div className="card">
                  <p>Total Discount</p>
                  <span>
                    {packageDetails?.total_discount
                      ? packageDetails?.total_discount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : null}
                  </span>
                </div>
                <div className="card">
                  <p>Pick up</p>
                  <span>{packageDetails?.isPickupRequired ? "Yes" : "No"}</span>
                </div>
                <div className="card">
                  <p>Same Day Delivery</p>
                  <span>{packageDetails?.isEmergency ? "Yes" : "No"}</span>
                </div>
              </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Superadminpackagedeatails;
