import usePackageDetails from "../../super_admin/libs/fetchPackageDetails";
import { useNavigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import { IoMdArrowBack } from "react-icons/io";
import { Fragment, useState, useEffect } from "react";
import { ReactComponent as WarnIcon } from "../../asset/caution-svgrepo-com 1.svg";
import useDeletePackage from "../libs/useDeletePackage";
import useUpdatePackage from "../libs/useUpdatePackage";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

function Logisticpackagedetails() {
  const { data, isLoading, isError } = usePackageDetails();
  const packageDetails = data?.package;
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [updateOverlay, setUpdateOverlay] = useState(false);
  const [formData, setFormData] = useState({
    senderName: "",
    senderEmail: "",
    senderPhoneNumber: "",
    senderAddress1: "",
    senderAddress2: "",
    senderZipCode: "",
    senderState: "",
    receiverName: "",
    receiverEmail: "",
    receiverPhoneNumber: "",
    receiverAddress1: "",
    receiverAddress2: "",
    receiverZipCode: "",
    receiverState: "",
  });
  useEffect(() => {
    if (packageDetails) {
      setFormData({
        senderName: packageDetails.sender?.name || "",
        senderEmail: packageDetails.sender?.email || "",
        senderPhoneNumber: packageDetails.sender?.phone_number || "",
        senderAddress1: packageDetails.sender?.address_1 || "",
        senderAddress2: packageDetails.sender?.address_2 || "",
        senderZipCode: packageDetails.sender?.zip_code || "",
        senderState: packageDetails.sender?.state || "",
        receiverName: packageDetails.receiver?.name || "",
        receiverEmail: packageDetails.receiver?.email || "",
        receiverPhoneNumber: packageDetails.receiver?.phone_number || "",
        receiverAddress1: packageDetails.receiver?.address_1 || "",
        receiverAddress2: packageDetails.receiver?.address_2 || "",
        receiverZipCode: packageDetails.receiver?.zip_code || "",
        receiverState: packageDetails.receiver?.state || "",
      });
    }
  }, [packageDetails]);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const navigate = useNavigate();

  const { deletePackage, isLoading: deleteLoading } = useDeletePackage();
  const { updatePackage, isUpdating } = useUpdatePackage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { _id } = packageDetails;
    await updatePackage({ packageId: _id, updatedData: formData });

    setUpdateOverlay(false);
  };

  const handleDeleteConfirmation = async () => {
    await deletePackage(packageDetails?._id);
    setDeleteOverlay(false);
    navigate("/Logisticspackages");
  };
  return (
    <Fragment>
      {updateOverlay && (
        <div className="update-pkg">
          <form className="card" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="senderName">Sender's Name:</label>
              <input
                type="text"
                id="senderName"
                value={formData.senderName}
                onChange={(e) => handleChange("senderName", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderEmail">Sender's Email:</label>
              <input
                type="text"
                id="senderEmail"
                value={formData.senderEmail}
                onChange={(e) => handleChange("senderEmail", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderPhoneNumber">Sender's Phone Number:</label>
              <input
                type="text"
                id="senderPhoneNumber"
                value={formData.senderPhoneNumber}
                onChange={(e) =>
                  handleChange("senderPhoneNumber", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderAddress1">Sender's Address 1:</label>
              <input
                type="text"
                id="senderAddress1"
                value={formData.senderAddress1}
                onChange={(e) => handleChange("senderAddress1", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderAddress2">Sender's Address 2:</label>
              <input
                type="text"
                id="senderAddress2"
                value={formData.senderAddress2}
                onChange={(e) => handleChange("senderAddress2", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderState">Sender's State:</label>
              <input
                type="text"
                id="senderState"
                value={formData.senderState}
                onChange={(e) => handleChange("senderState", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderZipCode">Sender's Zip Code:</label>
              <input
                type="text"
                id="senderZipCode"
                value={formData.senderZipCode}
                onChange={(e) => handleChange("senderZipCode", e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="receiverName">Receiver's Name:</label>
              <input
                type="text"
                id="receiverName"
                value={formData.receiverName}
                onChange={(e) => handleChange("receiverName", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="receiverEmail">Receiver's Email:</label>
              <input
                type="text"
                id="receiverEmail"
                value={formData.receiverEmail}
                onChange={(e) => handleChange("receiverEmail", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="receiverPhoneNumber">
                Receiver's Phone Number:
              </label>
              <input
                type="text"
                id="receiverPhoneNumber"
                value={formData.receiverPhoneNumber}
                onChange={(e) =>
                  handleChange("receiverPhoneNumber", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="receiverAddress1">Receiver's Address 1:</label>
              <input
                type="text"
                id="receiverAddress1"
                value={formData.receiverAddress1}
                onChange={(e) =>
                  handleChange("receiverAddress1", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="receiverAddress2">Receiver's Address 2:</label>
              <input
                type="text"
                id="receiverAddress2"
                value={formData.receiverAddress2}
                onChange={(e) =>
                  handleChange("receiverAddress2", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="receiverState">Receiver's State:</label>
              <input
                type="text"
                id="receiverState"
                value={formData.receiverState}
                onChange={(e) => handleChange("receiverState", e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="receiverZipCode">Receiver's Zip Code:</label>
              <input
                type="text"
                id="receiverZipCode"
                value={formData.receiverZipCode}
                onChange={(e) =>
                  handleChange("receiverZipCode", e.target.value)
                }
              />
            </div>

            <div className="form-groups">
              <button onClick={() => setUpdateOverlay(false)}>Cancel</button>
              <button type="submit" disabled={isUpdating}>
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}

      {deleteOverlay && (
        <div className="delete-pkg">
          <div className="card">
            <WarnIcon />
            <h3>Delete</h3>
            <p>
              Are you sure you want to delete this package? Note this action can
              not be undo.
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button
                onClick={handleDeleteConfirmation}
                disabled={deleteLoading}
              >
                {deleteLoading ? "Deleting.." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="pkg-details-container">
        <div className="header">
          <IoMdArrowBack
            onClick={() => navigate("/Logisticactivepkg")}
            className="icon"
          />
          <h1>Package Details</h1>
        </div>
        {isLoading && <Spinner />}
        {isError && <p>An Errror occured!</p>}
        <div className="card">
          {!isLoading && !isError && (
            <>
              <div className="header">
                <p>ID : {packageDetails._id?.toUpperCase().slice(0, 8)}</p>
                <p>{formatDate(packageDetails?.dateCreated)}</p>
              </div>

              <div className="updates-btn">
                <button onClick={() => setUpdateOverlay(true)}>
                  Update shipping info
                </button>
                <button onClick={() => setDeleteOverlay(true)}>
                  Delete Package
                </button>
              </div>

              <div className="sender">
                <p>SENDER'S INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Senders Full name</p>
                  <span>{packageDetails?.sender?.name}</span>
                </div>
                <div className="card">
                  <p>Email</p>
                  <span>{packageDetails?.sender?.email}</span>
                </div>
                <div className="card">
                  <p>Phone Number</p>
                  <span>{packageDetails?.sender?.phone_number}</span>
                </div>
                <div className="card">
                  <p>Home Adress</p>
                  <span>{packageDetails?.sender?.address_1}</span>
                </div>
                <div className="card">
                  <p>Other Adress</p>
                  <span>{packageDetails?.sender?.address_2}</span>
                </div>
                <div className="card">
                  <p>State</p>
                  <span>{packageDetails?.sender?.state}</span>
                </div>
                <div className="card">
                  <p>Zip Code</p>
                  <span>{packageDetails?.sender?.zip_code}</span>
                </div>
              </div>
              <div className="sender">
                <p>RECEIVER’S INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Senders Full name</p>
                  <span>{packageDetails?.receiver?.name}</span>
                </div>
                <div className="card">
                  <p>Email</p>
                  <span>{packageDetails?.receiver?.email}</span>
                </div>
                <div className="card">
                  <p>Phone Number</p>
                  <span>{packageDetails?.receiver?.phone_number}</span>
                </div>
                <div className="card">
                  <p>Home Adress</p>
                  <span>{packageDetails?.receiver?.address_1}</span>
                </div>
                <div className="card">
                  <p>Other Adress</p>
                  <span>{packageDetails?.receiver?.address_2}</span>
                </div>
                <div className="card">
                  <p>State</p>
                  <span>{packageDetails?.receiver?.state}</span>
                </div>
                <div className="card">
                  <p>Zip Code</p>
                  <span>{packageDetails?.receiver?.zip_code}</span>
                </div>
              </div>
              <div className="sender">
                <p>ITEM(S) INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Item(s) Name</p>
                  <span>
                    {packageDetails?.items?.map((item) => item.name).join(", ")}
                  </span>
                </div>
                <div className="card">
                  <p>Weight</p>
                  <span>
                    {packageDetails?.items
                      ?.map((item) => item.weight)
                      .join(", ")}
                  </span>
                </div>
                <div className="card">
                  <p>Quantity</p>
                  <span>
                    {packageDetails?.items
                      ?.map((item) => item.quantity)
                      .join(", ")}
                  </span>
                </div>

                <div className="card">
                  <p>Payment Method</p>
                  <span>{packageDetails?.payment_method}</span>
                </div>
                <div className="card">
                  <p>Est. Delivery date</p>
                  <span>
                    {formatDate(packageDetails?.estimated_delivery_date)}
                  </span>
                </div>
                <div className="card">
                  <p>Valued at</p>
                  <span>
                    {packageDetails?.items
                      ?.map((item) =>
                        Number(item.items_value).toLocaleString("en-NG", {
                          style: "currency",
                          currency: "NGN",
                        })
                      )
                      .join(", ")}
                  </span>
                </div>
                <div className="card">
                  <p>Shipping Fees</p>
                  <span>
                    {packageDetails?.shipping_cost
                      ? packageDetails?.shipping_cost.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : null}
                  </span>
                </div>
                <div className="card">
                  <p>Delivery Method</p>
                  <span>{packageDetails?.delivery_method}</span>
                </div>
                <div className="card">
                  <p>Driver</p>
                  <span>
                    {packageDetails?.isAssignedToDriver ? "Yes" : "No"}
                  </span>
                </div>
                <div className="card">
                  <p>Rider</p>
                  <span>
                    {packageDetails?.isAssignedToRider ? "Yes" : "No"}
                  </span>
                </div>
                <div className="card">
                  <p>Waybill Number</p>
                  <span>{packageDetails?.waybill?.waybillNumber}</span>
                </div>
                <div className="card">
                  <p>Barcode</p>
                  <img
                    src={`data:image/png;base64, ${packageDetails?.waybill?.barcode}`}
                    alt="Barcode"
                  />
                </div>
              </div>
              <div className="sender">
                <p>OTHERS INFO</p>
              </div>
              <div className="sender-info">
                <div className="card">
                  <p>Original Shipping Cost</p>
                  <span>
                    {packageDetails?.original_shipping_cost
                      ? packageDetails?.original_shipping_cost.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "NGN",
                          }
                        )
                      : null}
                  </span>
                </div>
                <div className="card">
                  <p>Total Discount</p>
                  <span>
                    {packageDetails?.total_discount
                      ? packageDetails?.total_discount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        })
                      : 0}
                  </span>
                </div>
                <div className="card">
                  <p>Pick up</p>
                  <span>{packageDetails?.isPickupRequired ? "Yes" : "No"}</span>
                </div>
                <div className="card">
                  <p>Same Day Delivery</p>
                  <span>{packageDetails?.isEmergency ? "Yes" : "No"}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Logisticpackagedetails;
