import React, { useState } from 'react';
import Businessinternationalshipment from '../Business_Type/businessinternationalshipment';
import Businessnationalshipment from '../Business_Type/businessnationalshipment';
import { TfiPackage } from "react-icons/tfi";
import "./Business_Shipment.css";


export default function Businessshipment() {
    const [national, setNational] = useState(true);
    const [international,  setInternational] = useState(false);
    const [selected, setSelected] = useState("national"); 


    const handleNational = () => {
        setNational(true);
        setInternational(false);
    };

    const handleInternational = () => {
        setNational(false);
        setInternational(true);
    };

    const handleNationalClick = () => {
        setSelected("national");
        handleNational(); 
      };
    
      const handleInternationalClick = () => {
        setSelected("international");
        handleInternational();
      };


  return (
    <div className='business_shipment'>
        <span>
        <TfiPackage className='shipment-icon'/>
        <h3>Shipments</h3>
        </span>
        <div className="switch">
            <button onClick={handleNationalClick} className={selected === "national" ? "active-btn" : ""}>National Shipment</button>
            <button onClick={handleInternationalClick} className={selected === "international" ? "active-btn" : ""}>International Shipment</button>
        </div>
        {national && <Businessnationalshipment/>}
        {international && <Businessinternationalshipment/>}
    </div>
  )
}
