// import React, { useContext, useState } from "react";
// import { ReactComponent as GetquoteIcon } from "../../Assets/QUOTE-text-svgrepo-com 1.svg";
// import { GlobalContext } from "../../GlobalContext/GlobalContext";
// import { ReactComponent as AnimationIcon } from "../../Assets/animation_llz85rwh_small 1.svg";
// import { ReactComponent as CautionIcon } from "../../Assets/caution-svgrepo-com 1.svg";
// import { Fragment } from "react";
// import useShippingCost from "../../libs/useShippingCost";
// import useAllStates from "../../libs/getAllStates";
// import Spinner from "../../Spinner/Spinner";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const defaultForm = {
//   sender_state: "",
//   receiver_state: "",
//   weight: "",
//   quantity: "",
// };

// const GetQuoteForm = () => {
//   const [form, setForm] = useState(defaultForm);
//   const { isGetquoteOpen, setIsGetquoteOpen } = useContext(GlobalContext);
//   const { sender_state, receiver_state, weight, quantity } = form;
//   const [resData, setResData] = useState(null);
//   const [items_value, setItesm_value] = useState("");
//   const [displayValidationError, setDisplayValidationError] = useState(false);
//   const { mutation, isLoading, isError } = useShippingCost();
//   const { data: allStates } = useAllStates();

//   const handleSubmit = async () => {
//     setIsGetquoteOpen(true);

// if (
//   sender_state.length === 0 ||
//   receiver_state.length === 0 ||
//   weight.length === 0 ||
//   quantity.length === 0 ||
//   items_value.length === 0
// ) {
//   setDisplayValidationError(true);
//   setIsGetquoteOpen(false);
//   return;
// }

//     const numericPrice = parseFloat(items_value.replace(/[^0-9.-]+/g, ""));

//     try {
//       const data = await mutation.mutateAsync({
//         sender_state: sender_state,
//         receiver_state: receiver_state,
//         weight: weight,
//         quantity: quantity,
//         items_value: numericPrice,
//       });

//       setResData(data);
//       setDisplayValidationError(false);
//     } catch (error) {
//       setDisplayValidationError(false);
//       setIsGetquoteOpen(false);
//       if (error?.response?.data?.message) {
//         toast.error(mutation.isError);
//       } else {
//         toast.error(
//           "Sorry, we are not shipping to these state combination yet,Please try again later."
//         );
//       }
//       console.error(error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setForm({ ...form, [name]: value });
//     setDisplayValidationError(false);
//   };

//   const handleDoneButtonClick = () => {
//     setIsGetquoteOpen(false);
//     setResData(null);
//   };

//   const handleItems_valueChange = (event) => {
//     let inputValue = event.target.value.replace(/[^\d]/g, "");
//     inputValue = Number(inputValue) / 100;

//     const items_value = inputValue.toLocaleString("en-US", {
//       style: "currency",
//       currency: "NGN",
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     });
//     setItesm_value(items_value);
//   };

//   return (
//     <Fragment>
//       {isGetquoteOpen && (
//         <div className="get-quote-pop-card">
//           <div className="get-quote-pop-wrapper">
//             {mutation.isLoading && <Spinner />}
// {isError && (
//   <div className="error">
//     <CautionIcon />
//     {mutation.isError && <p>Error fetching shipping cost</p>}
//     <button onClick={() => setIsGetquoteOpen(false)}>Okay</button>
//   </div>
// )}
//             {!isLoading && !isError && resData && (
//               <>
//                 <h3>Shipment Cost Estimate</h3>
//                 <p>
//                   This is just an estimated cost for your shipment. Actual price
//                   may vary after creating a shipment
//                 </p>
//                 <span>
//                   <h3>Quantity: {resData?.quantity}</h3>
//                   <h3>Weight: {resData?.weight}kg</h3>
//                 </span>
//                 <div className="get-quote-package">
//                   <AnimationIcon />
//                 </div>
//                 <div className="get-qoute-cost">
//                   <span>
//                     <h3>Total Item Cost:</h3>
//                     <h3>
//                       {resData?.totalShippingCost
//                         ? resData.totalShippingCost.toLocaleString("en-US", {
//                             style: "currency",
//                             currency: "NGN",
//                           })
//                         : ""}
//                     </h3>
//                   </span>
//                 </div>
//                 <button onClick={handleDoneButtonClick}>Done</button>
//               </>
//             )}
//           </div>
//         </div>
//       )}
//       <div className="getform-container">
//         <div className="getquote-con">
//           <span>
//             <h3>Get Quote</h3>
//             <p>Get a free Shipping Quote online</p>
//           </span>
//           <GetquoteIcon />
//         </div>
//         <form>
//           <div className="input-combination">
//             <span>
//               <label>Origin State</label>
//               <select
//                 name="sender_state"
//                 value={sender_state}
//                 onChange={handleChange}
//                 required
//               >
//                 <option value="">Select Origin State</option>
//                 {allStates &&
//                   allStates.map((state) => (
//                     <option key={state._id} value={state.state}>
//                       {state.state}
//                     </option>
//                   ))}
//               </select>
//             </span>

//             <span>
//               <label>Destination State</label>
//               <select
//                 name="receiver_state"
//                 value={receiver_state}
//                 onChange={handleChange}
//                 required
//               >
//                 <option value="">Select Destination State</option>
//                 {allStates &&
//                   allStates.map((state) => (
//                     <option key={state._id} value={state.state}>
//                       {state.state}
//                     </option>
//                   ))}
//               </select>
//             </span>
//           </div>

//           <div className="input-combination">
//             <span>
//               <label>Weight(kg)</label>
//               <input
//                 type="number"
//                 name="weight"
//                 placeholder="Weight"
//                 value={weight}
//                 onChange={handleChange}
//                 required
//               />
//             </span>

//             <span>
//               <label>Quantity</label>
//               <input
//                 type="number"
//                 name="quantity"
//                 placeholder="Enter Quantity"
//                 value={quantity}
//                 onChange={handleChange}
//                 required
//               />
//             </span>
//           </div>
//           <div className="input-combination">
//             <span>
//               <label>Value</label>
//               <input
//                 value={items_value}
//                 placeholder="Enter Items value"
//                 onChange={handleItems_valueChange}
//                 required
//               />
//             </span>
//           </div>
// {displayValidationError && (
//   <p
//     style={{
//       color: "red",
//       fontFamily: "Montserrat",
//       textAlign: "center",
//       margin: "10px auto",
//     }}
//   >
//     Please ensure that all fields are filled.
//   </p>
// )}

//           <button type="button" onClick={handleSubmit}>
//             Get Quotes
//           </button>
//         </form>
//       </div>
//       <ToastContainer className="toast-container" />
//     </Fragment>
//   );
// };

// export default GetQuoteForm;




import React, { useContext, useState, Fragment } from "react";
import { ReactComponent as GetquoteIcon } from "../../Assets/QUOTE-text-svgrepo-com 1.svg";
import { ReactComponent as CautionIcon } from "../../Assets/caution-svgrepo-com 1.svg";
import { ReactComponent as AnimationIcon } from "../../Assets/animation_llz85rwh_small 1.svg";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import useStatesAndCities from "../../libs/getStateAndCities";
import useLocalShippingCost from "../../libs/fetchLocalShippingCost";
import Spinner from "../../Spinner/Spinner";

const defaultForm = {
  senderState: "",
  senderCity: "",
  receiverState: "",
  receiverCity: "",
  weight: "",
  quantity: "",
};

const GetQuoteForm = () => {
  const [form, setForm] = useState(defaultForm);
  const [itemsValue, setItemsValue] = useState("");
  const [isEmergency, setIsEmergency] = useState("");
  const [isPickupRequired, setIsPickupRequired] = useState("");
  const [displayValidationError, setDisplayValidationError] = useState(false);
  const [resData, setResData] = useState(null);
  const { isGetquoteOpen, setIsGetquoteOpen } = useContext(GlobalContext);

  const { data: statesAndCities } = useStatesAndCities();
  const {
    mutateAsync,
    isLoading: isMutationLoading,
    isError,
  } = useLocalShippingCost().mutation;

  const getCitiesForState = (stateId) => {
    const state = statesAndCities?.states.find(
      (state) => state._id === stateId
    );
    return state?.cities || [];
  };

  const handleSubmit = async () => {
    setResData(null);

    if (
      !Object.values(form).every((value) => value.trim()) ||
      !itemsValue.trim() ||
      isEmergency === "" ||
      isPickupRequired === ""
    ) {
      setDisplayValidationError(true);
      return;
    }

    try {
      const numericPrice = parseFloat(itemsValue.replace(/[^0-9.-]+/g, ""));
      const payload = {
        itemsValue: numericPrice,
        quantity: form.quantity,
        weight: form.weight,
        senderState:
          statesAndCities?.states.find((s) => s._id === form.senderState)
            ?.name || "",
        senderCity:
          getCitiesForState(form.senderState).find(
            (c) => c._id === form.senderCity
          )?.name || "",
        receiverState:
          statesAndCities?.states.find((s) => s._id === form.receiverState)
            ?.name || "",
        receiverCity:
          getCitiesForState(form.receiverState).find(
            (c) => c._id === form.receiverCity
          )?.name || "",
        isEmergency: isEmergency === "true",
        isPickupRequired: isPickupRequired === "true",
      };

      setIsGetquoteOpen(true);
      const data = await mutateAsync(payload);
      setResData(data);
      setDisplayValidationError(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setDisplayValidationError(false);
  };

  const handleItemsValueChange = (e) => {
    let inputValue = e.target.value.replace(/[^\d]/g, "");
    const formattedValue = (Number(inputValue) / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    });
    setItemsValue(formattedValue);
  };

  const formatToNGN = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <Fragment>
      {isGetquoteOpen && (
        <div className="get-quote-pop-card">
          <div className="get-quote-pop-wrapper">
            {isMutationLoading && <Spinner />}
            {isError && (
              <>
                <CautionIcon />
                <p
                  style={{
                    fontFamily: "Montserrat",
                    color: "red",
                    textAlign: "center",
                  }}
                >
                  {mutateAsync.error?.response?.data?.message ||
                    "Sorry, we are not shipping to these city combination yet, Please try again."}
                </p>
                <button onClick={() => setIsGetquoteOpen(false)}>Okay</button>
              </>
            )}

            {resData && (
              <>
                <h3>Shipment Cost Estimate</h3>
                <p>
                  This is just an estimated cost for your shipment. Actual price
                  may vary after creating a shipment.
                </p>
                <span>
                  <h3>Quantity: {resData.quantity}</h3>
                  <h3>Weight: {resData.weight}kg</h3>
                </span>
                <div className="get-quote-package">
                  <AnimationIcon />
                </div>

                <div className="user-get-qoute-cost">
                  <div className="wrapper">
                    <p>Base Shipping Cost:</p>
                    <p>{formatToNGN(resData?.originalShippingCost)}</p>
                  </div>

                  <div className="wrapper">
                    <p>Discount Applied:</p>
                    <p>{formatToNGN(resData?.totalDiscount)}</p>
                  </div>

                  {resData.emergencyCharge > 0 && (
                    <div className="wrapper">
                      <p>Express Charges:</p>
                      <p>{formatToNGN(resData.emergencyCharge)}</p>
                    </div>
                  )}

                  {resData.pickupCharge > 0 && (
                    <div className="wrapper">
                      <p>Pick Up Charge:</p>
                      <p>{formatToNGN(resData.pickupCharge)}</p>
                    </div>
                  )}

                  <div className="wrapper">
                    <p>Final Shipping Cost:</p>
                    <h3>{formatToNGN(resData?.amountToPay)}</h3>
                  </div>
                </div>
                <button
                  onClick={() => {
                    setIsGetquoteOpen(false);
                    setResData(null);
                  }}
                >
                  Done
                </button>
              </>
            )}
          </div>
        </div>
      )}
      <div className="getform-container">
        <div className="getquote-con">
          <span>
            <h3>Get Quote</h3>
            <p>Get a free Shipping Quote online</p>
          </span>
          <GetquoteIcon />
        </div>
        <form>
          <div className="input-combination">
            <span>
              <label>Origin State</label>
              <select
                name="senderState"
                value={form.senderState}
                onChange={handleChange}
              >
                <option value="">Select Origin State</option>
                {statesAndCities?.states.map((state) => (
                  <option key={state._id} value={state._id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label>Origin City</label>
              <select
                name="senderCity"
                value={form.senderCity}
                onChange={handleChange}
              >
                <option value="">Select Origin City</option>
                {getCitiesForState(form.senderState).map((city) => (
                  <option key={city._id} value={city._id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <div className="input-combination">
            <span>
              <label>Receiver State</label>
              <select
                name="receiverState"
                value={form.receiverState}
                onChange={handleChange}
              >
                <option value="">Select Receiver State</option>
                {statesAndCities?.states.map((state) => (
                  <option key={state._id} value={state._id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label>Receiver City</label>
              <select
                name="receiverCity"
                value={form.receiverCity}
                onChange={handleChange}
              >
                <option value="">Select Receiver City</option>
                {getCitiesForState(form.receiverState).map((city) => (
                  <option key={city._id} value={city._id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <div className="input-combination">
            <span>
              <label>Weight (kg)</label>
              <input
                type="number"
                name="weight"
                placeholder="Enter Weight"
                value={form.weight}
                onChange={handleChange}
              />
            </span>
            <span>
              <label>Quantity</label>
              <input
                type="number"
                name="quantity"
                placeholder="Enter Quantity"
                value={form.quantity}
                onChange={handleChange}
              />
            </span>
          </div>
          <div className="input-combination">
            <span>
              <label>Pick Up</label>
              <select
                name="isPickup"
                value={isPickupRequired}
                onChange={(e) => setIsPickupRequired(e.target.value)}
                required
              >
                <option value="">Select</option>
                <option value="true">Pick Up</option>
                <option value="false">Regular</option>
              </select>
            </span>
            <span>
              <label>Express Delivery</label>
              <select
                name="isEmergency"
                value={isEmergency}
                onChange={(e) => setIsEmergency(e.target.value)}
                required
              >
                <option value="">Select</option>
                <option value="true">Express</option>
                <option value="false">Regular</option>
              </select>
            </span>
          </div>
          <div className="value-input">
            <span>
              <label>Value</label>
              <input
                value={itemsValue}
                placeholder="Enter Items Value"
                onChange={handleItemsValueChange}
                required
              />
            </span>
          </div>
          {displayValidationError && (
            <p
              style={{
                color: "red",
                fontFamily: "Montserrat",
                textAlign: "center",
                margin: "10px auto",
              }}
            >
              Please ensure that all fields are filled.
            </p>
          )}
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isMutationLoading}
          >
            Get Quotes
          </button>
        </form>
      </div>
    </Fragment>
  );
};

export default GetQuoteForm;
