import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import useBusinessTypes from "./lib/fetchBusinessType";
import useAllStates from "../../libs/getAllStates";
import useRegisterBusiness from "../../Auth/BusinessDetails/lib/registerBusiness";
import "./Business_details.css";

export default function Businessdetails() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [registrationData, setRegistrationData] = useState(null);
  const { registerBusiness, isLoading, successMessage, isError } = useRegisterBusiness();

  const [formInput, setFormInput] = useState({
    company_name: "",
    business_email: "",
    business_phone_number: "",
    business_address: "",
    state: "",
    role: "",
  });

  const { data } = useBusinessTypes();
  const { data: allStates } = useAllStates();

  const businessTypes = data?.businesses
    ? [...new Set(data.businesses.map((business) => business.industry))]
    : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    setError(null);
  };

  useEffect(() => {
    if (error || successMessage) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, successMessage]);

  const validateForm = () => {
    const { company_name, business_email, business_phone_number, business_address, state, role } = formInput;

    if (!company_name || !business_email || !business_phone_number || !business_address || !state || !role) {
      setError("All fields are required.");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(business_email)) {
      setError("Invalid email format.");
      return false;
    }

    if (business_phone_number.length < 10) {
      setError("Invalid phone number.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    // Get stored business details
    const storedBusinessDetails = sessionStorage.getItem("businessDetails");
    const businessFormData = storedBusinessDetails ? JSON.parse(storedBusinessDetails) : {};
  
    const combinedData = {
      ...businessFormData,
      ...formInput,
    };
  
    try {
      const response = await registerBusiness(combinedData);
      console.log("Registration Response:", response);
  
      if (response.message === "Activation code sent to email") {
        // Store registration response & trigger navigation
        setRegistrationData(response);
      } else {
        setError(response.message || "Registration failed.");
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setError("An unexpected error occurred.");
    }
  };
  
  // **Navigate only after successful registration & email is available**
  useEffect(() => {
    const storedBusinessDetails = sessionStorage.getItem("businessDetails");
    const businessFormData = storedBusinessDetails ? JSON.parse(storedBusinessDetails) : {};
  
    if (registrationData && businessFormData.email) {
      console.log("Navigating to Email Verification...");
      navigate(`/EmailVerificationForm?email=${encodeURIComponent(businessFormData.email)}`);
    }
  }, [registrationData, navigate]);
  

  return (
    <div className="business-details">
      <div className="card-1">
        <div className="login-image-overlay">
          <p>Empowering your business with seamless last-mile solutions.</p>
        </div>
      </div>
      <div className="card-2">
        <span>
          <FaArrowLeft onClick={() => navigate("/SignUp")} className="icon" />
          <h3>Back</h3>
        </span>
        <p>Enter your business details</p>
        <form onSubmit={handleSubmit}>
          <div className="input-combination">
            <span>
              <label>Company Name</label>
              <input
                type="text"
                name="company_name"
                placeholder="Enter Your Company Name"
                value={formInput.company_name}
                onChange={handleChange}
              />
            </span>

            <span>
              <label>Business Email Address</label>
              <input
                type="email"
                name="business_email"
                placeholder="Enter Business Email Address"
                value={formInput.business_email}
                onChange={handleChange}
              />
            </span>
          </div>

          <div className="input-combination">
            <span>
              <label>Business Phone Number</label>
              <input
                type="tel"
                name="business_phone_number"
                placeholder="Enter Business Phone Number"
                value={formInput.business_phone_number}
                onChange={handleChange}
              />
            </span>

            <span>
              <label>Business Address</label>
              <input
                type="text"
                name="business_address"
                placeholder="Enter Business Address"
                value={formInput.business_address}
                onChange={handleChange}
              />
            </span>
          </div>

          <div className="input-combination">
            <span>
              <label>State</label>
              <select name="state" value={formInput.state} onChange={handleChange}>
                <option value="">Select State</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state._id} value={state.state}>
                      {state.state}
                    </option>
                  ))}
              </select>
            </span>

            <span>
              <label>Business Type</label>
              <select name="role" value={formInput.role} onChange={handleChange}>
                <option value="">Select Business Type</option>
                {businessTypes.length > 0 ? (
                  businessTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))
                ) : (
                  <option disabled>No Business Types Available</option>
                )}
              </select>
            </span>
          </div>

          <button type="submit" disabled={isLoading}>
            {isLoading ? "Registering..." : "Register"}
          </button>

          {isError && (
            <p style={{ color: "red", textAlign: "center", fontFamily: "Montserrat" }}>{isError}</p>
          )}

          {error && (
            <p style={{ color: "red", textAlign: "center", fontFamily: "Montserrat" }}>{error}</p>
          )}

          {successMessage && (
            <p style={{ color: "green", textAlign: "center", fontFamily: "Montserrat" }}>
              {successMessage}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

