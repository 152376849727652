import React, { useRef, useCallback, useContext, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";
import usePendingPackages from "../lib/usePendingPackages";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import Spinner from "../../utils/spinner";
import "./business_pending_order.css";

const BusinessPendingOrder = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useContext(AuthContext);
  const userId = user?.userId;

  const {
    data,
    isLoading,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = usePendingPackages(userId);

  const observerRef = useRef();
  const lastPendingElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const allPackages = data?.pages.flatMap((page) => page.pendingPackages) || [];
  const filteredPackages = allPackages.filter((pkg) =>
    pkg.tracking_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="pending-orders-container">
      <div className="header">
        <p>Pending Packages</p>
        <span>
          <SearchIcon />
          <input
            type="search"
            placeholder="Search by Tracking Number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </span>
      </div>

      <div className="pending-container">
        {isLoading && <Spinner />}

        {error && (
          <p style={{ fontFamily: "Montserrat" }}>
            No Pending package available now
          </p>
        )}

        {!isLoading && !error && (
          <table>
            <thead>
              <tr>
                <th>Tracking</th>
                <th>Sender</th>
                <th>Sender Phone</th>
                <th>Receiver</th>
                <th>Receiver Phone</th>
                <th>Items</th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Delivery Method</th>
                <th>Estimated Delivery</th>
              </tr>
            </thead>
            <tbody>
              {filteredPackages.length === 0 ? (
                <tr>
                  <td colSpan="10" style={{ fontFamily: "Montserrat" }}>
                    No Pending packages found.
                  </td>
                </tr>
              ) : (
                filteredPackages.map((pkg, index) => (
                  <tr
                    key={pkg._id}
                    ref={
                      index === filteredPackages.length - 1
                        ? lastPendingElementRef
                        : null
                    }
                  >
                    <td>{pkg.tracking_number}</td>
                    <td>{pkg.sender?.name || "N/A"}</td>
                    <td>{pkg.sender?.phone_number || "N/A"}</td>
                    <td>{pkg.receiver?.name || "N/A"}</td>
                    <td>{pkg.receiver?.phone_number || "N/A"}</td>
                    <td>
                      <ul>
                        {pkg.items?.map((item) => (
                          <li key={item._id}>{item.name}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {pkg.items?.map((item) => (
                          <li key={item._id}>{item.quantity}</li>
                        ))}
                      </ul>
                    </td>
                    <td>{pkg.status[0]?.status || "N/A"}</td>
                    <td>{pkg.delivery_method || "N/A"}</td>
                    <td>
                      {pkg.estimated_delivery_date
                        ? new Date(
                            pkg.estimated_delivery_date
                          ).toLocaleDateString()
                        : "N/A"}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default BusinessPendingOrder;
