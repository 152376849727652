import React from 'react';
import "./Bulk_ship_icon.css";

export default function Upload() {
  return (
    <div className='add-icon'>
        <div className="wrapper">
        <div className="add-flow"></div>
        <p>Add</p>
        </div>
        <div className="wrapper">
        <div className="add-flow"></div>
        <p>Upload</p>
        </div>
        <div className="wrapper">
        <div className="creation-flow"></div>
        <p>Create</p>
        </div>
    </div>
  )
}