import React, { useState, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import * as XLSX from "xlsx";
import Upload from "../../utils/Bulk_ship_icon/upload";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { TbCloudUpload } from "react-icons/tb";
import { HiXMark } from "react-icons/hi2";
import useBusinessShippingCost from "../lib/bulks-cost";
import "./Business_bulk_upload.css";

export default function BusinessBulkUpload() {
  const { user } = useContext(AuthContext);
  const userId = user?.userId;
  const navigate = useNavigate();
  const { mutation } = useBusinessShippingCost();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [parsedData, setParsedData] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (
      selectedFile &&
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const fileSizeInKB = (selectedFile.size / 1024).toFixed(2);

      setFile(selectedFile);
      setError(null);
      setSuccessMsg(`File uploaded successfully! (${fileSizeInKB} KB)`);
      setFileUploaded(true);

      readExcelFile(selectedFile);
    } else {
      setError("Invalid file format. Please upload an Excel (.xlsx) file.");
      setFile(null);
      setSuccessMsg(null);
      setFileUploaded(false);
    }

    e.target.value = "";
  };

  // Read and process Excel file
  const readExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const data = XLSX.utils.sheet_to_json(sheet);
      console.log("📂 Extracted Data from Excel:", data);

      const formattedData = {
        creator: userId, // Use authenticated user ID as the creator
        packages: [],
      };

      data.forEach((row) => {
        // Format phone numbers (assuming Nigerian numbers without country code)
        const formatPhoneNumber = (phone) => {
          if (!phone) return "";
          const phoneStr = String(phone).trim();
          return phoneStr.startsWith("+") ? phoneStr : `+234${phoneStr}`;
        };

        // Format contact names (capitalize first letter of each word)
        const formatContactName = (name) => {
          if (!name) return "";
          return name
            .trim()
            .split(" ")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        };

        const packageData = {
          creator: userId,
          items: [
            {
              name: row["Item Name"]?.trim() || "Unknown Item",
              weight: parseFloat(row["Item Weight (kg)"]) || 1,
              quantity: parseInt(row["Item Quantity"], 10) || 1,
              items_value:
                parseFloat(
                  String(row["Item Value ($)"]).replace(/[^0-9.-]+/g, "")
                ) || 1,
            },
          ],
          sender: {
            name: formatContactName(row["Sender Name"] || "Unknown"),
            email: row["Sender Email"] || "",
            contact_name: formatContactName(row["Sender Contact"] || ""),
            phone_number: formatPhoneNumber(row["Sender Phone"] || ""),
            address_1: row["Sender Address 1"] || "",
            address_2: row["Sender Address 2"] || "",
            city: row["Sender City"] || "",
            zip_code: row["Sender Zip Code"] || "",
            state: row["Sender State"] || "",
          },
          receiver: {
            name: formatContactName(row["Receiver Name"] || "Unknown"),
            email: row["Receiver Email"] || "",
            contact_name: formatContactName(row["Receiver Contact"] || ""),
            phone_number: formatPhoneNumber(row["Receiver Phone"] || ""),
            address_1: row["Receiver Address 1"] || "",
            address_2: row["Receiver Address 2"] || "",
            city: row["Receiver City"] || "",
            zip_code: row["Receiver Zip Code"] || "",
            state: row["Receiver State"] || "",
          },
        };

        formattedData.packages.push(packageData);
      });

      console.log("✅ Formatted Data:", formattedData);

      // Store formatted data in localStorage
      localStorage.setItem("bulkShippingData", JSON.stringify(formattedData));

      // Update state
      setParsedData(formattedData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleCancel = () => {
    setFile(null);
    setError(null);
    setSuccessMsg(null);
    setFileUploaded(false);
    setParsedData(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please upload an Excel file.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMsg(null);

    try {
      // Retrieve formatted data from localStorage
      let storedData = JSON.parse(localStorage.getItem("bulkShippingData"));

      if (
        !storedData ||
        !storedData.packages ||
        storedData.packages.length === 0
      ) {
        throw new Error("No packages found in uploaded file.");
      }

      // ✅ Structure the shipment data properly
      const shipments = storedData.packages.map((packageData) => ({
        sender_state: packageData.sender.state,
        receiver_state: packageData.receiver.state,
        items: packageData.items.map((item) => ({
          weight: item.weight,
          quantity: item.quantity,
          items_value: item.items_value,
        })),
        isEmergency: false,
        isPickupRequired: false,
      }));

      const response = await mutation.mutateAsync({ shipments });

      // 🔹 **Ensure the response is valid**
      if (
        !response ||
        !Array.isArray(response) ||
        response.length !== storedData.packages.length
      ) {
        throw new Error("Invalid response from shipping API.");
      }

      // ✅ Update stored packages with shipping details
      storedData.packages = storedData.packages.map((packageData, index) => ({
        ...packageData,
        shipping_cost: response[index].amountToPay,
        original_shipping_cost: response[index].originalShippingCost,
        total_discount: response[index].totalDiscount,
        discount_percentage: (
          (parseFloat(response[index].totalDiscount) /
            parseFloat(response[index].originalShippingCost)) *
          100
        ).toFixed(2),
        estimated_delivery_date: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0], // 3 days ahead
        payment_method: "wallet",
        delivery_method: "Home",
      }));

      // 🔹 Store the updated data back in localStorage
      localStorage.setItem("bulkShippingData", JSON.stringify(storedData));
      console.log(storedData);
      setSuccessMsg("Shipping cost calculated and updated successfully!");
      navigate("/business_bulk_packages_creation");
    } catch (err) {
      console.error("❌ Error:", err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="business-bulk-upload">
      <div className="header">
        <FaArrowLeft onClick={() => navigate("/businesspackageshipment")} />
        <p>Packages Bulk Upload</p>
      </div>
      <div className="wrapper">
        <span>
          <Upload />
        </span>
        <div className="file">
          <h3>You are bulk shipping your orders</h3>
          <p>Upload your orders</p>
          <label className="upload-card">
            <TbCloudUpload style={{ fontSize: "20px" }} />
            <p>Click to upload your Excel file</p>
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              hidden
            />
          </label>
          {file && (
            <div className="info">
              <span>
                <p>{file.name}</p>
                <button onClick={handleCancel}>
                  <HiXMark />
                </button>
              </span>
              <div className="uploading-range"></div>
              <div className="success">
                <h4>{(file.size / 1024).toFixed(2)} KB</h4>
              </div>
            </div>
          )}
          {successMsg && (
            <p
              style={{
                color: fileUploaded ? "green" : "#000",
                fontFamily: "Montserrat",
                fontSize: "13px",
              }}
            >
              {successMsg}
            </p>
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}

          <button
            onClick={handleSubmit}
            disabled={loading}
            style={{
              backgroundColor: file ? "#284697" : "#ccc",
              color: "white",
              cursor: file ? "pointer" : "not-allowed",
              transition: "background-color 0.3s ease",
            }}
          >
            {loading ? "Processing..." : "Submit Orders"}
          </button>
        </div>
      </div>
    </div>
  );
}
