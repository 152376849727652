import React, { useEffect, useState } from "react";
import useVerifyPayment from "../lib/useVerifyPayment";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaExclamationTriangle, FaSpinner } from "react-icons/fa";
import "./payment-verification.css";

export default function PaymentVerification() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const { mutateAsync: verifyPayment, isLoading } = useVerifyPayment();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyPaymentStatus = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const transaction_id = urlParams.get("transaction_id");

      if (!transaction_id) {
        setError("Invalid payment details. Please try again.");
        return;
      }

      try {
        const response = await verifyPayment(transaction_id);

        if (response.message === "Payment successful, wallet funded") {
          setSuccess(true);

          // Start countdown timer
          const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown === 1) {
                clearInterval(timer); 
                navigate("/businesswallet");
              }
              return prevCountdown - 1;
            });
          }, 1000);

          setTimeout(() => {
            navigate("/businesswallet");
          }, 10000);
        } else {
          setError(response.message || "Payment verification failed.");
        }
      } catch (err) {
        setError("Error while verifying payment. Please try again.");
      }
    };

    verifyPaymentStatus();
  }, [verifyPayment, navigate]);

  return (
    <div className="payment-verification-container">
      {/* User Notice */}
      <div className="notice">
        <p>⚠️ Please do not close this tab until verification is complete.</p>
      </div>

      <div className="verification-card">
        {isLoading ? (
          <div className="loading-state">
            <FaSpinner className="spinner" />
            <h2>Verifying your payment...</h2>
            <p>Please wait while we check the status of your transaction.</p>
          </div>
        ) : success ? (
          <div className="success-message">
            <FaCheckCircle className="success-icon" />
            <h2>Payment Verified!</h2>
            <p>Your payment was successfully processed. Redirecting in {countdown} seconds...</p>
            <button onClick={() => navigate("/businesswallet")}>
              Go to Transactions
            </button>
            <p>
              If the redirection doesn't happen after {countdown} seconds, click the button.
            </p>
          </div>
        ) : (
          <div className="error-message">
            <FaExclamationTriangle className="error-icon" />
            <h2>Oops! Payment Failed</h2>
            <p>{error}</p>
            <button onClick={() => window.location.reload()}>Retry</button>
          </div>
        )}
      </div>
    </div>
  );
}

