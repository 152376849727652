import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg"
import "./Business_type.css";

export default function Businessinternationalshipment() {
  
  // const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="business-type">
      <div className="header">
        <h3>International Shipment</h3>
        <form>
          <SearchIcon style={{fontSize:"24px"}} />
          <input type="text" placeholder="Search package" />
        </form>
      </div>
      <div className="wrapper">
        <h3>COMING SOON...</h3>

        {/* <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Receiver</th>
              <th>Receiver </th>
              <th>Category</th>
              <th>Shipping Cost</th>
              <th>Date</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TID1234556789</td>
              <td>bruce jane</td>
              <td>112 awolowo way ikeja lagos</td>
              <td>Document</td>
              <td>N 3,660</td>
              <td>1 April, 2024</td>
              <td>Delivered</td>
              <td><button onClick={() => navigate('/')}>View</button></td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
}
