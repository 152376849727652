import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";

const fetchPackageDetails = async (id, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/packages/getpackage/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch package details");
  }

  return response.json();
};

const useBusinessPackageDetails = () => {
  const { token } = useContext(AuthContext);

  if (!token) {
    throw new Error("Token is not available. Make sure it's properly set up in AuthContext.");
  }

  const { id } = useParams();

  return useQuery(["packageDetails", id], () =>
    fetchPackageDetails(id, token)
  );
};

export default useBusinessPackageDetails;
