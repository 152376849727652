import React, { useState, useRef, useCallback } from 'react';
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import usePackagesByCreator from "../libs/usePackagesByCreator";
import { Link } from 'react-router-dom';
import Spinner from "../../utils/spinner";
import "./Logisticspackages.css";

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

function Logisticspackages() {
  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage, error } = usePackagesByCreator();

  const packages = data 
  ? data.pages.flatMap(page => page.packages).sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
  : [];

  const [searchQuery, setSearchQuery] = useState('');

  const filteredPackages = packages.filter(pkg =>
    pkg?.tracking_number?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    node => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      }, { threshold: 1.0 });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="logistic-packages">
      <div className="header">
        <p>Packages</p>
        <span>
          <SearchIcon />
          <input
            type="text"
            placeholder="Search by Tracking"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>
      <div className="packages-table">
        {isLoading && <Spinner />}
        {isError && <p>{error.message}</p>}
        {filteredPackages.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Package ID</th>
                <th>Sender’s Name</th>
                <th>Receiver’s Name</th>
                <th>Item/Weight</th>
                <th>Qty</th>
                <th>Item Value</th>
                <th>Shipping Cost</th>
                <th>Estimated Delivery Date</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
  {filteredPackages.map((pkg, index) => {
    const senderName = pkg?.sender?.name || "N/A";
    const receiverName = pkg?.receiver?.name || "N/A";

    if (filteredPackages.length === index + 1) {
      return (
        <tr key={pkg?._id} ref={lastProductElementRef}>
          <td>{pkg?.tracking_number || "N/A"}</td>
          <td>{senderName}</td>
          <td>{receiverName}</td>
          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>
                {item?.name} - {item?.weight}kg
              </div>
            )) || "N/A"}
          </td>
          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>{item?.quantity}</div>
            )) || 0}
          </td>
          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>
                {item?.items_value?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                }) || "N/A"}
              </div>
            )) || "N/A"}
          </td>
          <td>
  {pkg?.shipping_cost
    ? pkg.shipping_cost.toLocaleString("en-NG", {
        style: "currency",
        currency: "NGN",
      })
    : "N/A"}
</td>

          <td>{pkg?.estimated_delivery_date ? formatDate(pkg?.estimated_delivery_date) : "N/A"}</td>
          <td><Link to={`/details/${pkg?._id}`}>View Details</Link></td>
        </tr>
      );
    } else {
      return (
        <tr key={pkg?._id}>
          <td>{pkg?.tracking_number || "N/A"}</td>
          <td>{senderName}</td>
          <td>{receiverName}</td>
          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>
                {item?.name} / {item?.weight}kg
              </div>
            )) || "N/A"}
          </td>
          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>{item?.quantity}</div>
            )) || 0}
          </td>


          <td>
            {pkg?.items?.map((item) => (
              <div key={item?._id}>
                {item?.items_value?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "NGN",
                }) || "N/A"}
              </div>
            )) || "N/A"}
          </td>
                    <td>
  {pkg?.shipping_cost
    ? pkg.shipping_cost.toLocaleString("en-NG", {
        style: "currency",
        currency: "NGN",
      })
    : "N/A"}
</td>
          <td>{pkg?.estimated_delivery_date ? formatDate(pkg?.estimated_delivery_date) : "N/A"}</td>
          <td><Link to={`/details/${pkg?._id}`}>View Details</Link></td>
        </tr>
      );
    }
  })}
</tbody>

          </table>
        ) : (
          !isLoading && <p style={{ fontFamily: 'Montserrat' }}>No packages found.</p>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Logisticspackages;



