import React, { useState, useRef, useCallback, useContext } from 'react';
import { FaXmark } from "react-icons/fa6";
import { AuthContext } from "../../Context/AuthContext";
import Skeleton from "react-loading-skeleton"; 
import 'react-loading-skeleton/dist/skeleton.css'; 
import useInitiatePayment from "../lib/useInitiatePayment";
import useWalletFundingHistory from "../lib/useWalletFundingHistory";
import useWalletBalance from "../lib/useWalletBalance"; 
import Spinner from "../../utils/spinner";
import "./Business_wallet.css";

export default function Businesswallet() {
  const { user } = useContext(AuthContext);
  const userId = user?.userId;
  const [fundWalletOverlay, setFundWalletOverlay] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false); 

  const { mutate: initiatePayment, isLoading } = useInitiatePayment();
  const { data: walletBalance, isLoading: loadingBalance } = useWalletBalance(userId);
  const { 
    data: fundingData, 
    isLoading: loadingHistory, 
    isFetchingNextPage, 
    fetchNextPage, 
    hasNextPage 
  } = useWalletFundingHistory(userId);
  
  const observerRef = useRef();
  const lastFundingElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();
  
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
  
      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );
  

  const handleAddMoney = async (e) => {
    e.preventDefault();
    setError("");

    if (!amount || amount <= 0) {
      setError("Please enter a valid amount.");
      return;
    }

    const paymentData = {
      userId,
      amount,
      payment_method: "card",
    };

    initiatePayment(paymentData, {
      onSuccess: (data) => {
        setIsRedirecting(true); 
        window.location.href = data.link; 
      },
      onError: (error) => {
        setError("Payment initiation failed, please try again.");
      },
    });
  };

  return (
    <>
      {fundWalletOverlay && (
        <div className="fund-wallet-overlay">
          <div className="card">
            <span>
              <h3>Add money</h3>
              <button onClick={() => setFundWalletOverlay(false)}>
                <FaXmark />
              </button>
            </span>
            <form onSubmit={handleAddMoney}>
              <span>
                <label>Amount (Naira)</label>
                <input
                  type="number"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                 
                  disabled={isLoading || isRedirecting} 
                />
              </span>
            {error && <p style={{ color: "red", fontFamily:"Montserrat" }}>{error}</p>}
              <button type="submit" disabled={isLoading || isRedirecting}>
                {isLoading || isRedirecting ? "Processing..." : "Add Money"}
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="business-wallet-container">
        <div className="header">
          <p>Your wallet balance</p>
          <span>
            <h3>
              {loadingBalance ? <Skeleton width={120} height={30} /> : `NGN ${walletBalance?.toLocaleString()}`}
            </h3>
            <button onClick={() => setFundWalletOverlay(true)}>Add Money</button>
          </span>
        </div>

        <h3>Transaction History</h3>

        <div className="business-history-table">
          <table>
            <thead>
              <tr>
                <th>Summary</th>
                <th>Date</th>
                <th>Time</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Transaction ID</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
  {loadingHistory ? (
    Array.from({ length: 5 }).map((_, index) => (
      <tr key={index}>
        <td><Skeleton width={150} /></td>
        <td><Skeleton width={100} /></td>
        <td><Skeleton width={80} /></td>
        <td><Skeleton width={100} /></td>
        <td><Skeleton width={100} /></td>
        <td><Skeleton width={120} /></td>
        <td><Skeleton width={80} /></td>
      </tr>
    ))
  ) : fundingData?.pages?.length ? (
    fundingData.pages.map((page) =>
      page.fundingHistory.map((history, index) => {
        const date = new Date(history.date);
        return (
          <tr key={history._id} ref={index === page.fundingHistory.length - 1 ? lastFundingElementRef : null}>
            <td>{history.description}</td>
            <td>{date.toLocaleDateString()}</td>
            <td>{date.toLocaleTimeString()}</td>
            <td>NGN {history.amount.toLocaleString()}</td>
            <td>{history.status}</td>
            <td>{history.transactionId}</td>
            <td>{history.type}</td>
          </tr>
        );
      })
    )
  ) : (
    <tr>
      <td colSpan="7" style={{ textAlign: "center" }}>No funding history</td>
    </tr>
  )}
</tbody>
          </table>
        </div>
        {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
      </div>
    </>
  );
}
