import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Spinner/Spinner';
import { useLocation } from 'react-router-dom';
import "./Token.css";

const EmailVerificationForm = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const emailFromParams = searchParams.get('email');

    const [email] = useState(emailFromParams || localStorage.getItem('email'));
    const [digits, setDigits] = useState(['', '', '', '']);
    const inputRefs = useRef([...Array(4)].map(() => React.createRef()));
    const [successMsg, setSuccessMsg] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (emailFromParams) {
            localStorage.setItem('email', emailFromParams);
        }
    }, [emailFromParams]);

    const handleInputChange = (index, value) => {
        const newDigits = [...digits];
        newDigits[index] = value;
        setDigits(newDigits);

        if (value && index < 3) {
            inputRefs.current[index + 1].current.focus();
        } else if (!value && index > 0) {
            inputRefs.current[index - 1].current.focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === "Backspace") {
            if (!digits[index] && index > 0) {
                inputRefs.current[index - 1].current.focus();
            }
            const newDigits = [...digits];
            newDigits[index] = '';
            setDigits(newDigits);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').trim();
        
        // Check if the pasted data is a valid 4-digit code
        if (/^\d{4}$/.test(pastedData)) {
            const newDigits = pastedData.split('');
            setDigits(newDigits);

            // Focus on the last input after paste
            setTimeout(() => {
                inputRefs.current[3].current.focus();
            }, 0);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setError('Invalid email format.');
            setIsLoading(false);
            return;
        }

        const activationCode = digits.join('');

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/activate/account`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, activationCode }),
            });

            const resData = await response.json();
            if (!response.ok) throw new Error(resData.message);

            setIsLoading(false);
            setSuccessMsg("Account activated successfully. You can now log in.");
            setTimeout(() => {
                setSuccessMsg(null);
                navigate("/Login");
            }, 3000);
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    const handleResendSubmit = async (e) => {
        e.preventDefault();
        setResendLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/resend/activation-code`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            const resData = await response.json();
            if (!response.ok) throw new Error(resData.message);

            setResendLoading(false);
            setSuccessMsg("Activation code sent successfully. You can check your registered email.");
            setTimeout(() => setSuccessMsg(null), 3000);
        } catch (error) {
            setError(error.message);
            setResendLoading(false);
        }
    };

    return (
        <div className="token-card">
            <h3>Verify Email Address</h3>
            <p>Enter 4-digit code sent to your Email</p>
            <form onSubmit={handleSubmit}>
                <div className="token-inputs" onPaste={handlePaste}>
                    {digits.map((digit, index) => (
                        <span key={index}>
                            <input
                                ref={inputRefs.current[index]}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                            />
                        </span>
                    ))}
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? <Spinner /> : "Continue"}
                </button>
                <div className="resend-btn">
                    <button onClick={handleResendSubmit} disabled={resendLoading}>
                        <p>Didn't get the code? in 30 secs</p>
                        {resendLoading ? "Resending..." : "Resend"}
                    </button>
                </div>
                {successMsg && <p style={{ color: "green", fontFamily: "Montserrat" }}>{successMsg}</p>}
                {error && <p style={{ color: 'red', fontFamily: 'Montserrat' }}>{error}</p>}
            </form>
        </div>
    );
}

export default EmailVerificationForm;
