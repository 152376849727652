import React, { useState, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FrameIcon } from "../../asset/Frame 1003.svg";
import useBusinessSenderFormData from "../lib/useBusinessSender";
import useBusinessReceiverFormData from "../lib/useBusinessReceiver";
import useBusinessSummaryData from "../lib/useBusinessSummary";
import useBusinessReceiptDetails from "../lib/getBusinessPackageReceipt";
import useBusinessPackageCreate from "../lib/createBusinessPackage";
import { BsFillBoxSeamFill } from "react-icons/bs";
import useWalletBalance from "../lib/useWalletBalance";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Businesssummarydetail() {
  const { user } = useContext(AuthContext);
  const userId = user?.userId;
  const navigate = useNavigate();
  const [formData] = useBusinessSenderFormData("businessSenderForm");
  const [receiverFormData] = useBusinessReceiverFormData("receiverFormData");
  const [summaryformData] = useBusinessSummaryData("summaryData");
  const { createPackage, mutation } = useBusinessPackageCreate();
  const { fetchReceiptDetails } = useBusinessReceiptDetails();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");
  const { data: walletBalance, isLoading } = useWalletBalance(userId);

  const itemsData = JSON.parse(localStorage.getItem("itemsData")) || [];
  const storedSummary = localStorage.getItem("shippingSummaryData");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const storedSenderRaw =
        JSON.parse(localStorage.getItem("businessSenderForm")) || {};
      const storedReceiverRaw =
        JSON.parse(localStorage.getItem("receiverFormData")) || {};

      // Convert sender and receiver fields
      const storedSender = {
        ...storedSenderRaw,
        state: storedSenderRaw.senderState,
        city: storedSenderRaw.senderCity,
      };
      delete storedSender.senderState;
      delete storedSender.senderCity;

      const storedReceiver = {
        ...storedReceiverRaw,
        state: storedReceiverRaw.receiverState,
        city: storedReceiverRaw.receiverCity,
      };
      delete storedReceiver.receiverState;
      delete storedReceiver.receiverCity;

      const parsedData = JSON.parse(storedSummary);
      const {
        totalShippingCost,
        originalShippingCost,
        totalDiscount,
        discountPercentage,
        isEmergency,
        isPickupRequired,
      } = parsedData;

      const items = itemsData.map(({ category, ...item }) => ({
        ...item,
        items_value:
          parseFloat(item.items_value.replace(/[^0-9.-]+/g, "")) || 0, // Convert items_value to number
        weight: parseFloat(item.weight) || 0, // Convert weight to number
        quantity: parseInt(item.quantity, 10) || 1, // Convert quantity to number
      }));

      if (!storedSender || !storedReceiver || !storedSummary) {
        throw new Error("Missing required shipping data in local storage.");
      }

      // Construct package data
      const packageData = {
        creator: userId,
        estimated_delivery_date: summaryformData.estimated_delivery_date,
        delivery_method: summaryformData.delivery_method,
        payment_method: "wallet funded",
        shipping_cost: parseFloat(totalShippingCost),
        original_shipping_cost: parseFloat(originalShippingCost),
        total_discount: parseFloat(totalDiscount) || 0,
        discount_percentage: parseFloat(discountPercentage) || 0,
        isEmergency: isEmergency === "false",
        isPickupRequired: isPickupRequired === "false",
        sender: storedSender,
        receiver: storedReceiver,
        items: items,
      };

      setOverlayMessage("Creating package...");
      setIsOverlayVisible(true);

      // Send data to server
      const createdPackage = await createPackage(packageData);
      const packageId = createdPackage?.package?._id;

      if (packageId) {
        setOverlayMessage(
          "Package created successfully. Downloading receipt..."
        );
        const pdfUrl = await fetchReceiptDetails(packageId);

        if (pdfUrl) {
          const link = document.createElement("a");
          link.href = pdfUrl;
          link.download = `package_receipt_${packageId}.pdf`;
          link.click();
        }

        setOverlayMessage("Receipt downloaded successfully!");
        localStorage.clear();

        setTimeout(() => {
          setIsOverlayVisible(false);
          navigate("/businessshipment");
        }, 3000);
      } else {
        throw new Error("Failed to retrieve package ID from response.");
      }
    } catch (error) {
      console.error("Error creating package:", error);

      // Extract error message properly
      let errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Error creating package. Please try again.";

      setOverlayMessage(errorMessage);
      setIsOverlayVisible(true);
    }
  };

  const storedSummaryRaw = localStorage.getItem("shippingSummaryData");
  const storedSummarys = storedSummaryRaw ? JSON.parse(storedSummaryRaw) : {};

  const formatToNGN = (amount) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <>
      {isOverlayVisible && (
        <div className="insufficient-overlay">
          <div className="message-card">{overlayMessage}</div>
          {overlayMessage ===
            "Insufficient balance to cover the shipping cost." && (
            <div className="wallet-balance">
              <span>
                <p>
                  Wallet Balance:{" "}
                  {isLoading ? (
                    <Skeleton width={80} height={20} />
                  ) : (
                    `NGN ${walletBalance?.toLocaleString()}`
                  )}
                </p>{" "}
                <p>
                  shipping cost:{" "}
                  {formatToNGN(storedSummarys.totalShippingCost || 0)}
                </p>
              </span>
              <div className="btn">
                <button onClick={() => navigate("/businesswallet")}>
                  Fund Wallet
                </button>
                <button onClick={() => setIsOverlayVisible(false)}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="business-summary-detail">
        <h3>Create Package</h3>
        <div className="summary-details-combination">
          <div className="card-1">
            <FrameIcon />
            <div className="shipping-details">
              <span>
                <p>Sender name:</p>
                <p>{formData.name}</p>
              </span>
              <span>
                <p>Address 1:</p>
                <p>{formData.address_1}</p>
              </span>
              <span>
                <p>Address 2:</p>
                <p>{formData.address_2}</p>
              </span>
              <span>
                <p>Zip Code:</p>
                <p>{formData.zip_code}</p>
              </span>
              <span>
                <p>Email:</p>
                <p>{formData.email}</p>
              </span>
              <span>
                <p>Phone:</p>
                <p>{formData.phone_number}</p>
              </span>
              <span>
                <p>State:</p>
                <p>{formData.sender_state}</p>
              </span>
            </div>
            <div className="shipping-details">
              <span>
                <p>Receiver name:</p>
                <p>{receiverFormData.name}</p>
              </span>
              <span>
                <p>Address 1:</p>
                <p>{receiverFormData.address_1}</p>
              </span>
              <span>
                <p>Address 2:</p>
                <p>{receiverFormData.address_2}</p>
              </span>
              <span>
                <p>Zip Code:</p>
                <p>{receiverFormData.zip_code}</p>
              </span>
              <span>
                <p>Email:</p>
                <p>{receiverFormData.email}</p>
              </span>
              <span>
                <p>Phone:</p>
                <p>{receiverFormData.phone_number}</p>
              </span>
              <span>
                <p>State:</p>
                <p>{receiverFormData.receiver_state}</p>
              </span>
            </div>
          </div>
          <div className="card-2">
            <div className="wrapper">
              <h3>My Order</h3>
              <BsFillBoxSeamFill className="order-icon" />
            </div>
            <span>
              <p>Total Weight:</p>
              <p>{storedSummarys.totalWeight || 0} kg</p>
            </span>
            <span>
              <p>Total Quantity:</p>
              <p>{storedSummarys.totalQuantity || 0}</p>
            </span>
            <span>
              <p>Total Item Value:</p>
              <p>{formatToNGN(storedSummarys.totalItemsValue || 0)}</p>
            </span>
            <span>
              <p>Original Shipping Cost:</p>
              <p>{formatToNGN(storedSummarys.originalShippingCost || 0)}</p>
            </span>
            <span>
              <p>Total Discount:</p>
              <p>{formatToNGN(storedSummarys.totalDiscount || 0)}</p>
            </span>
            <span>
              <p>Total Shipping Cost:</p>
              <h3>{formatToNGN(storedSummarys.totalShippingCost || 0)}</h3>
            </span>

            {mutation.errorMsg && (
              <p style={{ color: "red" }}>{mutation.errorMsg}</p>
            )}

            <div className="btn">
              <button onClick={() => navigate("/businesspackagecategory")}>
                Back
              </button>
              <button
                type="submit"
                disabled={mutation.isLoading}
                onClick={handleSubmit}
              >
                {mutation.isLoading ? "Creating..." : "Create "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
