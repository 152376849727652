import React, { useRef, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMyConcerns from "../lib/useMyConcerns";
import "./my-concerns.css";

const MyConcerns = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useMyConcerns(searchTerm);
  const [selectedConcern, setSelectedConcern] = useState(null);

  const navigate = useNavigate();

  // Observer for infinite scroll
  const observer = useRef();
  const lastConcernRef = useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, isFetchingNextPage, hasNextPage, fetchNextPage]
  );

  return (
    <div className="my-concerns-container">
      <div className="header">
        <h2>My Concerns</h2>
        <div className="wrapper">
          <input
            type="search"
            placeholder="Search by Ticket ID or Tracking ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <button onClick={() => navigate("/raise-concerns")}>
            Raise Concerns
          </button>
        </div>
      </div>

      <div className="list-of-concerns">
        <table>
          <thead>
            <tr>
              <th>Ticket Code</th>
              <th>Tracking ID</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>View Response</th>
            </tr>
          </thead>
          <tbody>
            {data?.pages.map((page, pageIndex) =>
              page.concerns.map((concern, index) => {
                const isLastItem =
                  pageIndex === data.pages.length - 1 &&
                  index === page.concerns.length - 1;
                return (
                  <tr
                    key={concern._id}
                    ref={isLastItem ? lastConcernRef : null}
                  >
                    <td>{concern.ticketCode}</td>
                    <td>{concern.trackingID}</td>
                    <td>{concern.subject}</td>
                    <td>{concern.message.split(" ").slice(0, 3).join(" ")}</td>
                    <td>
                      <span
                        className={`status ${concern.status.toLowerCase()}`}
                      >
                        {concern.status}
                      </span>
                    </td>
                    <td>{new Date(concern.createdAt).toLocaleString()}</td>
                    <td>{new Date(concern.updatedAt).toLocaleString()}</td>
                    <td>
                      <button
                        className="view-response-btn"
                        onClick={() =>
                          setSelectedConcern({
                            message: concern.message,
                            response: concern.response || "No response yet",
                          })
                        }
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>

        {isLoading && <p className="loading">⏳ Loading concerns...</p>}
        {error && <p className="error">❌ {error.message}</p>}
        {!isLoading && !error && data?.pages[0].concerns.length === 0 && (
          <p className="no-concerns">🤷‍♂️ No concerns found.</p>
        )}
      </div>

      {isFetchingNextPage && (
        <p className="loading">⏳ Loading more concerns...</p>
      )}

      {selectedConcern !== null && (
        <div
          className="response-overlay"
          onClick={() => setSelectedConcern(null)}
        >
          <div className="response-box" onClick={(e) => e.stopPropagation()}>
            <span>
              <h3>Message:</h3>
              <p>{selectedConcern?.message}</p>
            </span>
            <span>
              <h4>Response:</h4>
              <p>{selectedConcern?.response}</p>
            </span>
            <button
              className="close-btn"
              onClick={() => setSelectedConcern(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyConcerns;
