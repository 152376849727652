import { useMutation } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

const verifyPayment = async (transaction_id, token) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/payments/verify/${transaction_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const useVerifyPayment = () => {
  const { token } = useContext(AuthContext);

  return useMutation(
    (transaction_id) => verifyPayment(transaction_id, token), 
    {
      onError: (error) => {
        console.error("Payment verification failed:", error);
      },
    }
  );
};

export default useVerifyPayment;

