import { useState, useEffect } from 'react';

const useBusinessSenderFormData = (key) => {
  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : {};
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(formData));
  }, [formData, key]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return [formData, handleChange];
};

export default useBusinessSenderFormData;