import { useQueryClient, useMutation } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";
import { useContext } from "react";

const useBusinessShippingCost = () => {
  const { token } = useContext(AuthContext); 
  const queryClient = useQueryClient();

  const fetchShippingCost = async (payload) => {
    try {
      console.log("🚀 Sending Payload to API:", payload);

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/shipping/get-bulk-cost`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, 
            "Content-Type": "application/json",
          },
        }
      );

      console.log("✅ API Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("❌ API Error:", error.response?.data || error.message);
      throw new Error(error.response?.data?.message || "Error fetching shipping cost");
    }
  };

  const mutation = useMutation(fetchShippingCost, {
    onSuccess: (data) => {
      console.log("🎯 Shipping Cost Data:", data);
      queryClient.invalidateQueries("shippingCost");
    },
    onError: (error) => {
      console.error("⚠️ Mutation Error:", error.message);
    },
  });

  return { mutation };
};

export default useBusinessShippingCost;
