import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LiaEyeSlashSolid, LiaEyeSolid } from "react-icons/lia";
import "./Business_Form.css";

const businessDefaultForm = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone_number: "",
  country_code: "+234",
};

export default function Businessform() {
  const [businessFormInput, setBusinessFormInput] =
    useState(businessDefaultForm);
  const { first_name, last_name, email, password, phone_number } =
    businessFormInput;
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleShowPass = () => setShowPass(!showPass);

  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    setBusinessFormInput({ ...businessFormInput, [name]: value });
    setError(null);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const validateBusinessForm = () => {
    if (!first_name || !last_name || !email || !password) {
      setError("All fields are required.");
      return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError("Invalid email format.");
      return false;
    }

    if (phone_number.length < 10) {
      setError("Invalid phone number.");
      return false;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters.");
      return false;
    }

    return true;
  };

  const handleBusinessSubmit = (e) => {
    e.preventDefault();

    if (!validateBusinessForm()) {
      return;
    }

    sessionStorage.setItem(
      "businessDetails",
      JSON.stringify(businessFormInput)
    );
    navigate("/businessdetails");
  };

  return (
    <div className="business-sign-up">
      <h3>Create Business Account</h3>
      <form onSubmit={handleBusinessSubmit}>
        <div className="input-combination-1">
          <span>
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              placeholder=" Enter First Name"
              value={first_name}
              onChange={handleBusinessChange}
            />
          </span>
          <span>
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              placeholder="Enter Last Name"
              value={last_name}
              onChange={handleBusinessChange}
            />
          </span>
        </div>
        <div className="input-combination-1">
          <span>
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Enter Email"
              value={email}
              onChange={handleBusinessChange}
            />
          </span>
          <span>
            <label>Phone Number</label>
            <div className="input-combination-2">
              <div className="zip-code">+234</div>
              <input
                type="tel"
                name="phone_number"
                placeholder="Enter Phone Number"
                value={phone_number}
                onChange={handleBusinessChange}
                required
              />
            </div>
          </span>
        </div>
        <div className="input-combination-1">
          <span>
            <label>Password</label>
            <div className="pass">
              <input
                type={showPass ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={password}
                onChange={handleBusinessChange}
              />
              <div onClick={handleShowPass}>
                {showPass ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
              </div>
            </div>
          </span>
        </div>

        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        <button type="submit">Proceed</button>
        <p onClick={() => navigate("/LogIn")}>
          Already have an account? <span>Log in</span>
        </p>
      </form>
    </div>
  );
}
