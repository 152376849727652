import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { CiEdit } from "react-icons/ci";
import { GoPlusCircle } from "react-icons/go";
import useDeactivateAccount from "../libs/deactivateAccount";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import useUpdateUserById from "../libs/useUpdateUserById";
import Spinner from "../../utils/spinner";
import useFetchBusinessUsers from "../libs/fetchBusinessUsers";
import "./Super_admin_business_user.css";

export default function Superadminbusinessusers() {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFetchBusinessUsers();

  const [searchTerm, setSearchTerm] = useState("");
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [unblockOverlay, setUnblockedOverlay] = useState(false);
  const [deleteOverlay, setDeleteOerlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const blockUser = useBlockUserById();
  const unblockUser = useUnblockUserById();
  const deactivateAccountMutation = useDeactivateAccount();
  const updateUserHook = useUpdateUserById();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    business_address: "",
    company_name: "",
    phone_number: "",
    business_email: "",
    business_phone_number: "",
    state: "",
  });

  const businessUsers = data?.pages.flatMap((page) => page.users) || [];

  const selectedUser = businessUsers.find(
    (user) => user?._id === selectedUserId
  );

  const filteredUsers = businessUsers.filter(
    (user) =>
      user?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user?.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user?.phone_number?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const observerRef = useRef();
  const lastUserElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditClick = () => {
    if (selectedUser) {
      setFormData({
        first_name: selectedUser.first_name || "",
        last_name: selectedUser.last_name || "",
        email: selectedUser.email || "",
        business_address: selectedUser.business_address || "",
        company_name: selectedUser.company_name || "",
        phone_number: selectedUser.phone_number || "",
        business_email: selectedUser.business_email || "",
        business_phone_number: selectedUser.business_phone_number || "",
        state: selectedUser.state || "",
      });
      setEditOverlay(true);
    }
  };

  useEffect(() => {
    if (selectedUserId && selectedUser) {
      setFormData({
        first_name: selectedUser.first_name || "",
        last_name: selectedUser.last_name || "",
        email: selectedUser.email || "",
        business_address: selectedUser.business_address || "",
        company_name: selectedUser.company_name || "",
        phone_number: selectedUser.phone_number || "",
        business_email: selectedUser.business_email || "",
        business_phone_number: selectedUser.business_phone_number || "",
        state: selectedUser.state || "",
      });
    }
  }, [selectedUserId, selectedUser]);

  const handleCancelEdit = () => {
    setSelectedUserId(null);
    setEditOverlay(false);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (!selectedUserId) {
        return;
      }

      await updateUserHook.updateUserById(selectedUserId, {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        business_address: formData.business_address,
        company_name: formData.company_name,
        phone_number: formData.phone_number,
        business_email: formData.business_email,
        business_phone_number: formData.business_phone_number,
        state: formData.state,
      });

      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        business_address: "",
        company_name: "",
        phone_number: "",
        business_email: "",
        business_phone_number: "",
        state: "",
      });
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update user:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await blockUser.mutateAsync({
        userId: selectedUserId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleUnblockedUser = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await unblockUser.mutateAsync({ userId: selectedUserId });
      setUnblockedOverlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await deactivateAccountMutation.mutateAsync(selectedUserId);
      setDeleteOerlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  return (
    <Fragment>
      {editOverlay && (
        <div className="logistic-edits-over-container">
          <form className="card" onSubmit={handleUpdateUser}>
            <label>
              First Name
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Last Name
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Business Address
              <input
                type="text"
                name="business_address"
                value={formData.business_address}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Company Name
              <input
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Phone Number
              <input
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Business Email
              <input
                type="email"
                name="business_email"
                value={formData.business_email}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Business Phone Number
              <input
                type="text"
                name="business_phone_number"
                value={formData.business_phone_number}
                onChange={handleInputChange}
              />
            </label>
            <label>
              State
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </label>
            <div className="btn">
              <button type="button" onClick={handleCancelEdit}>
                Cancel
              </button>
              <button type="submit">
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}

      {deleteOverlay && (
        <div className="logistic-delete-over-container">
          <div className="delete-card">
            <CautionIcon />
            <span>Delete</span>
            <p>
              Are you sure you want to delete this account? This action cannot
              be undone?
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOerlay(false)}>Cancel</button>
              <button onClick={handleDeactivateAccount}>
                {deactivateAccountMutation.isLoading
                  ? "Deactivating..."
                  : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
      {unblockOverlay && (
        <div className="logistic-unblock-over-container">
          <div className="inner">
            <p>Are you sure you want to lift the suspension on this user?</p>
            <div className="btn">
              <button onClick={() => setUnblockedOverlay(false)}>Cancle</button>
              <button
                type="button"
                disabled={unblockUser.isLoading}
                onClick={handleUnblockedUser}
              >
                {" "}
                {unblockUser.isLoading ? "Unblocking..." : "Confirm"}
              </button>
            </div>
            {unblockUser.isError && (
              <p className="error-message">
                Error: {unblockUser.error.message}
              </p>
            )}
          </div>
        </div>
      )}
      {blockOverlay && (
        <div className="logistic-block-over-container">
          <form>
            <p>Are you sure you want to suspend this officer?</p>
            <label>
              Start Date
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </label>
            <label>
              End Date
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </label>
            <div className="btn">
              <button onClick={() => setBlockOverlay(false)}>Cancel</button>
              <button
                type="button"
                onClick={handleBlockUser}
                disabled={blockUser.isLoading}
              >
                {blockUser.isLoading ? "Blocking..." : "Confirm"}
              </button>
            </div>
            {blockUser.isError && (
              <p className="error-message">Error: {blockUser.error.message}</p>
            )}
          </form>
        </div>
      )}
      <div className="super_admin_logistic_container">
        <div className="header">
          <p>Business Users</p>
          <div className="in">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search by  name, email, number"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
          </div>
        </div>
        <div className="logistic-table">
          {isLoading && <Spinner />}
          {isError && (
            <p style={{ fontFamily: "Montserrat" }}>No business users found</p>
          )}
          {!isLoading && !isError && (
            <table>
              <thead>
                <tr>
                  <th>First</th>
                  <th>Last</th>
                  <th>Email</th>
                  <th>Company Name</th>
                  <th>Address</th>
                  <th>Business Email</th>
                  <th>Business Phone </th>
                  <th>Business Type</th>
                  <th>State</th>
                  <th>Blocked</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr
                    key={user._id}
                    ref={
                      index === filteredUsers.length - 1
                        ? lastUserElementRef
                        : null
                    }
                  >
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.company_name}</td>
                    <td>{user.business_address}</td>
                    <td>{user.business_email}</td>
                    <td>{user.business_phone_number}</td>
                    <td>{user.role}</td>
                    <td>{user.state}</td>
                    <td>{user.blocked ? "Yes" : "No"}</td>
                    <td>
                      <div className="action">
                        <CiEdit
                          onClick={() => {
                            setSelectedUserId(user._id);
                            handleEditClick();
                          }}
                        />
                        {user?.blocked ? (
                          <GoPlusCircle
                            onClick={() => {
                              setUnblockedOverlay(true);
                              setSelectedUserId(user._id);
                            }}
                          />
                        ) : (
                          <RemoveIcon
                            onClick={() => {
                              setBlockOverlay(true);
                              setSelectedUserId(user._id);
                            }}
                          />
                        )}
                        <TrashIcon
                          onClick={() => {
                            setDeleteOerlay(true);
                            setSelectedUserId(user._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
      </div>
    </Fragment>
  );
}
