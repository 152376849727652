import React from 'react';
import { ReactComponent as FrameIcon } from "../../asset/Frame 100.svg";
import useBusinessSenderFormData from '../lib/useBusinessSender';
import useBusinessReceiverFormData from '../lib/useBusinessReceiver';
import useBusinessStatesAndCities from '../../super_admin/libs/fetchAdminState';
import {Link, useNavigate } from "react-router-dom";
import { FaArrowsRotate } from "react-icons/fa6";

export default function Businessreceiverdetails() {
  const navigate = useNavigate();
  const [formData] = useBusinessSenderFormData("businessSenderForm");
  const [receiverFormData, handleChanges] = useBusinessReceiverFormData("receiverFormData")
  const { data: statesAndCities, isLoading: isFetchingStates } = useBusinessStatesAndCities();
  

  const getStateNameById = (stateId) => {
    const state = statesAndCities?.states.find(
      (state) => state._id === stateId
    );
    return state?.name || "";
  };

  const getCityNameById = (stateId, cityId) => {
    const city = getCitiesForState(stateId).find((city) => city._id === cityId);
    return city?.name || "";
  };

  const getCitiesForState = (stateId) => {
    const selectedState = statesAndCities?.states.find(
      (state) => state._id === stateId
    );
    return selectedState?.cities || [];
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    const receiverStateName = getStateNameById(receiverFormData.receiverState);
    const receiverCityName = getCityNameById(
      receiverFormData.receiverState,
      receiverFormData.receiverCity
    );

    const updatedFormData = {
      ...receiverFormData,
      receiverState: receiverStateName,  
      receiverCity: receiverCityName,    
    };

    localStorage.setItem("receiverFormData", JSON.stringify(updatedFormData));
    console.log("Receiver FormData:", updatedFormData);
    navigate("/businesspackagecategory", { state: updatedFormData });
  };



   return (
      <div className="business-receiver-container">
        <div className="header">
        <h3>National Delivery</h3>
        </div>
        <div className="receiver-shipping">
        <FrameIcon />
        
         <div className="wrapper">
         <p>Shipped From/Return to</p>
        <div className="business-package-info">
          <p> {formData.name}</p>
          <p> {formData.address_1}</p>
          <p> {formData.address_2}</p>
          <p>{formData.zip_code}</p>
          <p>{formData.email}</p>
          <p> {formData.phone_number}</p>
          <p> {formData.senderState}</p>
          <p> {formData.senderCity}</p>
        </div>
        <div className="return-box">
        <Link to="/businesssenderdetails">Change Pickup Address<FaArrowsRotate /></Link>
        </div>
         </div>
        <span>
          <p>Where is your shippment going?</p>
        </span>
        <form onSubmit={handleSubmit}>
          <div className="shipping-combination">
            <span>
              <label className="required-label">Full Name or Company Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter Name"
                value={receiverFormData.name || ""}
                onChange={handleChanges}
                required
              />
            </span>
  
            <span>
              <label>Contact Name</label>
              <input
                type="text"
                name="contact_name"
                placeholder="Enter Name"
                value={receiverFormData.contact_name || ""}
                onChange={handleChanges}
                required
              />
            </span>
          </div>
  
          <div className="shipping-combination-address">
            <span>
              <label className="required-label">Address 1</label>
              <input
                type="text"
                name="address_1"
                placeholder="Enter Address"
                value={receiverFormData.address_1 || ""}
                onChange={handleChanges}
                required
              />
            </span>
  
            <span>
              <label>Address 2</label>
              <input
                type="text"
                name="address_2"
                placeholder="Enter Address"
                value={receiverFormData.address_2 || ""}
                onChange={handleChanges}
                required
              />
            </span>
          </div>
  
          <div className="shipping-combination">
            <span>
              <label className="required-label"> Email Address</label>
              <input
                type="email"
                name="email"
                placeholder="Enter email"
                value={receiverFormData.email || ""}
                onChange={handleChanges}
                required
              />
            </span>
  
            <span>
              <label>Phone Number</label>
              <div className="input-wrapper">
                <div className="zip-code">
                  +234 <i className="fa-solid fa-caret-down"></i>
                </div>
                <input
                  type="tel"
                  name="phone_number"
                  value={receiverFormData.phone_number || ""}
                  onChange={handleChanges}
                  required
                />
              </div>
            </span>
          </div>
  
          <div className="shipping-combination">
            <span>
              <label className="required-label">State</label>
              <select
                name="receiverState"
                value={receiverFormData.receiverState}
                onChange={handleChanges}
                disabled={isFetchingStates}
              >
                <option value="">Select Destination State</option>
                {statesAndCities?.states.map((state) => (
                  <option key={state._id} value={state._id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label className="required-label">City</label>
              <select
                name="receiverCity"
                value={receiverFormData.receiverCity}
                onChange={handleChanges}
                disabled={!receiverFormData.receiverState}
              >
                <option value="">Select Destination City</option>
                {getCitiesForState(receiverFormData.receiverState).map(
                  (city) => (
                    <option key={city._id} value={city._id}>
                      {city.name}
                    </option>
                  )
                )}
              </select>
            </span>
          </div>
          <div className="shipping-combination">
            <span>
              <label className="required-label">Zip Code</label>
              <input
                type="tel"
                name="zip_code"
                placeholder="Enter zip code"
                value={receiverFormData.zip_code || ""}
                onChange={handleChanges}
                required
              />
            </span>
          </div>
          <button>Proceed</button>
        </form>
        </div>
      </div>
    );
}
