import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from "../../Context/AuthContext";

const fetchPackages = async ({ pageParam = 1 }, token, user) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/businesspkgs/user-packages/${user.userId}?page=${pageParam}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Error fetching packages');
  }

  return response.json();
};

const useBusinessPackagesByCreator = () => {
  const { token, user } = useContext(AuthContext);
  return useInfiniteQuery(
    ['packagesByCreator', user.userId], 
    ({ pageParam }) => fetchPackages({ pageParam }, token, user),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalPackages / 10) ? nextPage : undefined;
      },
    }
  );
};

export default useBusinessPackagesByCreator;
