import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const validateTrackingID = async (trackingID, token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/raise/validate-tracking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ trackingID }),
    });

    const data = await response.json();
    if (!response.ok) throw new Error(data.message);

    return { 
      valid: true, 
      message: data.message, 
      status: data.package.status[0]?.status || "Unknown Status", 
    };
  } catch (error) {
    return { valid: false, message: error.message, status: null };
  }
};

const useValidateTracking = (trackingID) => {
  const { token } = useContext(AuthContext);
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [isChecking, setIsChecking] = useState(false); 

  useEffect(() => {
    if (trackingID.length === 11) {
      setMessage("");
      setIsChecking(true); 
      validateTrackingID(trackingID, token).then((result) => {
        setIsValid(result.valid);
        setMessage(result.message);
        setStatus(result.status);
        setIsChecking(false); 
      });
    } else {
      setIsValid(null);
      setMessage("");
      setStatus("");
      setIsChecking(false);
    }
  }, [trackingID, token]);

  return { isValid, message, status, isChecking };
};

export default useValidateTracking;
