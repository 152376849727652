import React, { useState } from 'react';
import Spinner from '../../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { LiaEyeSlashSolid, LiaEyeSolid } from "react-icons/lia";
import Businessform from '../Business_Form/businessform';
import "./Signup.css";

const defaultForm = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    country_code: "+234"
};



const Signup = () => {
    const [formInput, setFormInput] = useState(defaultForm);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const { first_name, last_name, email, phone_number, password } = formInput;
    const [customer, setCustomer] = useState(true);
    const [business, setBusiness] = useState(false);
    const [selected, setSelected] = useState("customer"); 

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInput({ ...formInput, [name]: value });
        setError(null);
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formInput),
            });

            const resData = await response.json();

            if (!response.ok) {
                throw new Error(resData.message);
            }

            localStorage.setItem('email', email);

            setFormInput(defaultForm);
            setIsLoading(false);
            navigate(`/EmailVerificationForm?email=${encodeURIComponent(email)}`);
        } catch (error) {
            setIsLoading(false);
            setError(error.message);
            console.log(formInput);
        }
    };


   
    const handleShowPass = () => setShowPass(!showPass);


    const handleCustomer = () => {
        setCustomer(true);
        setBusiness(false);
    };

    const handleBusiness = () => {
        setCustomer(false);
        setBusiness(true);
    };

    const handleCustomerClick = () => {
        setSelected("customer");
        handleCustomer(); 
      };
    
      const handleBusinessClick = () => {
        setSelected("business");
        handleBusiness();
      };

   
  
    return (
        <div className="sign-up-mother">
            <div className="card-1">
                <div className="login-image-overlay">
                    <p>Empowering your business with seamless last-mile solutions.</p>
                </div>
            </div>
            <div className="card-2">
                <h3>Register With Us!</h3>
               
                <div className="switch">
          <button
            onClick={handleCustomerClick}
            className={selected === "customer" ? "active-btn" : ""}
          >
            Customer
          </button>
          <button
            onClick={handleBusinessClick}
            className={selected === "business" ? "active-btn" : ""}
          >
            Business
          </button>
        </div>

                {customer && (
                    <div className="sign-up-form">
                        <h3>Create Customer Account</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="input-combination-1">
                                <span>
                                    <label>First Name</label>
                                    <input type="text" name="first_name" placeholder='Enter First Name' value={first_name} onChange={handleChange} required />
                                </span>
                                <span>
                                    <label>Last Name</label>
                                    <input type="text" name="last_name" placeholder='Enter Last Name' value={last_name} onChange={handleChange} required />
                                </span>
                            </div>
                            <div className="input-combination-1">
                                <span>
                                    <label>Email Address</label>
                                    <input type="email" name="email" placeholder='Enter Email' value={email} onChange={handleChange} required />
                                </span>
                                <span>
                                    <label>Phone Number</label>
                                    <div className="input-combination-2">
                                        <div className="zip-code">+234</div>
                                        <input type="tel" name="phone_number" placeholder='Enter Phone Number' value={phone_number} onChange={handleChange} required />
                                    </div>
                                </span>
                            </div>
                            <span>
                                <label>Password</label>
                                <div className="pass">
                                    <input type={showPass ? "text" : "password"} name="password" placeholder='Enter Password' value={password} onChange={handleChange} required />
                                    <div onClick={handleShowPass}>
                                        {showPass ? <LiaEyeSlashSolid /> : <LiaEyeSolid />}
                                    </div>
                                </div>
                            </span>
                            {error && <p style={{ color: 'red', textAlign: "center" }}>{error}</p>}
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? <Spinner /> : "Sign Up"}
                            </button>
                            <p onClick={() => navigate('/LogIn')}>Already have an account? <span>Log in</span></p>
                        </form>
                    </div>
                )}

                {business && (
                   <Businessform/>
                )}
            </div>
        </div>
    );
}

export default Signup;
