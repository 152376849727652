import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const useDeleteBusinessPackageReceipt = () => {
  const { user, token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const deleteBusinessReceiptMutation = useMutation(
    (packageIds) => deleteBusinessReceipt(packageIds, user.userId, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("packageReceipts");
      },
    }
  );

  return deleteBusinessReceiptMutation;
};

const deleteBusinessReceipt = async (packageIds, userId, token) => {
  if (!Array.isArray(packageIds)) {
    throw new Error("packageIds must be an array");
  }

  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/receipt/delete-multiple/${userId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ packageIds }), // Ensure it is an array
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Failed to delete package receipts");
  }

  return response.json();
};

// const deleteBusinessReceipt = async (packageIds, userId, token) => {
//   const response = await fetch(
//     `${process.env.REACT_APP_SERVER_URL}/receipt/delete-multiple/${userId}`,
//     {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify({ packageIds }),
//     }
//   );

//   if (!response.ok) {
//     throw new Error("Failed to delete package receipts");
//   }

//   return response.json();
// };

export default useDeleteBusinessPackageReceipt;
