import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { CiEdit } from "react-icons/ci";
import useGetLocalShippingCost from "../libs/getLocalShippingCost";
import useLocalUpdateShippingCostById from "../libs/useLocalUpdateShippingCost";
import useLocalDeleteShipCost from "../libs/useLocalDeleteShipCost";
import Spinner from "../../utils/spinner";
import "./Local_shipping_cost.css";

export default function LocalShippingCost() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCostId, setSelectedCostId] = useState(null);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetLocalShippingCost();

  const updateShippingCost = useLocalUpdateShippingCostById();
  const deleteShippingCostMutation = useLocalDeleteShipCost();

  const [costData, setCostData] = useState({
    senderState: "",
    senderCity: "",
    receiverState: "",
    receiverCity: "",
    normalBaseCost: "",
    pickupCost: "",
    additionalCostPerKg: "",
    expressCost:"",
    itemsValuePercentage: "",
  });

  const handleInputChange = (e) => {
    setCostData({
      ...costData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!isLoading && data && selectedCostId) {
      const allShippingCosts =
        data?.pages?.flatMap((page) => page.shippingCosts) || [];
      const selectedShippingCost = allShippingCosts.find(
        (cost) => cost._id === selectedCostId
      );
      if (selectedShippingCost) {
        setCostData({
          senderState: selectedShippingCost.senderState || "",
          senderCity: selectedShippingCost.senderCity || "",
          receiverState: selectedShippingCost.receiverState || "",
          receiverCity: selectedShippingCost.receiverCity || "",
          normalbaseCost: selectedShippingCost.normalBaseCost || "",
          pickupCost: selectedShippingCost.pickupCost || "",
          additionalCostPerKg: selectedShippingCost.additionalCostPerKg || "",
          expressCost: selectedShippingCost.expressCost || "",
          itemsValuePercentage:
            selectedShippingCost.itemsValuePercentage || "",
        });
      }
    }
  }, [selectedCostId, isLoading, data]);

  const handleEditClick = (shippingCost) => {
    if (shippingCost) {
      setSelectedCostId(shippingCost._id);
      setCostData({
        senderState: shippingCost.senderState || "",
        senderCity: shippingCost.senderCity || "",
        receiverState: shippingCost.receiverState || "",
        receiverCity: shippingCost.receiverCity || "",
        normalBaseCost: shippingCost.normalBaseCost || "",
        pickupCost: shippingCost.pickupCost || "",
        additionalCostPerKg: shippingCost.additionalCostPerKg || "",
        expressCost: shippingCost.expressCost || "",
        itemsValuePercentage: shippingCost.itemsValuePercentage || "",
      });
      setEditOverlay(true);
    }
  };

  const handleCancelEdit = () => {
    setSelectedCostId(null);
    setEditOverlay(false);
  };

  const handleUpdateShippingCost = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (selectedCostId) {
        await updateShippingCost.updateShippingCostById(selectedCostId, costData);
      }
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update shipping cost:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteShippingCost = async () => {
    try {
      setLoading(true);
      if (!selectedCostId) {
        console.error("Cost ID not available.");
        return;
      }
      await deleteShippingCostMutation.deleteShipCost(selectedCostId);
      setDeleteOverlay(false);
      setSelectedCostId(null);
      setLoading(false);
    } catch (error) {
      console.error("Failed to delete shipping cost:", error.message);
    }
  };

  const observerRef = useRef();
  const lastShippingCostRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const shippingCostArray =
    data?.pages?.flatMap((page) => page.shippingCosts) || [];
    
    const filteredShippingCosts = shippingCostArray.filter(
      (cost) =>
        (cost.senderState?.toLowerCase().includes(searchTerm.toLowerCase()) || 
         cost.receiverState?.toLowerCase().includes(searchTerm.toLowerCase()) ||
         cost.senderCity?.toLowerCase().includes(searchTerm.toLowerCase()) ||
         cost.receiverCity?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    

  return (
    <Fragment>
        {editOverlay && (
        <div className="local-shipping-cost-edits-over-container">
          <form className="card">
            <label>
             Sender State
              <input
                type="text"
                name="senderState"
                value={costData.senderState}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Receiver State
              <input
                type="text"
                name="receiverState"
                value={costData.receiverState}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Sender City
              <input
                type="text"
                name="senderCity"
                value={costData.senderCity}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Receiver City
              <input
                type="text"
                name="receiverCity"
                value={costData.receiverCity}
                onChange={handleInputChange}
              />
            </label>
            <label>
            Normal Base Cost
              <input
                type="tel"
                name="normalBaseCost"
                value={costData.normalBaseCost}
                onChange={handleInputChange}
              />
            </label>
            <label>
             Pick up Cost
              <input
                type="tel"
                name="pickupCost"
                value={costData.pickupCost}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Additional Cost Per Half kg
              <input
                type="tel"
                name="additionalCostPerKg"
                value={costData.additionalCostPerKg}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Express Cost 
              <input
                type="tel"
                name="expressCost"
                value={costData.expressCost}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Item Value Percentage
              <input
                type="text"
                name="itemsValuePercentage"
                value={costData.itemsValuePercentage}
                onChange={handleInputChange}
              />
            </label>
        
            <div className="btn">
              <button onClick={handleCancelEdit}>Cancel</button>
              <button onClick={handleUpdateShippingCost}>
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}
    
      {deleteOverlay && (
        <div className="local-shipping-cost-delete-over-container">
          <div className="delete-card">
            <CautionIcon />
            <p>
              Are you sure you want to delete this shipping cost? This action
              cannot be undone.
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>Cancel</button>
              <button onClick={handleDeleteShippingCost}>
                {loading ? "Deleting..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
    
      <div className="all-local-shipping-cost">
      <div className="header">
                <p>Local Shipping Cost</p>
                <div className="in">
                    <span>
                        <SearchIcon />
                        <input 
                            type="search" 
                            placeholder='search cost by state, city' 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} 
                            />
                    </span>
                    <div className="btn">
                       <button onClick={() => navigate('/superadmingetquotelocalcost')}>Get Quote</button>
                        <button onClick={() => navigate('/createlocalshippingcost')}>Create Cost</button>
                    </div>
                </div>
            </div>
        <div className="shipping-cost-table">
          {isLoading ? (
            <Spinner />
          ) : isError ? (
            <p>No shipping costs found.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Sender State</th>
                  <th>Receiver State</th>
                  <th>Sender City</th>
                  <th>Receiver City</th>
                  <th>NBase cost</th>
                  <th>Pickup cost</th>
                  <th>Additional Cost</th>
                  <th>Express Cost</th>
                  <th>Item Value Percentage</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredShippingCosts.map((cost, index) => (
                  <tr
                    key={cost._id}
                    ref={
                      index === filteredShippingCosts.length - 1
                        ? lastShippingCostRef
                        : null
                    }
                  >
                    <td>{cost.senderState}</td>
                    <td>{cost.receiverState}</td>
                    <td>{cost.senderCity}</td>
                    <td>{cost.receiverCity}</td>
                    <td>{cost.normalBaseCost}</td>
                    <td>{cost.pickupCost}</td>
                    <td>{cost.additionalCostPerKg}</td>
                    <td>{cost.expressCost}</td>
                    <td>{cost.itemsValuePercentage}</td>
                    
                    <td>
                     <div className="action">
                     <CiEdit onClick={() => handleEditClick(cost)} />
                      <TrashIcon
                        onClick={() => {
                          setDeleteOverlay(true);
                          setSelectedCostId(cost._id);
                        }}
                      />
                     </div>
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner/>}
      </div>
    </Fragment>
  );
}
