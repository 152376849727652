import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";

const deleteBusinessCategory = async (categoryId, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/categories/deletecategory/${categoryId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to delete business category");
  }

  return response.json();
};

const useDeleteBusinessCategory = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation((categoryId) => deleteBusinessCategory(categoryId, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("businessCategories");
    },
  });
};

export default useDeleteBusinessCategory;
