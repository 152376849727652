import { useMutation } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

const initiatePayment = async (paymentData, token) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/payments/initiate`,
    paymentData,
    {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    }
  );
  return response.data;
};

const useInitiatePayment = () => {
  const { token } = useContext(AuthContext); 

  return useMutation(
    (paymentData) => initiatePayment(paymentData, token), 
    {
      onSuccess: (data) => {
        // Redirect to Flutterwave payment page
        window.location.href = data.link;
      },
      onError: (error) => {
        console.error("Payment initiation failed:", error);
      },
    }
  );
};

export default useInitiatePayment;

