import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

const fetchPendingPackages = async ({ pageParam = 1, userId, token }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/businesspkgs/pending-packages/${userId}?page=${pageParam}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return {
    pendingPackages: response.data.pendingPackages,
    currentPage: pageParam,
    totalPending: response.data.totalPending,
  };
};

const usePendingPackages = (userId) => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ["pendingPackages", userId],
    ({ pageParam = 1 }) => fetchPendingPackages({ pageParam, userId, token }),
    {
      enabled: !!userId,
      getNextPageParam: (lastPage) => {
        if (lastPage.currentPage < Math.ceil(lastPage.totalPending / 10)) {
          return lastPage.currentPage + 1;
        }
        return undefined;
      },
    }
  );
};

export default usePendingPackages;
