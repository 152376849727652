import { useContext, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";

export default function useFetchExcel() {
  const { token } = useContext(AuthContext); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchExcelFile = async () => {
    if (!token) {
      setError("Unauthorized: No authentication token found.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/excel/generate-excel`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch Excel file: ${response.statusText}`);
      }

      const data = await response.json(); 

      if (data.success && data.fileUrl) {
        const a = document.createElement("a");
        a.href = data.fileUrl;
        a.download = "export.xlsx"; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        throw new Error("File URL not found in response.");
      }
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { fetchExcelFile, loading, error };
}
