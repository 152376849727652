import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ConcernIcon } from "../../Assets/golf-flag-svgrepo-com 1.svg";
import { ReactComponent as BusinessPackageIcon } from "../../Assets/business-packages-box-svgrepo-com 1.svg";
import { ReactComponent as PendingOrderIcon } from "../../Assets/Layer_1.svg";
import { ReactComponent as TopUpIcon } from "../../Assets/image 2.svg";
import { BsBox } from "react-icons/bs";
import { RiEBike2Line } from "react-icons/ri";
import { HiTruck } from "react-icons/hi2";
import { BsBoxSeam, BsBox2 } from "react-icons/bs";
import useWalletBalance from "../lib/useWalletBalance";
import usePackageStatus from "../lib/usePackageStatus";
import { AuthContext } from "../../Context/AuthContext"; 
import Skeleton from "react-loading-skeleton"; 
import 'react-loading-skeleton/dist/skeleton.css'; 
import "./Business_overview.css";

export default function Businessoverview() {
  const { user } = useContext(AuthContext);
  const userId = user?.userId;

  const { data: walletBalance, isLoading } = useWalletBalance(userId);
  const { data: packageStatus, isLoading:statusLoading } = usePackageStatus(userId);

  console.log(packageStatus)

  return (
    <div className="business-overview-container">
      <div className="card-1">
        <h3>Quick Links</h3>
        <span>
          <h3>{isLoading ? <Skeleton width={80} height={20} /> : `NGN ${walletBalance?.toLocaleString()}`}</h3>
          <p>Wallet Balance</p>
        </span>
      </div>

      <div className="card-2">
        <Link to="/businesspackageshipment" className="quick-link">
          <h3>Start New Request</h3>
          <BusinessPackageIcon />
        </Link>

        <Link to="/business_pending_order" className="quick-link">
          <h3>View Pending Orders</h3>
          <PendingOrderIcon />
        </Link>

        <Link to="/businesswallet" className="quick-link">
          <h3>Top - Up Wallet</h3>
          <TopUpIcon />
        </Link>

        <Link to="/raise-concerns" className="quick-link">
          <h3>Raise a Concern</h3>
          <ConcernIcon />
        </Link>
      </div>

      <div className="card-3">
        <span>
          <h3>Overview</h3>
        </span>
        <div className="wrapper">
          <div className="overview-box">
            <div className="circle">
              <BsBox className="icons" />
            </div>
            <span>
              <p>Pending Pickup</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.in_warehouse || 0}</h3>}
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <RiEBike2Line className="icons" />
            </div>
            <span>
              <p>Picked up Items</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.left_origin_facility || 0}</h3>}
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <RiEBike2Line className="icons" />
            </div>
            <span>
              <p>At Distribution Center</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.at_distribution_center || 0}</h3>}
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <HiTruck className="icons" />
            </div>
            <span>
              <p>Prepared for delivery</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.available_for_pickup || 0}</h3>} 
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <HiTruck className="icons" />
            </div>
            <span>
              <p>Delivery in progress</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.out_for_delivery  || 0}</h3>} 
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <BsBoxSeam className="icons" />
            </div>
            <span>
              <p>Delivered Items</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.delivered  || 0}</h3>} 
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <BsBoxSeam className="icons" />
            </div>
            <span>
              <p>Delivery failed</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.delivery_failed  || 0}</h3>} 
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <BsBoxSeam className="icons" />
            </div>
            <span>
              <p>Delivery Delayed</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.delayed  || 0}</h3>} 
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <BsBoxSeam className="icons" />
            </div>
            <span>
              <p>Delivery Delayed</p>
              {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.diverted  || 0}</h3>} 
            </span>
          </div>
          <div className="overview-box">
            <div className="circle">
              <BsBox2 className="icons" />
            </div>
            <span>
              <p>Total shipment</p>
             {statusLoading ? <Skeleton width={80} height={20} />  : <h3>{packageStatus?.total  || 0}</h3>} 
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
