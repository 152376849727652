import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const useBusinessUpdatePackage = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const updatePackageMutation = useMutation(
    ({ packageId, updatedData }) =>
      axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/packages/update-pkg-sender-receiver/${packageId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('packagesByCreator');
        queryClient.invalidateQueries('packageDetails');
        queryClient.invalidateQueries('dispatchDetailsByCreatorId');
      },
    }
  );

  const updatePackage = async ({ packageId, updatedData }) => {
    try {
      await updatePackageMutation.mutateAsync({ packageId, updatedData });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { updatePackage, isUpdating: updatePackageMutation.isLoading };
};

export default useBusinessUpdatePackage;
