import "./BusinessNavbar.css";
import React, { useState, useContext } from "react";
import { NavLink as Link, Outlet } from "react-router-dom";
import { Fragment } from "react";
import { BiCategoryAlt } from "react-icons/bi";
import { CiSettings } from "react-icons/ci";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { HiOutlineWallet } from "react-icons/hi2";
import { IoLogoDropbox } from "react-icons/io5";
import { IoPrintOutline } from "react-icons/io5";
import { IoCreateOutline } from "react-icons/io5";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import { HiTruck } from "react-icons/hi2";
import { FaXmark } from "react-icons/fa6";
import { CiMenuFries } from "react-icons/ci";
import { TfiPackage } from "react-icons/tfi";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { ReactComponent as CompanyLogo } from "../../Assets/Frame 1707479966.svg";
import UserDataFetcher from "../../super_admin/libs/Getadminbyid";
import { AuthContext } from "../../Context/AuthContext";

const BusinessNavbar = () => {
  const { Logout } = useContext(AuthContext);
  const { adminData } = UserDataFetcher();
  const [userDrop, setUserDrop] = useState(false);
  const [mobileUserdrop, setMobileUserdrop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const toggleUser = () => {
    setUserDrop(!userDrop);
  };

  const toggleMobileUserdrop = () => {
    setMobileUserdrop(!mobileUserdrop);
  };

  const NavLinkLCss = ({ isActive }) => {
    return {
      background: isActive
        ? "linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 205, 0, 0.50) 100%)"
        : "",
      color: isActive ? "#284697" : "",
      border: isActive ? "1px solid #FFCD00" : "",
    };
  };

  return (
    <Fragment>
      <div className="business_admin_header">
        <p>
          Welcome back, <span>{adminData?.user?.first_name}</span>
          <span>{adminData?.user?.last_name}</span>
        </p>
        <div className="user-details" onClick={toggleUser}>
          <div className="card-1">
            <div className="circle">
              {adminData?.user?.first_name?.charAt(0)}
              {adminData?.user?.last_name?.charAt(0)}
            </div>
            {userDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </div>

          <div className={`card-2 ${userDrop ? "open" : "closed"}`}>
            <span>
              <p>Business Email:</p>
              <h3>{adminData?.user?.business_email}</h3>
            </span>
            <span>
              <p>Company Name:</p>
              <h3>{adminData?.user?.company_name}</h3>
            </span>
            <span>
              <p>Business Type:</p>
              <h3>{adminData?.user?.role}</h3>
            </span>
            <button onClick={() => Logout()}>Log out</button>
          </div>
        </div>
      </div>

      <div className="business-nav-container">
        <Link to="/">
          <CompanyLogo className="company-logo" />
        </Link>
        <div className={`links ${isMobile ? "mobile active" : ""}`}>
          <div className="mobile-user-drop">
            <div className="profile">
              <div className="circle">
                {adminData?.user?.first_name?.charAt(0)}
                {adminData?.user?.last_name?.charAt(0)}
              </div>
              <div className="card-1">
                <div className="wrapper">
                  <span>{adminData?.user?.first_name}</span>
                  <span>{adminData?.user?.last_name}</span>
                </div>
              </div>
            </div>

            <div className="card-2">
              <div className="drop-card" onClick={toggleMobileUserdrop}>
                <h3>COMPANY PROFILE</h3>
                {mobileUserdrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
              <div className={`wrapper ${mobileUserdrop ? "open" : "closed"}`}>
                <span>
                  <p>Company Name:</p>
                  <h3>{adminData?.user?.company_name}</h3>
                </span>
                <span>
                  <p>Business Email:</p>
                  <h3>{adminData?.user?.business_email}</h3>
                </span>
                <span>
                  <p>Phone Number:</p>
                  <h3>{adminData?.user?.phone_number}</h3>
                </span>
                <span>
                  <p>Business Type:</p>
                  <h3>{adminData?.user?.role}</h3>
                </span>
                <span>
                  <p>Business Address:</p>
                  <h3>{adminData?.user?.business_address}</h3>
                </span>
                <span>
                  <p>Location:</p>
                  <h3>{adminData?.user?.state}</h3>
                </span>
                <button onClick={() => Logout()}>Log out</button>
              </div>
            </div>
          </div>

          <Link to="/" style={NavLinkLCss} onClick={() => setIsMobile(false)}>
            <BiCategoryAlt className="icon" /> Overview
          </Link>
          <Link
            to="/businessitemcategory"
            style={NavLinkLCss}
            onClick={() => setIsMobile(false)}
          >
            <IoCreateOutline className="icon" /> Create Category
          </Link>
          <Link
            to="/businesspackageshipment"
            style={NavLinkLCss}
            onClick={() => setIsMobile(false)}
          >
            <TfiPackage className="icon" /> Ship Now
          </Link>
          <Link
            to="/businessshipment"
            onClick={() => setIsMobile(false)}
            style={NavLinkLCss}
          >
            <IoLogoDropbox className="icon" />
            Shipments
          </Link>
          <Link
            to="/businessinterstatetrip"
            onClick={() => setIsMobile(false)}
            style={NavLinkLCss}
          >
            <HiTruck className="icon" />
            Inter State Trip
          </Link>
          <Link
            to="/my-concerns"
            onClick={() => setIsMobile(false)}
            style={NavLinkLCss}
          >
            <AiOutlineIssuesClose className="icon" />
            My Concerns
          </Link>
          <Link
            to="/businesspackagereceipt"
            onClick={() => setIsMobile(false)}
            style={NavLinkLCss}
          >
            <IoPrintOutline className="icon" />
            Print Shipment Label
          </Link>

          <Link
            to="/businesswallet"
            style={NavLinkLCss}
            onClick={() => setIsMobile(false)}
          >
            <HiOutlineWallet className="icon" />
            Wallet
          </Link>
          <Link
            to="/businessgetquote"
            onClick={() => setIsMobile(false)}
            style={NavLinkLCss}
          >
            <LiaMoneyCheckSolid className="icon" />
            Get Quote
          </Link>

          <Link
            to="/businessusermanagement"
            style={NavLinkLCss}
            onClick={() => setIsMobile(false)}
          >
            <CiSettings className="icon" /> Settings
          </Link>
        </div>
        <div className="mobile-menus" onClick={handleMobileMenu}>
          {isMobile ? <FaXmark /> : <CiMenuFries />}
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default BusinessNavbar;
