import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useContext, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";

const useBusinessPackageCreate = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const [receipt, setReceipt] = useState(false);
  const [receiptData, setReceiptData] = useState(null);

  const fetchPackage = async (packageData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/businesspkgs/create-business-package`,
        packageData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

     
      queryClient.invalidateQueries("recentPackages");
      queryClient.invalidateQueries("packagesByCreator");

      setReceiptData(response.data);
      setReceipt(true);
      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const mutation = useMutation(fetchPackage);

  const createPackage = async (packageData) => {
    return await mutation.mutateAsync(packageData);
  };

  return { createPackage, mutation, receipt, receiptData };
};

export default useBusinessPackageCreate;
