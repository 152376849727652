import { useInfiniteQuery } from 'react-query';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';

const fetchBusinessUsers = async ({ pageParam = 1 }, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/users/users/business?page=${pageParam}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch business users');
  }

  return response.json();
};

const useFetchBusinessUsers = () => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ['businessUsers'],
    ({ pageParam = 1 }) => fetchBusinessUsers({ pageParam }, token),
    {
      getNextPageParam: (lastPage) => {
        const { currentPage, totalBusinessUsers } = lastPage;
        const totalPages = Math.ceil(totalBusinessUsers / 10);
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );
};

export default useFetchBusinessUsers;







