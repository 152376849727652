import React, { useState, useRef, useCallback } from "react";
import useActiveDispatches from "../libs/useActiveDispatches";
import Spinner from "../../utils/spinner";
import { ReactComponent as Searchicon } from "../../asset/iconamoon_search-light.svg";

function LogisticsActiveDispatches() {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useActiveDispatches();
  const [searchQuery, setSearchQuery] = useState("");

  // Ensure packages exist before trying to filter by tracking number
  const filteredDispatches =
    data?.pages.flatMap((page) =>
      page.dispatches.filter((dispatch) =>
        dispatch.packages?.[0]?.tracking_number
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    ) || [];

  const observerRef = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 1.0 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div className="logistic-active-dispatch">
      <div className="header">
        <p>Dispatch</p>
        <span>
          <Searchicon />
          <input
            type="search"
            placeholder="Search by Tracking Number"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
      </div>
      <div className="dispatch-table">
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <p style={{ fontFamily: "Montserrat" }}>No dispatch found</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Tracking ID</th>
                <th>Items/Weight </th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Location</th>
                <th>Date Created</th>
                <th>Estimated Delivery Date</th>
                <th>Dispatch Status</th>
                <th>Rider Name</th>
                <th>Rider Phone</th>
                {/* <th>Driver Name</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredDispatches.map((dispatch, index) => {
                const latestStatus =
                  dispatch.packages?.[0]?.status?.slice(-1)[0] || {};
                const isLastItem = filteredDispatches.length === index + 1;

                return (
                  <tr
                    key={dispatch?._id || index}
                    ref={isLastItem ? lastProductElementRef : null}
                  >
                    <td>{dispatch?.packages?.[0]?.tracking_number || "N/A"}</td>
                    <td>
                      {dispatch?.packages?.flatMap((pkg) =>
                        pkg.items.map((item) => (
                          <div key={item?._id}>
                            {item?.name} / {item?.weight}kg
                          </div>
                        ))
                      ) || "N/A"}
                    </td>
                    <td>
                      {dispatch?.packages?.flatMap((pkg) =>
                        pkg.items.map((item) => (
                          <div key={item?._id}>{item?.quantity}</div>
                        ))
                      ) || 0}
                    </td>

                    <td>{latestStatus?.status || "N/A"}</td>
                    <td>
                      {latestStatus.city && latestStatus.state
                        ? `${latestStatus.city}, ${latestStatus.state}`
                        : "N/A"}
                    </td>
                    <td>
                      {dispatch.packages?.[0]?.dateCreated
                        ? new Date(
                            dispatch.packages[0].dateCreated
                          ).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>
                      {dispatch.packages?.[0]?.estimated_delivery_date
                        ? new Date(
                            dispatch.packages[0].estimated_delivery_date
                          ).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>
                      {dispatch.isAssignedToRider || dispatch.isAssignedToDriver
                        ? "Assigned"
                        : "Not Assigned"}
                    </td>
                    <td>
                      {dispatch?.riderDetails
                        ? `${dispatch.riderDetails.first_name} ${dispatch.riderDetails.last_name}`
                        : "N/A"}
                    </td>
                    <td>{dispatch?.riderDetails?.phone_number || "N/A"}</td>
                    {/* <td>{dispatch.assignedDrivers?.[0]?.name || "N/A"}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {isFetchingNextPage && (
        <div className="loading-more">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default LogisticsActiveDispatches;
