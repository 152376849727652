import React from "react";
import { useState, useRef, useCallback, Fragment } from "react";
import useShipmentsByCreatorId from "../lib/fetchShipmentByCreatorId";
import useGetDriverUsers from "../../Logistics_manager/libs/useGetDriverUsers";
import useAssignPackagesToDriver from "../../Logistics_manager/libs/useAssignPackagesToDriver";
import Spinner from "../../utils/spinner";
import { ReactComponent as SearchIcon } from "../../asset/iconamoon_search-light.svg";
import { ReactComponent as EllipIcon } from "../../asset/Ellipse 3685.svg";
import { ReactComponent as BlockedIcon } from "../../asset/Blocked 18.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 2.svg";

import "./Business_interstate_trip.css";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export default function Businessinterstatetrip() {
  const {
    data,
    isLoading: isDispatchLoading,
    isError: isDispatchError,
    fetchNextPage: fetchNextDispatchPage,
    hasNextPage: hasNextDispatchPage,
    isFetchingNextPage: isFetchingNextDispatchPage,
  } = useShipmentsByCreatorId();

  const dispatches = data ? data.pages.flatMap((page) => page.dispatches) : [];

  const assignPackagesToDriverMutation = useAssignPackagesToDriver();
  const [searchQuery, setSearchQuery] = useState("");
  const [addedPackages, setAddedPackages] = useState([]);
  const [duplicateError, setDuplicateError] = useState(false);
  const [assignedOverlay, setAssignedOverlay] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [assignError, setAssignError] = useState(null);

  const dispatchObserverRef = useRef();
  const lastDispatchElementRef = useCallback(
    (node) => {
      if (isFetchingNextDispatchPage) return;

      if (dispatchObserverRef.current) dispatchObserverRef.current.disconnect();

      dispatchObserverRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextDispatchPage) {
            fetchNextDispatchPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) dispatchObserverRef.current.observe(node);
    },
    [isFetchingNextDispatchPage, fetchNextDispatchPage, hasNextDispatchPage]
  );

  const [searchDriverQuery, setSearchDriverQuery] = useState("");
  const [addedDrivers, setAddedDrivers] = useState([]);

  const {
    data: driverUsers,
    isLoading: isDriverLoading,
    isError: isDriverError,
    fetchNextPage: fetchNextDriverPage,
    hasNextPage: hasNextDriverPage,
    isFetchingNextPage: isFetchingNextDriverPage,
  } = useGetDriverUsers();

  const filteredDriverUsers = Array.isArray(
    driverUsers?.pages.flatMap((page) => page.drivers)
  )
    ? driverUsers.pages
        .flatMap((page) => page.drivers)
        .filter((driver) =>
          `${driver.first_name} ${driver.last_name}`
            .toLowerCase()
            .includes(searchDriverQuery.toLowerCase())
        )
    : [];

  const formatDriverID = (id) => {
    return id.slice(0, 8).toUpperCase();
  };

  const handleCreateTrip = async () => {
    setAssignLoading(true);

    const requestData = {
      packageIds: addedPackages.map((pkg) => pkg._id),
      userId: addedDrivers[0]._id,
    };

    try {
      await assignPackagesToDriverMutation.mutateAsync(requestData);

      setAssignedOverlay(false);
      setAddedPackages([]);
    } catch (error) {
      setAssignError("Error assigning packages to driver");
    } finally {
      setAssignLoading(false);
    }
  };

  const isDriverAlreadyAdded = (driver) => {
    return addedDrivers.some((addedDriver) => addedDriver._id === driver._id);
  };

  const handleAddDriver = (driver) => {
    if (!isDriverAlreadyAdded(driver)) {
      setAddedDrivers((prevAddedDrivers) => [...prevAddedDrivers, driver]);
      setDuplicateError(false);
    } else {
      setDuplicateError(true);
    }
  };

  const handleRemoveDriver = (driver) => {
    setAddedDrivers((prevAddedDrivers) =>
      prevAddedDrivers.filter((addedDriver) => addedDriver._id !== driver._id)
    );
    setDuplicateError(false);
  };

  const filteredDispatches = Array.isArray(dispatches)
    ? dispatches.filter((dispatch) =>
        dispatch?.packages[0]?.receiver?.state
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : [];

  const isPackageAlreadyAdded = (pkg) => {
    return addedPackages.some((addedPkg) => addedPkg._id === pkg._id);
  };

  const handleAddPackage = (dispatch) => {
    const pkg = dispatch.packages[0];
    if (!isPackageAlreadyAdded(pkg)) {
      setAddedPackages((prevAddedPackages) => [...prevAddedPackages, pkg]);
      setDuplicateError(false);
    } else {
      setDuplicateError(true);
    }
  };

  const handleRemovePackage = (addedPkg) => {
    setAddedPackages((prevAddedPackages) =>
      prevAddedPackages.filter((pkg) => pkg._id !== addedPkg._id)
    );
    setDuplicateError(false);
  };

  const driverObserverRef = useRef();
  const lastDriverElementRef = useCallback(
    (node) => {
      if (isFetchingNextDriverPage) return;

      if (driverObserverRef.current) driverObserverRef.current.disconnect();

      driverObserverRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextDriverPage) {
            fetchNextDriverPage();
          }
        },
        { threshold: 0.5 }
      );

      if (node) driverObserverRef.current.observe(node);
    },
    [isFetchingNextDriverPage, fetchNextDriverPage, hasNextDriverPage]
  );

  return (
    <Fragment>
      {assignedOverlay && (
        <div className="interstate-assigned-over">
          <div className="card">
            <CautionIcon />
            <h3>Create Trip</h3>
            <p>Are you sure you want to create new Trip?</p>
            <div className="btn">
              <button onClick={() => setAssignedOverlay(false)}>No</button>
              <button onClick={handleCreateTrip} disabled={assignLoading}>
                {assignLoading ? <Spinner /> : "Create"}
              </button>
            </div>
            {assignError && <p>{assignError}</p>}
          </div>
        </div>
      )}

      <div className="business-inter-state">
        <div className="header">
          <p>Interstate Trips</p>
          <button
            onClick={() => setAssignedOverlay(true)}
            disabled={addedPackages.length === 0 || addedDrivers.length === 0}
          >
            Create Trip
          </button>
        </div>
        <div className="shipment-assigned">
          <div className="assigned">
            <span>
              <SearchIcon />
              <input
                type="search"
                placeholder="Search Shipment by State"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </span>
            <div className="card">
              {isDispatchLoading ? (
                <Spinner />
              ) : isDispatchError ? (
                <p>Error fetching dispatches</p>
              ) : filteredDispatches.length > 0 ? (
                filteredDispatches.map((dispatch, index) => (
                  <div
                    key={index}
                    className="package-item"
                    ref={
                      filteredDispatches.length === index + 1
                        ? lastDispatchElementRef
                        : null
                    }
                  >
                    <div className="items">
                      <h3>{dispatch.packages[0]?.tracking_number}</h3>
                      <p>{dispatch.packages[0]?.name}</p>
                      <p>
                        {dispatch.packages[0]?.receiver?.state},{" "}
                        {dispatch.packages[0]?.receiver?.address_1}
                      </p>
                      <p>
                        {new Date(
                          dispatch.packages[0]?.estimated_delivery_date
                        ).toLocaleDateString()}
                      </p>
                    </div>
                    <button onClick={() => handleAddPackage(dispatch)}>
                      Add
                    </button>
                  </div>
                ))
              ) : (
                <p>No dispatches found.</p>
              )}
            </div>
            {isFetchingNextDispatchPage && (
              <div className="loading-more">
                <Spinner />
              </div>
            )}
          </div>

          <div className="business-added-package">
            {addedPackages.map((addedPkg, index) => (
              <div className="card" key={index}>
                <div className="in">
                  <p>{addedPkg?.tracking_number}</p>
                  <p>{addedPkg?.receiver.state}</p>
                  <p>{addedPkg?.name}</p>
                  <p>{formatDate(addedPkg.estimated_delivery_date)}</p>
                </div>
                <button onClick={() => handleRemovePackage(addedPkg)}>
                  Remove
                </button>
              </div>
            ))}
            {duplicateError && (
              <div className="error-message">
                <p>Package with the same ID is already added.</p>
              </div>
            )}
          </div>

          <div className="driver-con">
            <span>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search Driver by Name"
                value={searchDriverQuery}
                onChange={(e) => setSearchDriverQuery(e.target.value)}
              />
            </span>
            {isDriverLoading ? (
              <Spinner />
            ) : isDriverError ? (
              <p>Error fetching drivers</p>
            ) : (
              <div className="driver-list">
                {Array.isArray(filteredDriverUsers) &&
                filteredDriverUsers.length > 0 ? (
                  filteredDriverUsers.map((driver, index) => (
                    <div
                      className="card"
                      key={driver._id}
                      ref={
                        filteredDriverUsers.length === index + 1
                          ? lastDriverElementRef
                          : null
                      }
                    >
                      <div className="inner">
                        <h3>
                          {driver?.first_name} {driver?.last_name}
                        </h3>
                        <span>{driver?.phone_number}</span>
                        <span>ID: {formatDriverID(driver._id)}</span>
                        <p>Driver</p>
                        <p>
                          {driver?.blocked ? <BlockedIcon /> : <EllipIcon />}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          isDriverAlreadyAdded(driver)
                            ? handleRemoveDriver(driver)
                            : handleAddDriver(driver)
                        }
                      >
                        {isDriverAlreadyAdded(driver) ? "Undo" : "Add"}
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No drivers found.</p>
                )}
              </div>
            )}
            {isFetchingNextDriverPage && (
              <div className="loading-more">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
