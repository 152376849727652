import React, { useState, useContext, Fragment } from "react";
import { AuthContext } from "../../Context/AuthContext";
import useBusinessCategories from "../lib/getBusinessCategory";
import useCreateBusinessCategory from "../lib/businessCreateCategory";
import useDeleteBusinessCategory from "../lib/deleteBusinessCategory";
import useUpdateBusinessCategory from "../lib/updateBusinessCategory";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { CiCircleMinus } from "react-icons/ci";
import Spinner from "../../utils/spinner";
import "./Business_Items_Category.css";

export default function Businessitemcategory() {
     const { user } = useContext(AuthContext);
      const [name, setName] = useState("");
      const [description, setDescription] = useState([""]);
      const creator = user.userId;
      const [openPopUp, setPopUp] = useState(false);
      const [msg, setMsg] = useState("");
      const [selectedId, setSelectedId] = useState(null);
      const [deleteOverLay, setDeleteOverlay] = useState(false);
      const [updateOverlay, setUpdateOverlay] = useState(false);
      const [updateName, setUpdateName] = useState("");
      const [updateDescription, setUpdateDescription] = useState([]);

      const createCategoryMutation = useCreateBusinessCategory();
      const deleteCategoryMutation = useDeleteBusinessCategory();
      const updateCategoryMutation = useUpdateBusinessCategory();
      const { data: userCategories, isLoading, isError } = useBusinessCategories();


      const handleAddDescriptionField = () => {
        setDescription([...description, ""]);
      };
    
      const handleRemoveDescriptionField = (index) => {
        setDescription(description.filter((_, i) => i !== index));
      };
    
      const handleDescriptionChange = (value, index) => {
        const updatedDescription = [...description];
        updatedDescription[index] = value;
        setDescription(updatedDescription);
      };
    
      const handleCreateCategory = async (e) => {
        e.preventDefault();
        try {
          await createCategoryMutation.mutateAsync({
            name,
            description,
            creator,
          });
          setName("");
          setDescription([""]);
          setMsg("Category created successfully");
          setTimeout(() => {
            setPopUp(false);
            setMsg("");
          }, 3000);
        } catch (error) {
          console.error("Error creating category:", error);
        }
      };
    
      const handleUpdateButtonClick = (categoryId) => {
        const selectedCategory = userCategories.categories.find(
          (category) => category._id === categoryId
        );
        setSelectedId(categoryId);
        setUpdateName(selectedCategory?.name || "");
        setUpdateDescription(selectedCategory?.description || [""]);
        setUpdateOverlay(true);
      };
    
      const handleUpdateCategory = async (e) => {
        e.preventDefault();
        try {
          await updateCategoryMutation.mutateAsync({
            categoryId: selectedId,
            categoryData: { name: updateName, description: updateDescription },
          });
          setUpdateOverlay(false);
          setSelectedId(null);
        } catch (error) {
          console.error("Error updating category:", error);
        }
      };
    
      const handleDeleteCategory = async () => {
        try {
          await deleteCategoryMutation.mutateAsync(selectedId);
          setDeleteOverlay(false);
          setSelectedId(null);
        } catch (error) {
          console.error("Error deleting category:", error);
        }
      };


      return (
        <Fragment>
          {updateOverlay && (
            <div className="business-update-container">
              <form onSubmit={handleUpdateCategory} >
                <p>Update Category</p>
                <input
                  type="text"
                  placeholder="Category Name"
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                  autoFocus
                />
                 <p>Descriptions</p>
                {updateDescription.map((desc, index) => (
                  <div key={index} className="update-description-fields">
                  <div className="wrapper">
                  <input
                      type="text"
                      value={desc}
                      onChange={(e) =>
                        setUpdateDescription((prev) => {
                          const updated = [...prev];
                          updated[index] = e.target.value;
                          return updated;
                        })
                      }
                      placeholder={`Item ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setUpdateDescription((prev) =>
                          prev.filter((_, i) => i !== index)
                        )
                      }
                      disabled={updateDescription.length === 1}
                    >
                     <p>Remove</p>
                      <CiCircleMinus className="business-update-removal-icon"/>
                    </button>
                  </div>
                  </div>
                ))}
                <button
                className="update-button-add"
                  type="button"
                  onClick={() =>
                    setUpdateDescription((prev) => [...prev, ""])
                  }
                >
                  Add Description
                </button>
                <div className="btn">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedId(null);
                      setUpdateOverlay(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit">
                    {updateCategoryMutation.isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              </form>
            </div>
          )}
          {deleteOverLay && (
            <div className="business-deleted-container">
              <div className="card">
                <CautionIcon />
                <h3>Delete</h3>
                <p>Are you sure you want to delete this Category?</p>
                <div className="btn">
                  <button onClick={() => setDeleteOverlay(false)}>No</button>
                  <button onClick={handleDeleteCategory} disabled={deleteCategoryMutation.isLoading}>
                    {deleteCategoryMutation.isLoading ? "Deleting..." : "Yes"}
                  </button>
                </div>
              </div>
            </div>
          )}
          {openPopUp && (
            <div className="business-create-category-overlay">
              <form onSubmit={handleCreateCategory}>
                <p>Add New Category</p>
                <label>
                  Category Name
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <p>Descriptions</p>
                {description.map((desc, index) => (
                  <div key={index} className="business-description-fields">
                   <div className="wrapper">
                   <input
                      type="text"
                      value={desc}
                      onChange={(e) =>
                        handleDescriptionChange(e.target.value, index)
                      }
                      placeholder={`Item ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveDescriptionField(index)}
                      disabled={description.length === 1}
                    >
                  <p>Remove</p>
                      <CiCircleMinus className="removal-icon"/>
                    </button>
                   </div>
                  </div>
                ))}
                <button type="button" className="button-add" onClick={handleAddDescriptionField}>
                  Add New Description
                </button>
                <div className="btn">
                  <button onClick={() => setPopUp(false)}>Cancel</button>
                  <button type="submit" disabled={createCategoryMutation.isLoading}>
                    {createCategoryMutation.isLoading ? "Saving..." : "Save"}
                  </button>
                </div>
                {msg && <span>{msg}</span>}
              </form>
            </div>
          )}
          <div className="business-create-category">
            <div className="header">
              <p>Create Category</p>
              <button onClick={() => setPopUp(true)}>Create</button>
            </div>
            <div className="business-user-category">
              <p>Categories</p>
              <div className="loading-error">
              {isLoading && <Spinner />}
              {isError && <p>No category found! create one.</p>}
              </div>
              {userCategories &&
                userCategories.categories.map((category) => (
                  <div key={category._id} className="category-item">
                    <div className="card">
                      <p>{category?.name}</p>
                      <p>{category?.description.join(", ")}</p>
                    </div>
                    <div className="btn">
                      <button
                        type="button"
                        onClick={() => handleUpdateButtonClick(category._id)}
                      >
                        Update
                      </button>
                      <button
                        onClick={() => {
                          setSelectedId(category._id);
                          setDeleteOverlay(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Fragment>
      );
}
