import { useQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

const fetchWalletBalance = async (userId, token) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/payments/wallet/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Include auth token
      },
    }
  );
  return response.data.balance; // Extract only the balance
};

const useWalletBalance = (userId) => {
  const { token } = useContext(AuthContext); // Get token from context

  return useQuery(
    ["walletBalance", userId], // Unique query key
    () => fetchWalletBalance(userId, token), // Fetch function
    {
      enabled: !!userId, // Only run query if userId exists
    }
  );
};

export default useWalletBalance;
