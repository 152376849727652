import React, { useState, useEffect, Fragment, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import useBusinessPackageCreate from "../lib/createBulkPackages";
import useWalletBalance from "../lib/useWalletBalance";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Business_bulk_packages_creation.css";

export default function BusinessBulkPackagesCreation() {
  const { user } = useContext(AuthContext);
  const userId = user?.userId;
  const [packages, setPackages] = useState([]);
  const [overlay, setOverlay] = useState({ show: false, message: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { createPackage, mutation } = useBusinessPackageCreate();
  const { data: walletBalance, isLoading } = useWalletBalance(userId);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("bulkShippingData"));
    if (storedData?.packages) {
      setPackages(storedData.packages);
    }
  }, []);

  const handleEdit = (index, field, value) => {
    const updatedPackages = [...packages];
    updatedPackages[index][field] = value;
    setPackages(updatedPackages);
    localStorage.setItem(
      "bulkShippingData",
      JSON.stringify({ packages: updatedPackages })
    );
  };

  const handleCreate = async () => {
    setOverlay({ show: true, message: "Creating Shipments..." });
    setError(null);

    const storedData = JSON.parse(localStorage.getItem("bulkShippingData"));

    if (
      !storedData ||
      !storedData.creator ||
      !Array.isArray(storedData.packages)
    ) {
      setError("Invalid or missing data in local storage.");
      setOverlay({ show: false, message: "" });
      return;
    }

    try {
      const cleanedPackages = storedData.packages.map((pkg) => ({
        ...pkg,
        shipping_cost: parseFloat(pkg.shipping_cost) || 0,
        original_shipping_cost: parseFloat(pkg.original_shipping_cost) || 0,
        total_discount: parseFloat(pkg.total_discount) || 0,
        discount_percentage: parseFloat(pkg.discount_percentage) || 0,
      }));

      const cleanedData = { ...storedData, packages: cleanedPackages };

      const response = await createPackage(cleanedData);

      if (response?.success) {
        setOverlay({ show: true, message: "Shipment(s) created successfully." });
      
        setTimeout(() => {
          setOverlay({ show: false, message: "" });
          navigate("/businessshipment");
        }, 3000);
      } else {
        throw new Error(response?.message || "Package creation failed.");
      }
    } catch (err) {
      const serverError =
        err.response?.data?.message ||
        mutation.error?.message ||
        err.message ||
        "An error occurred";

      setError(serverError);

      if (serverError === "Insufficient balance to cover the total shipping cost.") {
        // Keep overlay visible for insufficient balance case
        setOverlay({ show: true, message: "" });
      } else {
        // Hide overlay after 3 seconds for other errors
        setTimeout(() => {
          setError(null);
          setOverlay({ show: false, message: "" });
        }, 3000);
      }
    }
  };

  const totalShippingCost = packages.reduce(
    (sum, pkg) => sum + parseFloat(pkg.shipping_cost || 0),
    0
  );

  return (
    <Fragment>
      {overlay.show && (
        <div className="insufficient-overlay">
            {!error && <p className="message-card">{overlay.message}</p>}
            <p className="message-card">{error}</p>
            {error && (
              <>
               {error ===
                "Insufficient balance to cover the total shipping cost." ? (
                  <div className="wallet-balance">
                    <span>
                      <p>
                        Wallet Balance:{" "}
                        {isLoading ? (
                          <Skeleton width={80} height={20} />
                        ) : (
                          `NGN ${walletBalance?.toLocaleString()}`
                        )}
                      </p>
                      <p>
                        Shipping Cost:{" "}
                        {totalShippingCost
                          ? `NGN ${totalShippingCost.toLocaleString()}`
                          : "N/A"}
                      </p>
                    </span>
                    <div className="btn">
                      <button onClick={() => navigate("/businesswallet")}>
                        Fund Wallet
                      </button>
                      <button
                        onClick={() => {
                          setError(null);
                          setOverlay({ show: false, message: "" });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : null}
              </>
            )}
        </div>
      )}

      <div className="bulk-packages-container">
        <div className="header">
          <h2>Business Bulk Packages</h2>
          <button onClick={handleCreate}>Create</button>
        </div>
        <table className="bulk-packages-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Items</th>
              <th>Estimated Delivery Date</th>
              <th>Shipping Cost</th>
              <th>Original Shipping Cost</th>
              <th>Total Discount</th>
              <th>Discount Percentage</th>
              <th>Payment Method</th>
              <th>Delivery Method</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <strong>{pkg.sender?.name}</strong>
                  <br />
                  {pkg.sender?.email}
                  <br />
                  {pkg.sender?.phone_number}
                </td>
                <td>
                  <strong>{pkg.receiver?.name}</strong>
                  <br />
                  {pkg.receiver?.email}
                  <br />
                  {pkg.receiver?.phone_number}
                </td>
                <td>
                  {pkg.items?.length ? (
                    <ul>
                      {pkg.items.map((item, i) => (
                        <li key={i}>
                          <strong>{item.name}</strong> - {item.quantity} × $
                          {item.items_value}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No items"
                  )}
                </td>
                <td>
                  <input
                    type="date"
                    value={pkg.estimated_delivery_date || ""}
                    onChange={(e) =>
                      handleEdit(
                        index,
                        "estimated_delivery_date",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>{pkg.shipping_cost}</td>
                <td>{pkg.original_shipping_cost}</td>
                <td>{pkg.total_discount}</td>
                <td>{pkg.discount_percentage}%</td>
                <td>{pkg.payment_method}</td>
                <td>
                  <select
                    value={pkg.delivery_method}
                    onChange={(e) =>
                      handleEdit(index, "delivery_method", e.target.value)
                    }
                  >
                    <option value="Home">Home</option>
                    <option value="Pickup">Office</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
