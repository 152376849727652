import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Add from '../../utils/Bulk_ship_icon/add';
import useFetchExcel from '../lib/downloadExcelFile';
import { ReactComponent as BoxIcon } from "../../asset/box-svgrepo-com 1.svg";
import { BsBoxes, BsBoxSeam } from "react-icons/bs";
import { FaXmark } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import "./Business_package_shipment.css";

export default function Businesspackageshipment() {
  const navigate = useNavigate();
  const [singleOverlay, setSingleOverlay] = useState(false);
  const [bulkOverlay, setBulkOverlay] = useState(false);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(""); 
  const { fetchExcelFile, loading, error } = useFetchExcel(); 

  const handleDeliveryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDeliveryType(selectedValue); 

    if (selectedValue === "National delivery") {
      navigate('/businesssenderdetails');
    } else if (selectedValue === "international delivery") {
      navigate('/business_international_delivery');
    }
  };

  const handleBulkDeliveryChange = (e) => {
    setSelectedDeliveryType(e.target.value);
  };


  const handleBulkNextPage = () => {
    setBulkOverlay(false);
    
    if (selectedDeliveryType === "international delivery") {
      navigate('/business_international_delivery');
    } else {
      navigate('/business_bulk_upload');
    }
  };
  

  return (
    <>
      {singleOverlay && (
        <div className="business-delivery-types">
          <div className="card">
            <span>
              <h3>Single Order</h3>
              <button onClick={() => setSingleOverlay(false)}>
                <FaXmark style={{ fontSize: "24px" }} />
              </button>
            </span>
            <p>You are creating a single shipping order</p>
            <form>
              <select onChange={handleDeliveryChange} value={selectedDeliveryType}>
                <option value="">Select delivery type</option>
                <option value="National delivery">National delivery</option>
                <option value="international delivery">International delivery</option>
              </select>
            </form>
          </div>
        </div>
      )}

      {bulkOverlay && (
        <div className="business-delivery-types">
          <div className="card">
            <span>
              <h3>Bulk Order</h3>
              <button onClick={() => setBulkOverlay(false)}>
                <FaXmark style={{ fontSize: "24px" }} />
              </button>
            </span>
            <div className="shipping-icon-home">
            <Add/>
            </div>
            <p>You are creating a bulk shipping order</p>
            <form>
              <select onChange={handleBulkDeliveryChange} value={selectedDeliveryType}>
                <option value="">Select delivery type</option>
                <option value="National delivery">National delivery</option>
                <option value="international delivery">International delivery</option>
              </select>

             
              {selectedDeliveryType && (
                <button onClick={fetchExcelFile} disabled={loading}>
                  <IoMdDownload /> {loading ? "Downloading..." : "Download Excel File"}
                </button>
              )}
            </form>

            {selectedDeliveryType && (
              <div className="next">
                <button onClick={handleBulkNextPage}>Next</button>
              </div>
            )}
            {error && <p style={{ color: "red",fontFamily:'Montserrat' }}>{error}</p>}
          </div>
        </div>
      )}

      <div className='business-package-shipment'>
        <div className="header">
          <h3>Package creation</h3>
        </div>
        <div className="wrapper">
          <div className="create-icon">
            <span>
              <h3>Create a shipment</h3>
              <p>Create a single shipment or bulk shipment</p>
            </span>
            <BoxIcon />
          </div>
          <h2>What type of shipment do you want to create?</h2>
          <div className="shipment-type-box">
            <div className="card" onClick={() => setSingleOverlay(true)}>
              <BsBoxSeam className='shipment-type-icon' />
              <h3>Single shipment</h3>
              <p>Create one shipment or many one at a time</p>
            </div>
            <div className="card" onClick={() => setBulkOverlay(true)}>
              <BsBoxes className='shipment-type-icon' />
              <h3>Bulk shipment</h3>
              <p>Add all your shipments at once with our bulk feature</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

