import React, {
  Fragment,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useInfiniteQuery, queryClient } from "react-query";
import { AuthContext } from "../Context/AuthContext";
import { GlobalContext } from "../GlobalContext/GlobalContext";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { ReactComponent as CautionIcon } from "../Assets/caution-svgrepo-com 1.svg";
import useDeletePublicMessages from "./useDeletePublicMessages";
import useMarkTicketAsSolved from "./useMarkTicketAsSolved";
import Spinner from "../Spinner/Spinner";

const PublicMessage = () => {
  const { token } = useContext(AuthContext);
  const { isPublicMessageOpen, setIsPublicMessageOpen } =
    useContext(GlobalContext);

  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [individualSelection, setIndividualSelection] = useState({});
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectMsgId, setSlectMsgId] = useState(null);
  const [closeMsgOverlay, setCloseMsgOverlay] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);

  const { mutate, isError } = useMarkTicketAsSolved();
  const deletePublicMessagesMutation = useDeletePublicMessages();

  const clampWords = (text, maxWords) => {
    const words = text.split(" ");
    const clampedWords = words.slice(0, maxWords);
    return clampedWords.join(" ");
  };

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "publicMessages",
    async ({ pageParam = 1 }) => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/publicmsgs/getall-public-messages?page=${pageParam}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch public messages");
      }

      return response.json();
    },
    {
      getNextPageParam: (lastPage) => {
        const currentPage = Number(lastPage?.currentPage);
        const totalPages = lastPage?.totalPages;

        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
    }
  );

  const allMessages =
    data?.pages?.flatMap((page) => page.publicMessages || []) || [];

  const observerRef = useRef();
  const lastMessageRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleCheckboxChange = (messageId) => {
    setIndividualSelection((prevSelection) => ({
      ...prevSelection,
      [messageId]: !prevSelection[messageId],
    }));
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedMessageIds((prevSelected) =>
      !selectAll ? allMessages.map((msg) => msg._id) : []
    );
    setIndividualSelection({});
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return `Error: ${error.message}`;
  }

  const handleMessageClick = (messageId) => {
    const selectedIndex = allMessages.findIndex(
      (message) => message._id === messageId
    );

    if (selectedIndex === -1) return;

    setSelectedMessageIndex(selectedIndex);
    setIsPublicMessageOpen(true);
  };

  const handleDeleteSelectedMessages = () => {
    const selectedIds = Object.keys(individualSelection).filter(
      (id) => individualSelection[id]
    );
    if (selectedMessageIds.length > 0 || selectedIds.length > 0) {
      setDeleteOverlay(true);
    }
  };

  const confirmDelete = async () => {
    try {
      const idsToDelete =
        selectedMessageIds.length > 0
          ? selectedMessageIds
          : Object.keys(individualSelection).filter(
              (id) => individualSelection[id]
            );

      await deletePublicMessagesMutation.mutateAsync(idsToDelete);
      setDeleteOverlay(false);
    } catch (error) {
      console.error("Error deleting messages:", error);
    }
  };

  const handleMarkAsSolved = () => {
    setCloseLoading(true);
    mutate(selectMsgId, {
      onSuccess: () => {
        queryClient.setQueryData("publicMessages", (oldData) => {
          if (!oldData) return oldData;

          const updatedMessages = oldData.publicMessages.map((message) => {
            if (message.tickets[0]?._id === selectMsgId) {
              return {
                ...message,
                tickets: [
                  {
                    ...message.tickets[0],
                    status: "Solved",
                  },
                ],
              };
            }
            return message;
          });

          return {
            ...oldData,
            publicMessages: updatedMessages,
          };
        });

        setCloseMsgOverlay(false);
        setCloseLoading(false);
      },
      onError: (error) => {
        console.error("Error marking message as solved:", error);
        setCloseLoading(false);
        setCloseMsgOverlay(false);
      },
    });
  };

  return (
    <Fragment>
      {closeMsgOverlay && (
        <div className="closemsg-overlay">
          <div className="card">
            <p>Are you sure you want mark this message as Closed?</p>
            <div className="btn">
              <button
                onClick={() => {
                  setCloseMsgOverlay(false);
                  setSlectMsgId(null);
                }}
              >
                No
              </button>
              <button onClick={handleMarkAsSolved} disabled={closeLoading}>
                {closeLoading ? "Solving..." : "Yes"}
              </button>
              {isError && (
                <p style={{ fontFamily: "Montserrat" }}>
                  Error occurred while marking as solved: {isError.message}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Delete Overlay */}
      {deleteOverlay && (
        <div className="delete-overlay">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete the selected message(s)?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button
                onClick={confirmDelete}
                disabled={deletePublicMessagesMutation.isLoading}
              >
                {deletePublicMessagesMutation.isLoading ? "Deleting..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}

      {isPublicMessageOpen &&
      selectedMessageIndex !== null &&
      allMessages[selectedMessageIndex] ? (
        <div className="overlay-msg">
          <div className="card">
            <div className="header">
              <span>
                <h3>
                  {allMessages[selectedMessageIndex]?.fullname || "Unknown"}
                </h3>
                <p>
                  {allMessages[selectedMessageIndex]?.email ||
                    "No email provided"}
                </p>
              </span>
              <p>
                {allMessages[selectedMessageIndex]?.createdAt
                  ? new Intl.DateTimeFormat("en-US", {
                      timeZone: "Africa/Lagos",
                    }).format(
                      new Date(allMessages[selectedMessageIndex].createdAt)
                    )
                  : "No date available"}
              </p>
            </div>
            <div className="content-box">
              <p>
                {allMessages[selectedMessageIndex]?.content ||
                  "No content available"}
              </p>
            </div>
            <div className="btn">
              <button onClick={() => setIsPublicMessageOpen(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/* Main Table */}
      <div className="public-msg-table">
        <div className="header">
          <span>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <p>Select All</p>
          </span>
          <span>
            <FaTrash onClick={handleDeleteSelectedMessages} className="icon" />
          </span>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Content</th>
              <th>Created</th>
              <th>Ticket No</th>
              <th>Status</th>
              <th>Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allMessages.map((message, index) => (
              <tr
                key={message._id}
                ref={index === allMessages.length - 1 ? lastMessageRef : null}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={selectAll || individualSelection[message._id]}
                    onChange={() => handleCheckboxChange(message._id)}
                  />
                </td>
                <td>{message.fullname}</td>
                <td>{message.email}</td>
                <td>{message.phoneNumber}</td>
                <td>{clampWords(message.content, 2)}</td>
                <td>{new Date(message.createdAt).toLocaleDateString()}</td>
                <td>{message.tickets[0]?.ticketCode}</td>
                <td>{message.tickets[0]?.status}</td>
                <td>
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMessageClick(message._id);
                    }}
                  >
                    View Details
                  </Link>
                </td>
                <td>
                  {message.tickets[0]?.status === "closed" ? (
                    <button disabled>Solved</button>
                  ) : (
                    <button
                      onClick={() => {
                        setSlectMsgId(message.tickets[0]._id);
                        setCloseMsgOverlay(true);
                      }}
                    >
                      Mark As Solved
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isFetchingNextPage && <Spinner />}
    </Fragment>
  );
};

export default PublicMessage;
