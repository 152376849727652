import { useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../Context/AuthContext';

const useShippingCost = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const fetchShippingCost = async ({ sender_state, receiver_state, items, isPickupRequired,isExpress }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/shipping/calculate/inter/state/cost`,
        {
          sender_state,
          receiver_state,
          items,
          isExpress,
          isPickupRequired
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        'We are not shipping to that state combination yet. Please try again later.'
      );
    }
  };

  const mutation = useMutation(fetchShippingCost, {
    onSuccess: () => {
      queryClient.invalidateQueries('shippingCost');
    },
  });

  return { mutation };
};

export default useShippingCost;
