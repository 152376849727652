import { useQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

const fetchPackageStatus = async (userId, token) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/businesspkgs/package-status/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    }
  );
  return response.data.data; 
};

const usePackageStatus = (userId) => {
  const { token } = useContext(AuthContext); 

  return useQuery(
    ["packageStatus", userId], 
    () => fetchPackageStatus(userId, token), 
    {
      enabled: !!userId, 
    }
  );
};

export default usePackageStatus;
