import React, { useRef, useCallback, useState } from "react";
import useFetchAllUsers from "../libs/fetchAllUsers";
import useBlockUserById from "../libs/blockUserById";
import useUnblockUserById from "../libs/unblockUserById";
import useDeactivateAccount from "../libs/deactivateAccount";
import { ReactComponent as RemoveIcon } from "../../asset/gg_remove.svg";
import { ReactComponent as TrashIcon } from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { GoPlusCircle } from "react-icons/go";
import Spinner from "../../utils/spinner";
import "./allusers.css";

const AllUsers = () => {
  const blockUser = useBlockUserById();
  const unblockUser = useUnblockUserById();
  const deactivateAccount = useDeactivateAccount();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
  } = useFetchAllUsers();

  const [searchQuery, setSearchQuery] = useState("");
  const [blockOverlay, setBlockOverlay] = useState(false);
  const [unblockOverlay, setUnblockedOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const observerRef = useRef();
  const lastUsersRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  // const selectedUser = data?.users?.find((user) => user._id === selectedUserId);

  const filteredUsers = data?.pages.flatMap((page) =>
    page.users.filter((user) =>
      [
        user.first_name,
        user.last_name,
        user.email,
        user.phone_number,
        user.role,
      ]
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
  );

  const handleBlockUser = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await blockUser.mutateAsync({
        userId: selectedUserId,
        blockData: { startDate, endDate },
      });
      setBlockOverlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to block user:", error.message);
    }
  };

  const handleUnblockedUser = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await unblockUser.mutateAsync({ userId: selectedUserId });
      setUnblockedOverlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to unblock user:", error.message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      if (!selectedUserId) {
        console.error("User ID not available.");
        return;
      }

      await deactivateAccount.mutateAsync(selectedUserId);
      setDeleteOverlay(false);
      setSelectedUserId(null);
    } catch (error) {
      console.error("Failed to deactivate account:", error.message);
    }
  };

  return (
    <>
      {deleteOverlay && (
        <div className="user-delete-over-container">
          <div className="delete-card">
            <CautionIcon />
            <span>Delete</span>
            <p>
              Are you sure you want to delete this account? This action cannot
              be undone?
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>Cancel</button>
              <button onClick={handleDeactivateAccount}>
                {deactivateAccount.isLoading ? "Deactivating..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
      {unblockOverlay && (
        <div className="user-unblock-over-container">
          <div className="inner">
            <p>Are you sure you want to lift the suspension on this user?</p>
            <div className="btn">
              <button onClick={() => setUnblockedOverlay(false)}>No</button>
              <button
                type="button"
                disabled={unblockUser.isLoading}
                onClick={handleUnblockedUser}
              >
                {unblockUser.isLoading ? "Unblocking..." : "Confirm"}
              </button>
            </div>
            {unblockUser.isError && (
              <p className="error-message">
                Error: {unblockUser.error.message}
              </p>
            )}
          </div>
        </div>
      )}
      {blockOverlay && (
        <div className="user-block-over-container">
          <form>
            <p>Are you sure you want to suspend this officer?</p>
            <label>
              Start Date
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </label>
            <label>
              End Date
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </label>
            <div className="btn">
              <button onClick={() => setBlockOverlay(false)}>Cancel</button>
              <button
                type="button"
                onClick={handleBlockUser}
                disabled={blockUser.isLoading}
              >
                {blockUser.isLoading ? "Blocking..." : "Confirm"}
              </button>
            </div>
            {blockUser.isError && (
              <p className="error-message">Error: {blockUser.error.message}</p>
            )}
          </form>
        </div>
      )}

      <div className="all-users-container">
        <div className="header">
          <h1>All Users</h1>
          <input
            type="search"
            placeholder="Search by name, role, email, or phone..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {isLoading && <Spinner />}
        {isError && (
          <div className="error">
            Error fetching users. Please try again later.
          </div>
        )}

        {!isLoading && !isError && (
          <table className="user-table">
            <thead>
              <tr>
                <th>First</th>
                <th>Last</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Branch</th>
                <th>Role</th>
                <th>Status</th>
                <th>Blocked</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.length > 0 ? (
                filteredUsers.map((user, index) => (
                  <tr
                    key={user._id}
                    ref={
                      index === filteredUsers.length - 1 ? lastUsersRef : null
                    }
                  >
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone_number || "NA"}</td>
                    <td>{user.address || "NA"}</td>
                    <td>{user.branch_full_address || "NA"}</td>
                    <td>{user.role}</td>
                    <td>{user.isActive ? "Active" : "Inactive"}</td>
                    <td>{user.blocked ? "Blocked" : "Active"}</td>
                    <td>
                      <div className="action">
                        {user?.blocked ? (
                          <GoPlusCircle
                            onClick={() => {
                              setUnblockedOverlay(true);
                              setSelectedUserId(user._id);
                            }}
                          />
                        ) : (
                          <RemoveIcon
                            onClick={() => {
                              setBlockOverlay(true);
                              setSelectedUserId(user._id);
                            }}
                          />
                        )}
                        <TrashIcon
                          onClick={() => {
                            setDeleteOverlay(true);
                            setSelectedUserId(user._id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="no-data">
                    No users found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {isFetchingNextPage && <Spinner />}
      </div>
    </>
  );
};

export default AllUsers;
