import React from 'react';
import { ReactComponent as FrameIcon } from "../../asset/Frame 100.svg";
import useBusinessSenderFormData from '../lib/useBusinessSender';
import useBusinessStatesAndCities from '../../super_admin/libs/fetchAdminState';
import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import "./Business_package_creation.css";

export default function Businesssenderdetails() {
  const navigate = useNavigate();
  const [formData, handleChange] = useBusinessSenderFormData("businessSenderForm");
  const { data: statesAndCities, isLoading: isFetchingStates } = useBusinessStatesAndCities();

  const getCitiesForState = (stateId) => {
    const selectedState = statesAndCities?.states.find((state) => state._id === stateId);
    return selectedState?.cities || [];
  };

  const getStateNameById = (stateId) => {
    const state = statesAndCities?.states.find((state) => state._id === stateId);
    return state?.name || "";
  };

  const getCityNameById = (stateId, cityId) => {
    const city = getCitiesForState(stateId).find((city) => city._id === cityId);
    return city?.name || "";
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    
    const senderStateName = getStateNameById(formData.senderState);
    const senderCityName = getCityNameById(formData.senderState, formData.senderCity);
  
  
    const updatedFormData = {
      ...formData,
      senderState: senderStateName,  // Store state name, not the ID
      senderCity: senderCityName,    // Store city name, not the ID
    };
  
    
    // Save form data to localStorage
    localStorage.setItem("businessSenderForm", JSON.stringify(updatedFormData));

    // Navigate to the next page
    navigate('/businessreceiverdetails', {state: updatedFormData});
  };

  return (
    <div className='business-package-creation'>
      <div className="header">
        <span onClick={() => navigate('/businesspackageshipment')}>
          <IoArrowBackSharp style={{ fontSize: '18px', cursor:"pointer" }} />
          <p>National Delivery</p>
        </span>
      </div>
      <div className="ship-form">
        <FrameIcon />
        <form onSubmit={handleSubmit}>
          <p>Where are you shipping from?</p>
          <div className="inner">
            <label>
              Full Name or Company Name*
              <input
                type="text"
                placeholder="Enter name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              Contact Name
              <input
                type="text"
                placeholder="Enter name"
                name="contact_name"
                value={formData.contact_name}
                onChange={handleChange}
              />
            </label>

            <label>
              Address Line 1*
              <textarea
                placeholder="Enter address 1"
                name="address_1"
                value={formData.address_1}
                onChange={handleChange}
                required
              ></textarea>
            </label>

            <label>
              Address Line 2
              <textarea
                placeholder="Enter address 2"
                name="address_2"
                value={formData.address_2}
                onChange={handleChange}
              ></textarea>
            </label>

            <label>
              Email Address*
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              Phone Number*
              <div className="num">
                <select name="country_code" onChange={handleChange} value={formData.country_code} required>
                  <option value="+234">+234</option>
                  <option value="+1">+1</option>
                </select>
                <input
                  type="tel"
                  placeholder="Phone Number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                />
              </div>
            </label>

            
            <label>
                Sender State*
                <select
                  name="senderState"
                  value={formData.senderState}
                  onChange={handleChange}
                  disabled={isFetchingStates}
                  required
                >
                  <option value="">Select Origin State</option>
                  {statesAndCities?.states.map((state) => (
                    <option key={state._id} value={state._id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </label>
  
              <label>
                Sender City*
                <select
                  name="senderCity"
                  value={formData.senderCity}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Origin City</option>
                  {getCitiesForState(formData.senderState).map((city) => (
                    <option key={city._id} value={city._id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </label>

            <label>
              Zip Code*
              <input
                type="number"
                placeholder="Zip code"
                name="zip_code"
                value={formData.zip_code}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <button type="submit">Proceed</button>
        </form>
      </div>
    </div>
  );
}
