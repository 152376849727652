import useFetchRiderAssignmentById from "../Libs/useFetchRiderAssignmentById";
import Spinner from "../../utils/spinner";
import { Link } from "react-router-dom";

function Rideraccepted() {
  const { data: assignmentData, isLoading, isError, error } = useFetchRiderAssignmentById();

  const filteredAssignments = assignmentData?.riderAssignments
    ?.filter((assignment) => assignment?.accept?.length > 0) 
    ?.reverse() || [];

  return (
    <div className="accepted-assignment">
      {isLoading && <Spinner />}
      {isError && <p style={{ fontFamily: 'Montserrat' }}>Error: {error.message}</p>}
      <div className="card">
        {!isLoading && !isError && (
          <>
            {filteredAssignments.length === 0 ? (
              <p style={{ fontFamily: 'Montserrat' }}>No matching assignments found.</p>
            ) : (
              filteredAssignments.map((assignment) => (
                <Link key={assignment._id} className="accepted" to={`details/${assignment._id}`}>
                  <h3>ID {assignment._id.slice(0, 8).toUpperCase()}</h3>
                  <h2>Receiver: {assignment.package?.[0]?.receiver?.name || "N/A"}</h2>
                  <span>{assignment.package[0]?.items?.map((item) => (
                            <div key={item?._id}>
                              {item?.name} - {item?.quantity}
                            </div>
                          )) || "N/A"}</span>
                  <p>
                    {`${assignment.package?.[0]?.receiver?.state || "Unknown"}, 
                    ${assignment.package?.[0]?.receiver?.address_1 || "Unknown Address"}`}
                  </p>
                  <h3>Receiver Phone Number: {assignment.package?.[0]?.receiver?.phone_number || "N/A"}</h3>
                  <h3>Receiver Secret Pin: {assignment.package?.[0]?.secretPin || "N/A"}</h3>
                </Link>
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Rideraccepted;


