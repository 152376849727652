import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useQueryClient } from "react-query";

const useUpdateUserById = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const updateUserById = async (userId, userData) => {
    console.log("Updating user with data:", userData);
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/update/${userId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      }
    );

    if (!response.ok) {
      throw new Error("Error updating user");
    }

    queryClient.invalidateQueries("businessUsers");
    return response.json();
  };

  return { updateUserById };
};

export default useUpdateUserById;
