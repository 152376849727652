import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";

const raiseConcern = async (concernData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/raise/create/concerns`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(concernData),
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to raise concern");
  }

  return response.json();
};

const useRaiseConcern = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((concernData) => raiseConcern(concernData, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("concerns");
    },
  });
};

export default useRaiseConcern;
