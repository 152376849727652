
import { useState } from 'react';

const useRegisterBusiness = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const registerBusiness = async (formData) => {
        setIsLoading(true);
        setIsError(null);
        setSuccessMessage(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/register/business`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Business registration failed');
            }

            setSuccessMessage('Business registered successfully');

            setTimeout(() => {
                setSuccessMessage(null);
            }, 3000);

            return data;
        } catch (err) {
            setIsError(err.message);

            setTimeout(() => {
                setIsError(null);
            }, 3000);
        } finally {
            setIsLoading(false);
        }
    };

    return { registerBusiness, isLoading, isError, successMessage };
};

export default useRegisterBusiness;
