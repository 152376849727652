import { useMutation, useQueryClient } from 'react-query';
import { AuthContext } from '../../Context/AuthContext';
import { useContext } from 'react';

const blockUserById = async (userId, blockData, token) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users/block/${userId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(blockData),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

const useBlockUserById = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation(({ userId, blockData }) => blockUserById(userId, blockData, token), {
    onSuccess: () => {
        queryClient.invalidateQueries('logisticsOfficers');
        queryClient.invalidateQueries('drivers');
        queryClient.invalidateQueries('riders');
        queryClient.invalidateQueries('marketplaceManagers');
        queryClient.invalidateQueries('supports');
        queryClient.invalidateQueries('chartererOfficers');
        queryClient.invalidateQueries('freightOfficers');
        queryClient.invalidateQueries('allUsers');
        queryClient.invalidateQueries('businessUsers');
    },
  });
};

export default useBlockUserById;
