import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";

const updateBusinessCategory = async (categoryId, categoryData, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/categories/updatecategory/${categoryId}`,
    {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(categoryData),
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || "Failed to update business category");
  }

  return response.json();
};

const useUpdateBusinessCategory = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation(({ categoryId, categoryData }) => updateBusinessCategory(categoryId, categoryData, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("businessCategories");
    },
  });
};

export default useUpdateBusinessCategory;
