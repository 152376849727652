// import { ReactComponent as FrameIcon } from "../../asset/Frame 100.svg";
// import useFormDataReceiver from "../libs/receiver";
// import useAllStates from "../../libs/getAllStates";
// import { useNavigate } from "react-router-dom";

// function Logisticsreceiever() {
//     const navigate = useNavigate();
//   const [formData] = useFormDataReceiver("shipFormData");
//   const [receiverFormData, handleChanges] = useFormDataReceiver("recieverFormData");
//   const { data: allStates } = useAllStates();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form Data:", formData);
//     navigate("/Logisticsitems");
//   };
//   return (
//     <div className="logistics-sender">
//       <div className="header">
//         <p>Create Package</p>
//       </div>
//       <div className="ship-form">
//         <FrameIcon />
//         <form onSubmit={handleSubmit}>
//           <p>where are you shipping to</p>
//           <div className="inner">
//             <label>
//               Full Name Or Company Name*
//               <input
//                 type="text"
//                 placeholder="Enter name"
//                 name="name"
//                 value={receiverFormData.name}
//                 onChange={handleChanges}
//                 required
//               />
//             </label>

//             <label>
//               Contact Name
//               <input
//                 type="text"
//                 placeholder="Enter name"
//                 name="contact_name"
//                 value={receiverFormData.contact_name}
//                 onChange={handleChanges}
//               />
//             </label>

//             <label>
//               Address Line 1*
//               <textarea
//                 placeholder="Enter address 1"
//                 name="address_1"
//                 value={receiverFormData.address_1}
//                 onChange={handleChanges}
//                 required
//               ></textarea>
//             </label>

//             <label>
//               Address Line 2*
//               <textarea
//                 placeholder="Enter address 2"
//                 name="address_2"
//                 value={receiverFormData.address_2}
//                 onChange={handleChanges}
//                 required
//               ></textarea>
//             </label>

//             <label>
//               Email Address*
//               <input
//                 type="text"
//                 placeholder="Email"
//                 name="email"
//                 value={receiverFormData.email}
//                 onChange={handleChanges}
//                 required
//               />
//             </label>

//             <label>
//               Phone Number*
//               <div className="num">
//                 <select name="" id="">
//                   <option value="+234">+234</option>
//                   <option value="+1">+1</option>
//                 </select>
//                 <input
//                   type="number"
//                   placeholder="Phone Number"
//                   name="phone_number"
//                   onChange={handleChanges}
//                   value={receiverFormData.phone_number}
//                 />
//               </div>
//             </label>

//             <label>
//               State*
//                 <select
//                 name="state"
//                 value={receiverFormData.state}
//                 onChange={handleChanges}
//                 required
//               >
//                 <option value="">Select State</option>
//                 {allStates &&
//                   allStates.map((state) => (
//                     <option key={state._id} value={state.state}>
//                       {state.state}
//                     </option>
//                   ))}
//               </select>
//             </label>

//             <label>
//               Zip Code
//               <input
//                 type="number"
//                 placeholder="Zip code"
//                 name="zip_code"
//                 value={receiverFormData.zip_code}
//                 onChange={handleChanges}
//                 required
//               />
//             </label>
//           </div>
//           <div className="btn">
//             <button onClick={() => navigate("/Logiscticsender")}>Back</button>
//           <button>Proceed</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   )
// }

// export default Logisticsreceiever



import { ReactComponent as FrameIcon } from "../../asset/Frame 100.svg";
import useFormDataReceiver from "../libs/receiver";
import useManagerStatesAndCities from "../libs/fetchManagerStateCities";
import { useNavigate } from "react-router-dom";

function Logisticsreceiever() {
  const navigate = useNavigate();
  const [receiverFormData, handleChanges] =
    useFormDataReceiver("recieverFormData");
  const { data: statesAndCities, isLoading: isFetchingStates } =
    useManagerStatesAndCities();

  const getStateNameById = (stateId) => {
    const state = statesAndCities?.states.find(
      (state) => state._id === stateId
    );
    return state?.name || "";
  };

  const getCityNameById = (stateId, cityId) => {
    const city = getCitiesForState(stateId).find((city) => city._id === cityId);
    return city?.name || "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Get the selected state and city names
    const receiverStateName = getStateNameById(receiverFormData.receiverState);
    const receiverCityName = getCityNameById(
      receiverFormData.receiverState,
      receiverFormData.receiverCity
    );

    // Update the form data with state and city names instead of IDs
    const updatedFormData = {
      ...receiverFormData,
      receiverState: receiverStateName,  // Store state name, not the ID
      receiverCity: receiverCityName,    // Store city name, not the ID
    };

    // Store the entire updated form data object in localStorage
    localStorage.setItem("recieverFormData", JSON.stringify(updatedFormData));

    // Log the updated form data to verify it's correct
    console.log("Receiver FormData:", updatedFormData);

    // Pass updated form data to the next page
    navigate("/Logisticsitems", { state: updatedFormData });
  };

  const getCitiesForState = (stateId) => {
    const selectedState = statesAndCities?.states.find(
      (state) => state._id === stateId
    );
    return selectedState?.cities || [];
  };

  return (
    <div className="logistics-sender">
      <div className="header">
        <p>Create Package</p>
      </div>
      <div className="ship-form">
        <FrameIcon />
        <form onSubmit={handleSubmit}>
          <p>Where are you shipping to?</p>
          <div className="inner">
            <label>
              Full Name Or Company Name*
              <input
                type="text"
                placeholder="Enter name"
                name="name"
                value={receiverFormData.name}
                onChange={handleChanges}
                required
              />
            </label>

            <label>
              Contact Name
              <input
                type="text"
                placeholder="Enter name"
                name="contact_name"
                value={receiverFormData.contact_name}
                onChange={handleChanges}
              />
            </label>

            <label>
              Address Line 1*
              <textarea
                placeholder="Enter address 1"
                name="address_1"
                value={receiverFormData.address_1}
                onChange={handleChanges}
                required
              ></textarea>
            </label>

            <label>
              Address Line 2*
              <textarea
                placeholder="Enter address 2"
                name="address_2"
                value={receiverFormData.address_2}
                onChange={handleChanges}
                required
              ></textarea>
            </label>

            <label>
              Email Address*
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={receiverFormData.email}
                onChange={handleChanges}
                required
              />
            </label>

            <label>
              Phone Number*
              <div className="num">
                <select name="" id="">
                  <option value="+234">+234</option>
                  <option value="+1">+1</option>
                </select>
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="phone_number"
                  onChange={handleChanges}
                  value={receiverFormData.phone_number}
                />
              </div>
            </label>

            <label>
              Receiver State*
              <select
                name="receiverState"
                value={receiverFormData.receiverState}
                onChange={handleChanges}
                disabled={isFetchingStates}
              >
                <option value="">Select Destination State</option>
                {statesAndCities?.states.map((state) => (
                  <option key={state._id} value={state._id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Receiver City*
              <select
                name="receiverCity"
                value={receiverFormData.receiverCity}
                onChange={handleChanges}
                disabled={!receiverFormData.receiverState}
              >
                <option value="">Select Destination City</option>
                {getCitiesForState(receiverFormData.receiverState).map(
                  (city) => (
                    <option key={city._id} value={city._id}>
                      {city.name}
                    </option>
                  )
                )}
              </select>
            </label>

            <label>
              Zip Code
              <input
                type="number"
                placeholder="Zip code"
                name="zip_code"
                value={receiverFormData.zip_code}
                onChange={handleChanges}
                required
              />
            </label>
          </div>
          <div className="btn">
            <button onClick={() => navigate("/Logisticsender")}>Back</button>
            <button>Proceed</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Logisticsreceiever;
