import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";

const fetchFundingHistory = async ({ pageParam = 1, userId, token }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/payments/wallet/funding-history/${userId}?page=${pageParam}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const useWalletFundingHistory = (userId) => {
  const { token } = useContext(AuthContext);

  return useInfiniteQuery(
    ["fundingHistory", userId],
    ({ pageParam = 1 }) => fetchFundingHistory({ pageParam, userId, token }),
    {
      enabled: !!userId,
      getNextPageParam: (lastPage) => {
        if (lastPage.currentPage < Math.ceil(lastPage.totalFundings / 10)) {
          return lastPage.currentPage + 1;
        }
        return undefined;
      },
    }
  );
};

export default useWalletFundingHistory;
