import React, { useState, useEffect, useRef, useCallback , Fragment} from 'react';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as SearchIcon} from "../../asset/iconamoon_search-light.svg";
import {ReactComponent as TrashIcon} from "../../asset/basil_trash-outline.svg";
import { ReactComponent as CautionIcon } from "../../asset/caution-svgrepo-com 1.svg";
import { CiEdit } from "react-icons/ci";
import useFetchShippingCost from '../libs/fetchShippingCost';
import useDeleteShipCost from '../libs/useDeleteShipCost';
import useUpdateShippingCostById from '../libs/useUpdateShipCostById';
import Spinner from '../../utils/spinner';
import "./SuperadminshippingCost.css";

const Superadminshippingcost = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCostId, setSelectedCostId] = useState(null);
    const [deleteOverlay, setDeleteOverlay] = useState(false);
    const [editOverlay, setEditOverlay] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const {
        data, 
        isLoading, 
        isError, 
        fetchNextPage, 
        isFetchingNextPage, 
        hasNextPage 
    } = useFetchShippingCost();

    const updateShippingCost = useUpdateShippingCostById();
    const deleteShippingCostMutation = useDeleteShipCost();

    const [costData, setCostData] = useState({
        sender_state: "",
        receiver_state: "",
        normalBaseCost: "",
        pickupBaseCost: "",
        itemsValuePercentage: "",
        pickupAdditionalCostPerKg: "",
        additionalCostPerKg: "",
        expressCost: ""
      });


  const handleInputChange = (e) => {
    setCostData({
      ...costData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!isLoading && data && selectedCostId) {
      const allShippingCost = data?.pages?.flatMap((page) => page.shippingCost) || [];
  
      const selectedShippingCost = allShippingCost.find(
        (shippingCost) => shippingCost?._id === selectedCostId
      );
  
      if (selectedShippingCost) {
        console.log('Selected Shipping Cost:', selectedShippingCost);
        setCostData({
          sender_state: selectedShippingCost.sender_state || "",
          receiver_state: selectedShippingCost.receiver_state || "",
          normalBaseCost: selectedShippingCost.normalBaseCost || "",
          pickupCost: selectedShippingCost.pickupCost || "",
          expressCost: selectedShippingCost.expressCost|| "",
          itemsValuePercentage: selectedShippingCost.itemsValuePercentage || "",
          additionalCostPerKg: selectedShippingCost.additionalCostPerKg || "",

        });
      }
    }
  }, [selectedCostId, isLoading, data]);
  
  const handleEditClick = (shippingCost) => {
    if (shippingCost) {
      setSelectedCostId(shippingCost._id);
      setCostData({
        sender_state:shippingCost.sender_state || "",
        receiver_state:shippingCost.receiver_state || "",
        normalBaseCost:shippingCost.normalBaseCost || "",
        pickupCost:shippingCost.pickupCost || "",
        expressCost:shippingCost.expressCost || "",
        itemsValuePercentage:shippingCost.itemsValuePercentage || "",
        additionalCostPerKg:shippingCost.additionalCostPerKg || "",
      });
      setEditOverlay(true);
    }
  };

  const handleCancelEdit = () => {
    setSelectedCostId(null);
    setEditOverlay(false);
  };

  const handleUpdateShippingCost = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      if (selectedCostId) {
        await updateShippingCost.updateShippingCostById(selectedCostId, costData);
      }
      setEditOverlay(false);
    } catch (error) {
      console.error("Failed to update shipping:", error.message);
    } finally {
      setIsUpdating(false);
    }
  };
  
  const handleDeleteShippingCost = async () => {
    try {
      setLoading(true)
      if (!selectedCostId) {
        console.error("Cost ID not available.");
        return;
      }
      await deleteShippingCostMutation.deleteShipCost(selectedCostId);
      setDeleteOverlay(false);
      setSelectedCostId(null);
      setLoading(false)
    } catch (error) {
      console.error("Failed to delete shipping cost:", error.message);
    }
  };
  
    const observerRef = useRef();
    const lastShippingCostRef = useCallback(
        (node) => {
            if (isFetchingNextPage) return;
            if (observerRef.current) observerRef.current.disconnect();

            observerRef.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });

            if (node) observerRef.current.observe(node);
        },
        [isFetchingNextPage, fetchNextPage, hasNextPage]
    );

    const shippingCostArray = data?.pages?.flatMap(page => page.shippingCosts) || [];


    const filteredShippingCost = shippingCostArray.filter(
      (shippingCost) =>
          shippingCost.sender_state?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          shippingCost.receiver_state?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          shippingCost.sender_city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          shippingCost.receiver_city?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  


    return (
        <Fragment>
             {editOverlay && (
        <div className="shipping-cost-edits-over-container">
          <form className="card">
            <label>
             Sender State
              <input
                type="text"
                name="sender_state"
                value={costData.sender_state}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Receiver State
              <input
                type="text"
                name="receiver_state"
                value={costData.receiver_state}
                onChange={handleInputChange}
              />
            </label>
            <label>
            Normal Base Cost
              <input
                type="tel"
                name="normalBaseCost"
                value={costData.normalBaseCost}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Pick Up Cost
              <input
                type="tel"
                name="pickupCost"
                value={costData.pickupCost}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Express Cost
              <input
                type="tel"
                name="expressCost"
                value={costData.expressCost}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Additional Cost Per KG
              <input
                type="tel"
                name="additionalCostPerKg"
                value={costData.additionalCostPerKg}
                onChange={handleInputChange}
              />
            </label>
            <label>
             Item Value %
              <input
                type="tel"
                name="itemsValuePercentage"
                value={costData.itemsValuePercentage}
                onChange={handleInputChange}
              />
            </label>
        
            <div className="btn">
              <button onClick={handleCancelEdit}>Cancel</button>
              <button onClick={handleUpdateShippingCost}>
                {isUpdating ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      )}
       {deleteOverlay && (
        <div className="shipping-cost-delete-over-container">
          <div className="delete-card">
            <CautionIcon />
            <span>Delete</span>
            <p>
              Are you sure you want to delete this shipping cost? This action cannot
              be undone?
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>Cancel</button>
              <button onClick={handleDeleteShippingCost}>
                {loading
                  ? "Deleting..."
                  : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
        <div className='all-shipping-cost'>
            <div className="header">
                <p>National Shipping Cost</p>
                <div className="in">
                    <span>
                        <SearchIcon />
                        <input 
                            type="search" 
                            placeholder='search cost by state, city' 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} 
                            />
                    </span>
                    <div className="btn">
                        <button onClick={() => navigate('/superadmingetquote')}>Get Quote</button>
                        <button onClick={() => navigate('/superadmincreateshipcost')}>Create Cost</button>
                    </div>
                </div>
            </div>

            <div className="shipping-cost-table">
            {isLoading ?(
              <Spinner />
            ) : isError ? (
              <p style={{fontFamily:'Montserrat'}}>No shipping cost found.</p>
            ) :(
                <table>
                    <thead>
                        <tr>
                            <th>Sender State</th>
                            <th>Receiver State</th>
                            <th>Normal Base cost</th>
                            <th>Pick Up cost</th>
                            <th>Additional Cost/kg</th>
                            <th>Express Cost</th>
                            <th>Item Value %</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredShippingCost.map((shippingCost, index) => (
                            <tr key={shippingCost._id} ref={index === filteredShippingCost.length - 1 ? lastShippingCostRef : null}>
                                <td>{shippingCost.sender_state}</td>
                                <td>{shippingCost.receiver_state}</td>
                                <td>{shippingCost.normalBaseCost}</td>
                                <td>{shippingCost.pickupCost}</td>
                                <td>{shippingCost.additionalCostPerKg}</td>
                                <td>{shippingCost.expressCost}</td>
                                <td>{shippingCost.itemsValuePercentage}</td>
                                <td>
                                    <div className="action">
                                        <CiEdit onClick={() => handleEditClick(shippingCost)} />
                                        <TrashIcon
                              onClick={() => {
                                setDeleteOverlay(true);
                                setSelectedCostId(shippingCost._id);
                              }}
                            />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            </div>
            {isFetchingNextPage && <Spinner />}
        </div>
     </Fragment>
    );
}

export default Superadminshippingcost;
