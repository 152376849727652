


import React, { Fragment, useState } from "react";
import useFetchRiderAssignmentById from "../../Driver_account/Libs/fetchRiderAssignmentById";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useUpdatePackageStatusToDelayed from "../Libs/useUpdatePackageStatusToDelayed";
import useUpdatePackageStatusToDiverted from "../Libs/useUpdatePackageStatusToDiverted";
import useUpdatePackageStatusToOutForDelivery from "../Libs/useUpdatePackageStatusToOutForDelivery";
import useUpdatePackageStatusToDelivered from "../Libs/useUpdatePackageStatusToDelivered";
import useUpdatePackageStatusToDeliveryFailed from "../Libs/useUpdatePackageToDeliveryFailed";
import Spinner from "../../utils/spinner";

function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
}

function Riderassigneddetails() {
  const { data, isLoading, isError, error } = useFetchRiderAssignmentById();
  const [updateOverlay, setUpdateOverlay] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [receivedBy, setReceivedBy] = useState(""); 
  const [reason, setFailedReason] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState(null);

  

  const {  updatePackageStatusToDelayed } = useUpdatePackageStatusToDelayed();
  const { updatePackageStatusToDiverted } = useUpdatePackageStatusToDiverted();
  const { updatePackageStatusToOutForDelivery } = useUpdatePackageStatusToOutForDelivery();
  const { updatePackageStatusToDelivered } = useUpdatePackageStatusToDelivered();
  const {updatePackageStatusToDeliveryFailed} = useUpdatePackageStatusToDeliveryFailed();

  const handleUpdate = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setErrors(null);
  
    try {
      const updateData = {
        state_id: selectedState,
        city: selectedCity,
      };
  
      if (selectedStatus === "Delivered") {
        updateData.receivedBy = receivedBy;
      }
      if (selectedStatus === "Delivery Failed") { 
        updateData.reason = reason;
      }
  
      if (selectedStatus === "Delayed") {
        await updatePackageStatusToDelayed(packageId, updateData);
      } else if (selectedStatus === "Diverted") {
        await updatePackageStatusToDiverted(packageId, updateData);
      } else if (selectedStatus === "Out For Delivery") {
        await updatePackageStatusToOutForDelivery(packageId, updateData);
      } else if (selectedStatus === "Delivered") {
        await updatePackageStatusToDelivered(packageId, updateData);
      } else if (selectedStatus === "Delivery Failed") {
        await updatePackageStatusToDeliveryFailed(packageId, updateData); 
      }
  
      setPackageId(null);
      setUpdateOverlay(false);
      setSelectedStatus("");
      setSelectedState("");
      setSelectedCity("");
    } catch (error) {
      setErrors(error.message);
    } finally {
      setUpdating(false);
    }
  };
  

  const navigate = useNavigate();

  const osunCities = [
    "Osogbo", "Ilesa", "Iwo", "Ede", "Ikirun", "Ejigbo", "Iragbiji",
    "Modakeke", "Ipetu-Ijesha", "Ifon-Osun", "Okuku", "Ila Orangun",
    "Erin-Osun", "Oke-Ila Orangun", "Iresi", "Otan-Ayegbaju", "Inisa",
    "Ibokun", "Orile-Owu", "Okinni", "Oyan", "Ife", "Igbajo", "Ada", "Ikire",
    "Ikoyi", "Apomu", "Erin-Oke", "Awo", "Eekosin", "Edun Abon", "Owode Ede", 
    "Sekona", "Ilobu", "Oba Oke", "Ile-Ogbo", "Iperindo", "Iba", "Osu", "Aisu",
    "Ode Omu", "Gbongan", "Orita Moro", "Otan Ile", "Agbeye",
  ];

  return (
    <Fragment>
      {updateOverlay && (
        <div className="updatepkg-over">
          <div className="card">
            <p>Trip Status</p>
            <form onSubmit={handleUpdate}>
              <select
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                  setSelectedState("");
                  setSelectedCity("");
                }}
              >
                <option>Select Status</option>
                <option value="Delayed">Delayed</option>
                <option value="Diverted">Diverted</option>
                <option value="Out For Delivery">Out For Delivery</option>
                <option value="Delivery Failed">Delivery Failed</option>
                <option value="Delivered">Delivered</option>
              </select>
              <>
                <select
                  value={selectedState}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    setSelectedCity("");
                  }}
                >
                  <option value="">Select  State</option>
                  <option value="Osun">Osun</option>
                </select>
                <select
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                disabled={!selectedState}
              >
                <option value="">Select City</option>
                {selectedState === "Osun" &&
                  osunCities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
              </select>
              </>

              {selectedStatus === "Delivered" && (
                <input
                  type="text"
                  placeholder="Received by"
                  value={receivedBy}
                  onChange={(e) => setReceivedBy(e.target.value)}
                />
              )}
{selectedStatus === "Delivery Failed" && (
  <select
    value={reason}
    onChange={(e) => setFailedReason(e.target.value)}
  >
    <option value="">Select Reason</option>
    <option value="Customer Unavailable">Customer Unavailable</option>
    <option value="Incorrect Address">Incorrect Address</option>
    <option value="Weather Conditions">Weather Conditions</option>
    <option value="Vehicle Breakdown">Motor Cycle Breakdown</option>
    <option value="Security Concerns">Security Concerns</option>
    <option value="Customer Refused Delivery">Customer Refused Delivery</option>
    <option value="Restricted Access to Delivery Location">Restricted Access to Delivery Location</option>
    <option value="Failed Contact Attempts">Failed Contact Attempts</option>
    <option value="Unclear Delivery Instructions">Unclear Delivery Instructions</option>
  </select>
)}


              <div className="btn">
                <button
                  onClick={() => {
                    setPackageId(null);
                    setUpdateOverlay(false);
                    setSelectedStatus("");
                    setSelectedState("");
                    setSelectedCity("");
                  }}
                >
                  Cancel
                </button>
                <button>
                  {updating ? "Updating..." : "Update"}
                </button>
              </div>
              {errors && <p>{errors}</p>}
            </form>
          </div>
        </div>
      )}

      <div className="assignment-details">
        {isLoading && <Spinner />}
        {isError && <p>Error: {error.message}</p>}
        {data && data.riderAssignment.accept && (
          <div className="ass-details">
            {data.riderAssignment.accept.map((acceptId) => (
              <div key={acceptId} className="card">
                <div className="header">
                  <span>
                    <FaLongArrowAltLeft onClick={() => navigate("/Rideraccepted")} />
                    <h2>ID{acceptId.slice(0, 8).toUpperCase()}</h2>
                  </span>
                  <button
                    onClick={() => {
                      setPackageId(data.riderAssignment.package[0]._id);
                      setUpdateOverlay(true);
                    }}
                  >
                    Update package
                  </button>
                </div>
                <div className="receiver-card">
                  <p>RECEIVERS’S INFORMATION</p>
                  <div className="receiver">
                    <span>
                      <p>Full Name</p>
                      <h3>
                        {data.riderAssignment.package[0]?.receiver?.name ||
                          "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Phone Number</p>
                      <h3>
                        {data.riderAssignment.package[0]?.receiver
                          ?.phone_number || "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Address</p>
                      <h3>
                        {data.riderAssignment.package[0]?.receiver
                          ?.address_1 || "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Zip Code</p>
                      <h3>
                        {data.riderAssignment.package[0]?.receiver?.zip_code ||
                          "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>State</p>
                      <h3>
                        {data.riderAssignment.package[0]?.receiver?.state ||
                          "N/A"}
                      </h3>
                    </span>
                  </div>
                  <p>PACKAGE INFORMATION</p>
                  <div className="package-items">
                    <span>
                      <p>Item(s) / Qt(s)</p>
                      {data.riderAssignment.package[0]?.items?.map((item) => (
                            <h3 key={item?._id}>
                              {item?.name} - {item?.quantity}
                            </h3>
                          )) || "N/A"}
                    </span>
                    <span>
                      <p>Shipping Fee</p>
                      <h3>
                        {data.riderAssignment.package[0]?.shipping_cost
                          ? formatCurrency(
                              data.riderAssignment.package[0]?.shipping_cost 
                            )
                          : "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Estimated Delivery Date</p>
                      <h3>
                        {data.riderAssignment.package[0]
                          ?.estimated_delivery_date
                          ? new Date(
                              data.riderAssignment.package[0]?.estimated_delivery_date
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : "N/A"}
                      </h3>
                    </span>
                    <span>
                      <p>Current Status</p>
                      <h3>
                        {data.riderAssignment.package[0]?.status.slice(-1)[0]?.status ||
                          "N/A"}
                      </h3>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Riderassigneddetails;
