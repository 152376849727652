import React, { useState, useRef, useCallback } from "react";
import { CiSearch } from "react-icons/ci";
import { ReactComponent as CautionIcon } from "../../Assets/caution-svgrepo-com 1.svg";
import { FaTrash } from "react-icons/fa";
import useFetchBusinessReceiptDetails from "../lib/fetchBusinessReceiptDetail";
import useBusinessPackageReceipt from "../lib/fetcBusinessReceipt";
import useDeleteBusinessPackageReceipt from "../lib/deleteBusinessReceipt";
import useDebounce from "../../Logistics_manager/libs/useDebounce";
import Spinner from "../../utils/spinner";
import "./Business_receipt.css";

export default function Businesspackagereceipt() {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useBusinessPackageReceipt();

  const { fetchBusinessReceiptDetails } = useFetchBusinessReceiptDetails();
  const deleteBusinessReceiptMutation = useDeleteBusinessPackageReceipt();

  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingReceiptId, setLoadingReceiptId] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [individualSelection, setIndividualSelection] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const logs = data?.pages.flatMap((page) => page.logs) || [];

  const filteredLogs = logs.filter((log) => {
    const packageName = log.response?.package?.tracking_number || "";
    return packageName
      .toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase());
  });

  const handleCheckboxChange = (packageId) => {
    setIndividualSelection((prevSelection) => {
      const newSelection = {
        ...prevSelection,
        [packageId]: !prevSelection[packageId],
      };
      return newSelection;
    });

    // Update "Select All" state
    const allChecked = filteredLogs.every(
      (log) => individualSelection[log.packageId]
    );
    setSelectAll(allChecked);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const newSelection = {};
    if (newSelectAll) {
      filteredLogs.forEach((log) => {
        newSelection[log.packageId] = true;
      });
    }
    setIndividualSelection(newSelection);
  };

  const handleDeleteSelectedReceipt = () => {
    const selectedIds = Object.keys(individualSelection).filter(
      (id) => individualSelection[id]
    );
    if (selectedIds.length > 0) {
      setDeleteOverlay(true);
    }
  };

  // const confirmDelete = async () => {
  //   try {
  //     const idsToDelete = Object.keys(individualSelection).filter(
  //       (id) => individualSelection[id]
  //     );

  //     if (idsToDelete.length === 0) return;

  //     await deleteBusinessReceiptMutation.mutateAsync(idsToDelete);
  //     setDeleteOverlay(false);
  //     setIndividualSelection({});
  //     setSelectAll(false);
  //   } catch (error) {
  //     console.error("Error deleting receipt:", error);
  //   }
  // };

  const confirmDelete = async () => {
    try {
      const idsToDelete = Object.keys(individualSelection).filter(
        (id) => individualSelection[id]
      );

      if (idsToDelete.length === 0) {
        console.error("No receipts selected for deletion.");
        return;
      }

      console.log("Deleting receipts:", idsToDelete); // Debugging log

      await deleteBusinessReceiptMutation.mutateAsync([...idsToDelete]); // Ensure it's an array

      setDeleteOverlay(false);
      setIndividualSelection({});
      setSelectAll(false);
    } catch (error) {
      console.error("Error deleting receipt:", error);
    }
  };

  const observerRef = useRef();
  const lastLogElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.1 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleViewReceipt = async (packageId) => {
    if (!packageId) {
      console.error("Invalid Package ID:", packageId);
      setErrorMessage("Invalid Package ID.");
      return;
    }

    setLoadingReceiptId(packageId);

    try {
      const pdfUrl = await fetchBusinessReceiptDetails(packageId);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `package_log_${packageId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setErrorMessage("");
    } catch (error) {
      console.error("Failed to fetch receipt details:", error.message);
      setErrorMessage(error.message || "Failed to fetch receipt details.");
    } finally {
      setLoadingReceiptId(null);
    }
  };

  return (
    <>
      {deleteOverlay && (
        <div className="business-receipt-delete-overlay">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>
              Are you sure you want to delete the selected Receipt(s)? It's
              irreversible.{" "}
            </p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button
                onClick={confirmDelete}
                disabled={deleteBusinessReceiptMutation.isLoading}
              >
                {deleteBusinessReceiptMutation.isLoading
                  ? "Deleting..."
                  : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="business-package-receipt">
        <div className="header">
          <p>Packages Receipts</p>
          <div className="in">
            <span>
              <CiSearch style={{ fontSize: "24px" }} />
              <input
                type="search"
                placeholder="Search by Tracking Number"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </span>
          </div>
        </div>
        <div className="package-receipt-table">
          {Object.values(individualSelection).some(
            (isSelected) => isSelected
          ) && (
            <div className="selected-receipt">
              <p>Delete Selected Receipt</p>
              <FaTrash
                onClick={handleDeleteSelectedReceipt}
                className="delete-icon"
              />
            </div>
          )}

          <div className="delete-header">
            <p>Select All</p>
            <span className="flex items-center gap-2 ">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </span>
          </div>

          {isLoading ? (
            <Spinner />
          ) : isError ? (
            <p style={{ fontFamily: "Montserrat" }}>
              Failed to load package receipts.
            </p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Tracking Num</th>
                  <th>Sender</th>
                  <th>Number</th>
                  <th>Receiver</th>
                  <th>Number</th>
                  <th>Item/Weight</th>
                  <th>Quantity</th>
                  <th>Item Value</th>
                  <th>Delivery Method</th>
                  <th>Shipping Cost</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredLogs.length > 0 ? (
                  filteredLogs.map((log, index) => {
                    const pkg = log.response?.package;
                    return (
                      <tr
                        key={`${log.packageId}-${index}`}
                        ref={
                          index === filteredLogs.length - 1
                            ? lastLogElementRef
                            : null
                        }
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={!!individualSelection[log.packageId]}
                            onChange={() => handleCheckboxChange(log.packageId)}
                          />
                        </td>
                        <td>{pkg?.tracking_number || "N/A"}</td>
                        <td>{log.sender?.name || "N/A"}</td>
                        <td>{log.sender?.phone_number || "N/A"}</td>
                        <td>{log.receiver?.name || "N/A"}</td>
                        <td>{log.receiver?.phone_number || "N/A"}</td>
                        <td>
                          {pkg?.items?.map((item) => (
                            <div key={item?._id}>
                              {item?.name} - {item?.weight}kg
                            </div>
                          )) || "N/A"}
                        </td>
                        <td>
                          {pkg?.items?.map((item) => (
                            <div key={item?._id}>{item?.quantity}</div>
                          )) || 0}
                        </td>
                        <td>
                          {pkg?.items?.map((item) => (
                            <div key={item?._id}>
                              {item?.items_value?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "NGN",
                              })}
                            </div>
                          )) || "N/A"}
                        </td>

                        <td>{pkg?.delivery_method || "N/A"}</td>
                        <td>
                          {pkg?.shipping_cost?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "NGN",
                          }) || "N/A"}
                        </td>
                        <td>
                          <button
                            onClick={() => handleViewReceipt(log.packageId)}
                            disabled={loadingReceiptId === log.packageId}
                          >
                            {loadingReceiptId === log.packageId
                              ? "Downloading..."
                              : "Download"}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="13">No matching package receipts found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        {isFetchingNextPage && <Spinner />}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </>
  );
}
