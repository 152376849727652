import React, {useState} from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { LiaEyeSolid, LiaEyeSlashSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';
import { GiPadlock } from "react-icons/gi";
import useChangePassword from '../../super_admin/libs/useChangePassword';
import "./Business_user_security.css";

export default function Businessusersecurity() {
    const navigate = useNavigate();
    const { changePassword } = useChangePassword();
    const [showPass, setShowPass] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleToggleShowPass = (field) => {
        if (field === 'oldPassword') {
            setShowPass((prev) => !prev);
        } else if (field === 'newPassword') {
            setShowPass2((prev) => !prev);
        }
    };

    const validateForm = () => {
        if (!oldPassword || !newPassword) {
            setErrorWithTimeout('Both fields are required.');
            return false;
        }
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(newPassword)) {
            setErrorWithTimeout('New password must be at least 8 characters long and include uppercase, lowercase, number, and special character.');
            return false;
        }
        return true;
    };

    const setErrorWithTimeout = (message) => {
        setError(message);
        setTimeout(() => {
            setError(null);
        }, 7000); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setLoading(true);
            setError(null);
            setSuccessMessage(null);

            await changePassword(
                { oldPassword, newPassword },
                {
                    onSuccess: () => {
                        setSuccessMessage('Password changed successfully');
                        setTimeout(() => {
                            setSuccessMessage('');
                            navigate('/');
                        }, 3000);
                    },
                    onError: (error) => {
                        console.error('Error changing password:', error.message);
                        setErrorWithTimeout('Invalid old password.');
                    },
                    onSettled: () => {
                        setLoading(false);
                    },
                }
            );
        } catch (error) {
            console.error('Error changing password:', error.message);
            setErrorWithTimeout('Failed to change password');
            setLoading(false);
        }
    };

    return (
        <div className="business-user-management">
            <div className="header">
            <GiPadlock  className='secure-icon' />
                <p>Change password</p>
            </div>
            <form className="form-setting" onSubmit={handleSubmit}>
                <label>
                    Enter Old Password
                    <div className="inner">
                        <input
                            type={showPass ? 'text' : 'password'}
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        {showPass ? (
                            <LiaEyeSolid className="iconp" onClick={() => handleToggleShowPass('oldPassword')} />
                        ) : (
                            <LiaEyeSlashSolid className="iconp" onClick={() => handleToggleShowPass('oldPassword')} />
                        )}
                    </div>
                </label>
                <label>
                    Enter New Password
                    <div className="inner">
                        <input
                            type={showPass2 ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        {showPass2 ? (
                            <LiaEyeSolid className="iconp" onClick={() => handleToggleShowPass('newPassword')} />
                        ) : (
                            <LiaEyeSlashSolid className="iconp" onClick={() => handleToggleShowPass('newPassword')} />
                        )}
                    </div>
                </label>
                <button type="submit" disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                </button>
                {error && <p className="error-message">{error}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </form>
        </div>
    );
}
