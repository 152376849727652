import React, {useState} from 'react';
import { ReactComponent as FrameIcon } from "../../asset/Frame 1001.svg";
import {Link, useNavigate } from 'react-router-dom';
import useBusinessSenderFormData from '../lib/useBusinessSender';
import useBusinessReceiverFormData from '../lib/useBusinessReceiver';
import useBusinessSummaryData from '../lib/useBusinessSummary';
import useBusinessCategories from '../lib/getBusinessCategory';
import useBusinessShippingCost from '../lib/fetchBusinessShippingCost';
import { FaArrowsRotate } from "react-icons/fa6";
import { CiCircleMinus } from "react-icons/ci";
import Spinner from "../../utils/spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Businesspackagecategory() {
    const navigate = useNavigate();
    const [formData] = useBusinessSenderFormData("businessSenderForm");
    const [receiverFormData] = useBusinessReceiverFormData("receiverFormData");
    const [summaryformData, handlesummaryChange] = useBusinessSummaryData('summaryData');
    const { mutation } = useBusinessShippingCost();
    const { data: businessCategories, isLoading, isError } = useBusinessCategories();
      const [isPickupRequired] = useState("");
      const [isEmergency] = useState("");
      const [items, setItems] = useState([{name: "", weight: "", items_value: "", quantity: "" }]);

      const handleItemsChange = (index, event) => {
        const { name, value } = event.target;
        console.log(`Updating item[${index}] - ${name}:`, value);
        const newItems = [...items];
        newItems[index][name] = value;
        if (name === "category") newItems[index].name = ""; 
        setItems(newItems);
      };

      
  const addItem = () => setItems([...items, { name: "", weight: "", items_value: "", quantity: "" }]);
  const removeItem = (index) => setItems(items.filter((_, i) => i !== index));

    const handleSubmit = async (e) => {
      e.preventDefault();
      const senderStateName = formData.senderState; 
      const receiverStateName = receiverFormData.receiverState;

      const payload = {
        sender_state: senderStateName,
        receiver_state: receiverStateName,
        isEmergency: isEmergency === "false",
        isPickupRequired: isPickupRequired === "false",
        items: items.map((item) => ({
          name: item.name,
          weight: parseFloat(item.weight) || 0,
          items_value: parseFloat(item.items_value.replace(/[^0-9.-]+/g, "")) || 0,
          quantity: parseInt(item.quantity, 10) || 1,
        })),
        estimated_delivery_date: summaryformData.estimated_delivery_date,
        delivery_method: summaryformData.delivery_method,
        payment_method: summaryformData.payment_method,
      };
    
    
      try {
       
        localStorage.setItem("itemsData", JSON.stringify(items));
    
        const data = await mutation.mutateAsync(payload);
        if (data) {
          localStorage.setItem("shippingSummaryData", JSON.stringify(data));
          navigate("/businesssummarydetail");
        }
      } catch (error) {
        console.error("❌ Submission Error:", error);
        toast.error("Shipping unavailable for this location.");
      }
    };

  return (
    <div className='business-package-category'>
      <div className="header">
        <h3>National Delivery</h3>
      </div>
      <div className="item">
        <FrameIcon/>
        <div className="wrapper">
        <p>Shipped From</p>
        <div className="business-package-info">
          <p> {formData.name}</p>
          <p> {formData.address_1}</p>
          <p> {formData.address_2}</p>
          <p>{formData.zip_code}</p>
          <p>{formData.email}</p>
          <p> {formData.phone_number}</p>
          <p> {formData.senderState}</p>
          <p> {formData.senderCity}</p>
        </div>
        <div className="return-box">
                <Link to="/businesssenderdetails">Change Pickup Address<FaArrowsRotate /></Link>
                </div>
        <p>Shipped To</p>
        <div className="business-package-info">
          <p> {receiverFormData.name}</p>
          <p> {receiverFormData.address_1}</p>
          <p> {receiverFormData.address_2}</p>
          <p>{receiverFormData.zip_code}</p>
          <p>{receiverFormData.email}</p>
          <p> {receiverFormData.phone_number}</p>
          <p> {receiverFormData.receiverState }</p>
          <p> {receiverFormData.receiverCity }</p>
        </div>
        <div className="return-box">
                <Link to="/businessreceiverdetails">Change delivery Address<FaArrowsRotate /></Link>
                </div>
        </div>
        <form onSubmit={handleSubmit}>
          <p>What are you Shipping?</p>
          {items.map((item, index) => (
            <div className="inner" key={index}>
              <label>Category
                <select name="category" value={item.category} onChange={(e) => handleItemsChange(index, e)} required>
                  <option value="">Select</option>
                  {isLoading && <option>Loading categories...</option>}
                  {isError && <option>Error loading categories</option>}
                  {businessCategories?.categories?.map((category) => (
                    <option key={category._id} value={category.name}>{category.name}</option>
                  ))}
                </select>
              </label>

              <label>Select Item
                <select name="name" value={item.name} onChange={(e) => handleItemsChange(index, e)} required>
                  <option value="">Select</option> {}
                  {item.category &&
                    businessCategories?.categories
                      ?.find((cat) => cat.name === item.category)
                      ?.description?.map((itemName, idx) => (
                        <option key={idx} value={itemName}>{itemName}</option>
                      ))}
                </select>
              </label>

              <label>Weight (kg)
                <input type="text" name="weight" value={item.weight} onChange={(e) => handleItemsChange(index, e)} required />
              </label>
              <label>Quantity
                <input type="text" name="quantity" value={item.quantity} onChange={(e) => handleItemsChange(index, e)} required />
              </label>
              <label>Value (Naira)
                <input type="text" name="items_value" value={item.items_value} onChange={(e) => handleItemsChange(index, e)} required />
              </label>
             
              <label>Estimated Delivery Date
            <input type="date" name="estimated_delivery_date" value={summaryformData.estimated_delivery_date || ""} onChange={handlesummaryChange} required />
          </label>

          
          <label>Delivery Method
            <select name="delivery_method" value={summaryformData.delivery_method || ""} onChange={handlesummaryChange} required>
              <option value="">Select</option>
              <option value="Home">Home</option>
              <option value="Office">Office</option>
            </select>
          </label>

         
          {items.length > 1 && (
                <div className="remove-item">
                  <button type="button" onClick={() => removeItem(index)}><CiCircleMinus /></button>
                </div>
              )}
            </div>
          ))}
          
          <div className="btn">
         <button type="button" onClick={addItem}>Add Item</button>
           
            <button type="submit">{mutation.isLoading ? <Spinner /> : "Proceed"}</button>
          </div>
        </form>
        <ToastContainer style={{ width: "80%" }} position="top-right" />
      </div>
    </div>
  )
}
