import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export default function Logisticinternationalpackagecreation() {
  const navigate = useNavigate();

  return (
    <div className="international-package-creation">
      <div className="header">
        <span>
          <FaArrowLeft onClick={() => navigate("/logisticpackagecreation")} />
          <p>International Shipment</p>
        </span>
      </div>
      <div className="item-creation">
        <h3>COMING SOON...</h3>
      </div>
    </div>
  );
}
