import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import useRegisterDriver from "../libs/useRegisterDriver";
import "./superadminregisterdriver.css";

const DriverRegistrationForm = () => {
  const navigate = useNavigate();
  const registerDriverMutation = useRegisterDriver();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    country_code: "+234",
    phone_number: "",
    email: "",
    vehicle_type: "",
    vehicle_plate_number: "",
    vehicle_registration_number: "",
    drivers_license_number: "",
    address: "",
    branch_full_address: "",
  });

  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerDriverMutation.mutateAsync(formData);
      setSuccess("Driver registered successfully, code sent to an Email");
      setTimeout(() => {
        setSuccess(false);
        navigate("/Superamdindriver");
      }, 3000);
    } catch (error) {
      console.error("Registration failed:", error.message);
    }
  };

  return (
    <div className="super_admin_register_driver">
      <div className="header">
        <IoMdArrowBack
          onClick={() => navigate("/Superamdindriver")}
          className="icon"
        />
        <p>Register New Driver</p>
      </div>
      <div className="driver-register-form">
        <form onSubmit={handleSubmit}>
          <label>
            First Name
            <input
              type="text"
              name="first_name"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Last Name
            <input
              type="text"
              name="last_name"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Phone Number
            <div className="inner">
              <select name="country_code" onChange={handleInputChange} required>
                <option value="+234">+234</option>
                <option value="+1">+1</option>
                <option value="+95">+95</option>
              </select>
              <input
                type="text"
                name="phone_number"
                onChange={handleInputChange}
                required
              />
            </div>
          </label>

          <label>
            Email
            <input
              type="email"
              name="email"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Vehicle Type
            <input
              type="text"
              name="vehicle_type"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Vehicle Plate Number
            <input
              type="text"
              name="vehicle_plate_number"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Vehicle Registration Number
            <input
              type="text"
              name="vehicle_registration_number"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Driver's License Number
            <input
              type="text"
              name="drivers_license_number"
              onChange={handleInputChange}
              required
            />
          </label>

          <label>
            Driver(s) Full Address
            <textarea
              name="address"
              onChange={handleInputChange}
              required
            ></textarea>
          </label>

          <label>
            Branch
            <select
              name="branch_full_address"
              onChange={handleInputChange}
              required
            >
              <option value="">Select Branch</option>
              <option value="Branch 1">Branch 1</option>
              <option value="Branch 2">Branch 2</option>
            </select>
          </label>

          <button type="submit" disabled={registerDriverMutation.isLoading}>
            {registerDriverMutation.isLoading ? "Registering..." : "Register"}
          </button>
          {registerDriverMutation.isError && (
            <p className="error-message">
              Error: {registerDriverMutation.error.message}
            </p>
          )}
          {success && <p className="success-message">{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default DriverRegistrationForm;
