import { useInfiniteQuery } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const fetchShipmentsByCreatorId = async ({ pageParam = 1 }, token, user) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/packages/by-creator/${user.userId}?page=${pageParam}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch shipments details");
  }

  return response.json();
};

const useShipmentsByCreatorId = () => {
  const { token, user } = useContext(AuthContext);

  return useInfiniteQuery(
    ["shipmentByCreatorId", user.userId],
    ({ pageParam = 1 }) =>
      fetchShipmentsByCreatorId({ pageParam }, token, user),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        return nextPage <= Math.ceil(lastPage.totalDispatches / 10)
          ? nextPage
          : undefined;
      },
    }
  );
};

export default useShipmentsByCreatorId;
