import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";

const useDeletePublicMessages = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const deletePublicMessagesMutation = useMutation(
    (messageIds) => deletePublicMessages(messageIds, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("publicMessages");
      },
    }
  );

  return deletePublicMessagesMutation;
};

const deletePublicMessages = async (messageIds, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/publicmsgs/delete-public-message`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ messageIds }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to delete public messages");
  }

  return response.json();
};

export default useDeletePublicMessages;
