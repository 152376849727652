import React, { useState, useRef, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../Assets/iconoir_search.svg";
import usePackageReceipt from "../libs/fetchPackageReceipt";
import useFetchReceiptDetails from "../libs/fetchReceiptDetails";
import useDebounce from "../libs/useDebounce";
import Spinner from "../../utils/spinner";
import "./Logisticspackagereceipt.css";

export default function Packagereceipt() {
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePackageReceipt();

  const { fetchReceiptDetails } = useFetchReceiptDetails();

  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingReceiptId, setLoadingReceiptId] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const logs = data?.pages.flatMap((page) => page.logs) || [];
  const filteredLogs = logs.filter((log) => {
    const packageName = log.response?.package?.name || "";
    return packageName
      .toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase());
  });

  const observerRef = useRef();
  const lastLogElementRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.1 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  const handleViewReceipt = async (packageId) => {
    if (!packageId) {
      console.error("Invalid Package ID:", packageId);
      setErrorMessage("Invalid Package ID.");
      return;
    }

    setLoadingReceiptId(packageId);

    try {
      const pdfUrl = await fetchReceiptDetails(packageId);

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `package_log_${packageId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setErrorMessage("");
    } catch (error) {
      console.error("Failed to fetch receipt details:", error.message);
      setErrorMessage(error.message || "Failed to fetch receipt details.");
    } finally {
      setLoadingReceiptId(null);
    }
  };

  return (
    <div className="logistic-package-receipt">
      <div className="header">
        <p>Packages Receipts</p>
        <div className="in">
          <span>
            <SearchIcon />
            <input
              type="search"
              placeholder="Search by Tracking Number"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </span>
        </div>
      </div>
      <div className="package-receipt-table">
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <p>Failed to load package receipts.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Tracking Num</th>
                <th>Sender Name</th>
                <th>Sender Num</th>
                <th>Receiver Name</th>
                <th>Receiver Num</th>
                <th>Package/Weight</th>
                <th>Quantity</th>
                <th>Item Value</th>
                <th>Delivery Method</th>
                <th>Shipping Cost</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredLogs.length > 0 ? (
                filteredLogs.map((log, index) => {
                  const pkg = log.response?.package;
                  return (
                    <tr
                      key={`${log.packageId}-${index}`}
                      ref={
                        index === filteredLogs.length - 1
                          ? lastLogElementRef
                          : null
                      }
                    >
                      <td>{pkg?.tracking_number || "N/A"}</td>
                      <td>{log.sender?.name || "N/A"}</td>
                      <td>{log.sender?.phone_number || "N/A"}</td>
                      <td>{log.receiver?.name || "N/A"}</td>
                      <td>{log.receiver?.phone_number || "N/A"}</td>
                      <td>
                        {pkg?.items?.map((item) => (
                          <div key={item?._id}>
                            {item?.name} - {item?.weight}kg
                          </div>
                        )) || "N/A"}
                      </td>
                      <td>
                        {pkg?.items?.map((item) => (
                          <div key={item?._id}>{item?.quantity}</div>
                        )) || 0}
                      </td>
                      <td>
                        {pkg?.items?.map((item) => (
                          <div key={item?._id}>
                            {item?.items_value?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "NGN",
                            })}
                          </div>
                        )) || "N/A"}
                      </td>

                      <td>{pkg?.delivery_method || "N/A"}</td>
                      <td>
                        {pkg?.shipping_cost?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "NGN",
                        }) || "N/A"}
                      </td>
                      <td>
                        <button
                          onClick={() => handleViewReceipt(log.packageId)}
                          disabled={loadingReceiptId === log.packageId}
                        >
                          {loadingReceiptId === log.packageId
                            ? "Downloading..."
                            : "Download"}
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="13">No matching package receipts found.</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {isFetchingNextPage && <Spinner />}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}
